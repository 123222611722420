import ReactDOM from "react-dom";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  FormControlLabel,
  Typography,
  Checkbox,
} from "@mui/material";
import "./Modal.css";
import { Fragment, useContext, useEffect, useState } from "react";
import AuthContext from "../../store/auth-context";
import BackdropContext from "../../store/backdrop-context";
import { SendDeleteRequest } from "../../actions/Actions";
import { formatDate, isNull } from "../../utils/Utils";
import { baseUrl } from "../../Config";

const ModalContent = (props) => {
  const [data, setData] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const [selectedWorktimes, setSelectedWorktimes] = useState([]);

  const authContext = useContext(AuthContext);
  const backdropContext = useContext(BackdropContext);

  useEffect(() => {
    if (!isNull(props.data)) {
      setData(props.data);
      setSelectedDate(props.selectedDate);
    }
  }, [props]);

  const hadleDeleteClick = () => {
    deleteHandler(selectedWorktimes);
    backdropContext.toggle();
  };

  const handleWorktimeSelect = (worktimeId, isChecked) => {
    let wts = selectedWorktimes;
    if (isChecked) {
      wts.push(worktimeId);
    } else {
      wts = selectedWorktimes.filter((id) => id !== worktimeId);
    }
    setSelectedWorktimes(wts);
  };

  const deleteHandler = async (user) => {
    if (selectedWorktimes.length > 0) {
      const url = baseUrl + "/api/worktime/" + selectedWorktimes;
      SendDeleteRequest(url, authContext.token, callbackHandler);
    }
  };

  const callbackHandler = () => {
    setData([]);
    setSelectedWorktimes([]);
    backdropContext.toggle();
    props.onHide();
  };

  const handleCancel = () => {
    setData([]);
    setSelectedWorktimes([]);
    props.onHide();
  };

  return (
    <div>
      <Dialog open={props.isVisible} maxWidth="lg">
        <DialogTitle>Delete worktimes</DialogTitle>
        <DialogContent>
          <Box style={{ marginTop: "16px" }}>
            <Typography variant="h6">
              Worktimes for date{" "}
              {isNull(selectedDate) ? "" : formatDate(selectedDate)}:
            </Typography>
            <div>
              {!isNull(data) &&
                data.map((worktime) => {
                  return (
                    <div key={worktime.id}>
                      <FormControlLabel
                        control={<Checkbox />}
                        label={`${worktime.startTime}-${worktime.endTime} / ${worktime.task}`}
                        onChange={(e) =>
                          handleWorktimeSelect(worktime.id, e.target.checked)
                        }
                      />
                    </div>
                  );
                })}
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            className="cancelButton"
            variant="contained"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={hadleDeleteClick}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const DeleteWorktimeModal = (props) => {
  const onHide = () => {
    props.closeModalHandler();
  };

  return (
    <Fragment>
      {ReactDOM.createPortal(
        <ModalContent
          isVisible={props.isVisible}
          onHide={onHide}
          data={props.modalData}
          selectedDate={props.selectedDate}
        />,
        document.getElementById("modal-root")
      )}
    </Fragment>
  );
};

export default DeleteWorktimeModal;
