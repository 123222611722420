import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../../store/auth-context";
import { baseUrl } from "../../Config";
import "./VehiclesPage.css";
import { SendGetRequest } from "../../actions/Actions";
import VehiclesTable from "../Table/VehiclesTable";

const VehiclesPage = () => {
  const [vehicles, setVehicles] = useState([]);

  const authContext = useContext(AuthContext);
  const user = JSON.parse(sessionStorage.getItem("user"))

  const vehiclesUrl = baseUrl + "/api/vehicle/all/" + user.id;

  useEffect(() => {
    SendGetRequest(vehiclesUrl, authContext.token, vehiclesHandler);
  }, []);

  const vehiclesHandler = (data) => {
    setVehicles(data);
  }

  const refreshHandler = () => {
    SendGetRequest(vehiclesUrl, authContext.token, vehiclesHandler);
  }

  return (
    <div className="VehiclesPage">
      <VehiclesTable vehicles={vehicles} user={user} refreshHandler={refreshHandler} />
    </div>
  );
};

export default VehiclesPage;
