import React from "react";
import "./LocalTravelPage.css";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
} from "@mui/material";
import Color from "../../enum/Color";
import { formatDate, isNull } from "../../utils/Utils";

import generatePDF, { Resolution, Margin } from "react-to-pdf";

const LocalTravelReport = (props) => {
  const report = props.report;
  const companyInfo = JSON.parse(sessionStorage.getItem("companyInfo"));

  const downloadPDF = () => {
    const options = {
      filename: "Loko vožnja " + formatDate(report.startDate).split(".").join("-").substring(3).slice(0, -1),
      method: "save",
      // default is Resolution.MEDIUM = 3, which should be enough, higher values
      // increases the image quality but also the size of the PDF, so be careful
      // using values higher than 10 when having multiple pages generated, it
      // might cause the page to crash or hang.
      resolution: Resolution.EXTREME,
      page: {
        // margin is in MM, default is Margin.NONE = 0
        margin: Margin.SMALL,
        // default is 'A4'
        format: "A4",
        // default is 'portrait'
        orientation: "portrait"
      },
      canvas: {
        // default is 'image/jpeg' for better size performance
        mimeType: "image/jpeg",
        qualityRatio: 1
      },
      // Customize any value passed to the jsPDF instance and html2canvas
      // function. You probably will not need this and things can break,
      // so use with caution.
      overrides: {
        // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
        pdf: {
          compress: false
        },
        // see https://html2canvas.hertzen.com/configuration for more options
        canvas: {
          useCORS: true
        }
      }
    };
    const getTargetElement = () => document.getElementById("report");
    generatePDF(getTargetElement, options);
  }

  const getRowColor = (item, index) => {
    if(!isNull(item) && (item.holiday || item.absentTime)) {
      return Color.HOLIDAY
    } else {
      return index%2!==0 ? Color.ACCENT1 : "white"
    }
  }
  
  return (
    <div
      className="LocalTravelPage"
      style={{ backgroundColor: Color.ACCENT1, paddingBottom: "8px" }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Toolbar
          style={{
            display: "flex",
            gap: "1rem",
            justifyContent: "end",
            backgroundColor: Color.ACCENT2,
          }}
        >
          <Button
            variant="contained"
            style={{ backgroundColor: Color.BACKGROUND }}
            onClick={downloadPDF}
          >
            Download report
          </Button>
        </Toolbar>

        <div className="invoicePage" id="report">
          <div
            className="details-container clearfix invoicePage"
            style={{ margiLeft: "auto", margiRight: "auto" }}
          >
            <div className="flex">
              <div>
                <div className="pcs-template-body">
                  <div style={{fontSize: "12px"}}>
                    <div>
                      <span>Naziv tvrtke: </span>{" "}
                      <span>{companyInfo.companyName}</span>
                    </div>
                    <div>
                      <span>Mjesto i adresa: {companyInfo.address}, {companyInfo.zipCode} {companyInfo.city}</span>
                    </div>
                    <div>
                      <span>OIB: {companyInfo.businessId}</span>
                    </div>
                  </div>

                  <h3 style={{marginTop: "1rem"}}> Evidencija o loko vožnji</h3>
                  <h4 style={{textAlign: "center", margin: 0}}> Za razdoblje od {formatDate(report.startDate)} do {formatDate(report.endDate)}</h4>

                  <div style={{width: "100%", height: "3px", backgroundColor: Color.BACKGROUND, marginTop: "1rem"}}></div>
                  
                  <div style={{marginTop: "1rem", fontSize: "12px"}}>
                    <div>
                      <span>Ime i prezime: <b>{report.owner}</b></span>
                    </div>
                    <div>
                      <span>Marka automobila: <b>{report.vehicle.make} {report.vehicle.model}</b></span>
                    </div>
                    <div>
                      <span>Registracijski broj: <b>{report.vehicle.registration}</b></span>
                    </div>
                  </div>
                  
                  <div style={{marginTop: "1rem"}}>
                    <TableContainer>
                      <Table
                        sx={{
                          maxWidth: "100%",
                          "& .MuiTableCell-root": {
                            border: "1px solid rgba(0, 0, 0, 1)",
                            padding: "3px",
                            textAlign: "center",
                          },
                        }}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow sx={{ backgroundColor: Color.ACCENT3 }}>
                            <TableCell sx={{fontSize: "11px" }}>Datum</TableCell>
                            <TableCell sx={{fontSize: "11px" }}>
                              Početno stanje brojila
                            </TableCell>
                            <TableCell sx={{fontSize: "11px" }}>
                              Završno stanje brojila
                            </TableCell>
                            <TableCell sx={{fontSize: "11px" }}>Broj prijeđenih km</TableCell>
                            <TableCell sx={{fontSize: "11px" }}>Naziv lokacije</TableCell>
                            <TableCell sx={{fontSize: "11px" }}>Izvješće o radu</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {report.items.map((item, index) => {
                            return (
                              <TableRow key={index} style={{backgroundColor: getRowColor(item, index)}}>
                                <TableCell>{!isNull(item.date) && formatDate(item.date)}</TableCell>
                                <TableCell style={{fontSize: "11px"}}>
                                  {item.startingDistance}
                                </TableCell>
                                <TableCell style={{fontSize: "11px"}}>
                                  {item.endingDistance}
                                </TableCell>
                                <TableCell style={{fontSize: "11px"}}>
                                  {item.distance}
                                </TableCell>
                                <TableCell style={{fontSize: "11px"}}>{item.location}</TableCell>
                                <TableCell style={{fontSize: "11px"}}>{item.report}</TableCell>

                              </TableRow>
                            );
                          })}
                          <TableRow
                            key={3}
                            style={{ marginTop: "8px" }}
                            sx={{
                              "& .MuiTableCell-root": {
                                border: "0px",
                                padding: "3px",
                              },
                            }}
                          >
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                          <TableRow
                            key={3}
                            style={{ marginTop: "8px" }}
                            sx={{
                              backgroundColor: Color.ACCENT2,
                              "& .MuiTableCell-root": {
                                border: "0px",
                                padding: "5px",
                              },
                            }}
                          >
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>Ukupno:</TableCell>
                            <TableCell>
                              <b>{report.totalDistance}km</b>
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocalTravelReport;
