import React, {useState} from "react";

const BackdropContext = React.createContext({
    open: false,
    toggle: () => {}
});

export const BackdropContextProvider = (props) => {

    const [open, setOpen] = useState(false);

    const toggleHandler = () => {
        setOpen(prev => {
            return !prev
        });
    };

    const contextValue = {
        open: open,
        toggle: toggleHandler
    }

    return (<BackdropContext.Provider value={contextValue}>{props.children}</BackdropContext.Provider>);
}

export default BackdropContext

