import ReactDOM from "react-dom";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import "./Modal.css";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import CurrencyEnum from "../../enum/CurrencyEnum";
import LanguageEnum from "../../enum/LanguageEnum";
import AuthContext from "../../store/auth-context";
import { SendPostRequest, SendPutRequest } from "../../actions/Actions";
import {  isNull } from "../../utils/Utils";
import { baseUrl } from "../../Config";
import BackdropContext from "../../store/backdrop-context";

const ModalContent = (props) => {
  const currencies = Object.keys(CurrencyEnum);
  const languages = Object.keys(LanguageEnum);
  const data = props.data;
  const taxes = props.taxes;
  const [loading, setLoading] = useState(true);
  const [errorDetails, setErrorDetails] = useState({
    showError: false,
    errorCode: "",
    errorMessage: "",
  });

  const authContext = useContext(AuthContext);
  const backdropContext = useContext(BackdropContext);

  const firstNameInputRef = useRef();
  const lastNameInputRef = useRef();
  const companyNameInputRef = useRef();
  const companyDisplayNameInputRef = useRef();
  const businessIdInputRef = useRef();
  const pdvIdInputRef = useRef();
  const emailInputRef = useRef();
  const telephoneInputRef = useRef();
  const mobileInputRef = useRef();
  const webInputRef = useRef();
  const addressInputRef = useRef();
  const cityInputRef = useRef();
  const countryInputRef = useRef();
  const zipCodeInputRef = useRef();

  const [clientType, setClientType] = useState();
  const [clientCurrency, setCurrency] = useState();
  const [clientLanguage, setLanguage] = useState();
  const [clientTax, setTax] = useState();

  useEffect(() => {
    if (!isNull(data)) {
      setClientType(data.businessClient);
      setCurrency(data.currency);
      setLanguage(data.language);
      setTax(data.tax);
    }
  }, [data]);

  const handleClientTypeSelect = (event) => {
    setClientType(event.target.value);
  };

  const handleCurrencySelect = (event) => {
    setCurrency(event.target.value);
  };

  const handleLanguageSelect = (event) => {
    setLanguage(event.target.value);
  };

  const handleTaxSelect = (event) => {
    const tax = taxes.find((t) => t.id === event.target.value);
    setTax(tax);
  };

  const hadleOnSaveClick = () => {
    const client = {
      isBusinessClient: clientType,
      firstName: firstNameInputRef.current.value,
      lastName: lastNameInputRef.current.value,
      companyName: companyNameInputRef.current.value,
      companyDisplayName: companyDisplayNameInputRef.current.value,
      businessId: businessIdInputRef.current.value,
      pdvId: pdvIdInputRef.current.value,
      email: emailInputRef.current.value,
      telephone: telephoneInputRef.current.value,
      mobile: mobileInputRef.current.value,
      web: webInputRef.current.value,
      address: addressInputRef.current.value,
      city: cityInputRef.current.value,
      country: countryInputRef.current.value,
      zipCode: zipCodeInputRef.current.value,
      currency: clientCurrency,
      language: clientLanguage,
      tax: clientTax,
    };
    saveHandler(client);
    backdropContext.toggle();
  };

  const hadleOnUpdateClick = () => {
    const client = {
      id: data.id,
      index: data.index,
      isBusinessClient: clientType,
      firstName: firstNameInputRef.current.value,
      lastName: lastNameInputRef.current.value,
      companyName: companyNameInputRef.current.value,
      companyDisplayName: companyDisplayNameInputRef.current.value,
      businessId: businessIdInputRef.current.value,
      pdvId: pdvIdInputRef.current.value,
      email: emailInputRef.current.value,
      telephone: telephoneInputRef.current.value,
      mobile: mobileInputRef.current.value,
      web: webInputRef.current.value,
      address: addressInputRef.current.value,
      city: cityInputRef.current.value,
      country: countryInputRef.current.value,
      zipCode: zipCodeInputRef.current.value,
      currency: clientCurrency,
      language: clientLanguage,
      tax: clientTax,
    };
    updateHandler(client);
    backdropContext.toggle();
  };

  const saveHandler = async (client) => {
    const url = baseUrl + "/api/customer";
    SendPostRequest(url, client, authContext.token, customerHandler);
  };

  const updateHandler = async (client) => {
    const url = baseUrl + "/api/customer";
    SendPutRequest(url, client, authContext.token, customerHandler);
  };

  const customerHandler = () => {
    backdropContext.toggle();
    props.onHide();
  }

  return (
    <div>
      <Dialog open={props.isVisible} maxWidth="lg">
        <DialogTitle>
          {isNull(data) ? "New Customer" : data.customerDisplayName}
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <div>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Client type
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={handleClientTypeSelect}
                    defaultValue={isNull(data) ? "" : data.businessClient}
                  >
                    <FormControlLabel
                      disabled={props.isReadOnly}
                      value="true"
                      control={<Radio />}
                      label="Busines client"
                    />
                    <FormControlLabel
                      disabled={props.isReadOnly}
                      value="false"
                      control={<Radio />}
                      label="Private client"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div>
                <TextField
                  disabled={props.isReadOnly}
                  id="outlined-required"
                  label="First Name"
                  inputRef={firstNameInputRef}
                  defaultValue={isNull(data) ? "" : data.firstName}
                />
                <TextField
                  disabled={props.isReadOnly}
                  id="outlined-disabled"
                  label="Last Name"
                  inputRef={lastNameInputRef}
                  defaultValue={isNull(data) ? "" : data.lastName}
                />
              </div>
              <div>
                <TextField
                  disabled={props.isReadOnly}
                  id="outlined-required"
                  label="Company Name"
                  inputRef={companyNameInputRef}
                  defaultValue={isNull(data) ? "" : data.companyName}
                />
                <TextField
                  disabled={props.isReadOnly}
                  id="outlined-disabled"
                  label="Display Name"
                  inputRef={companyDisplayNameInputRef}
                  defaultValue={isNull(data) ? "" : data.companyDisplayName}
                />
                <TextField
                  disabled={props.isReadOnly}
                  id="outlined-disabled"
                  label="Business ID"
                  inputRef={businessIdInputRef}
                  defaultValue={isNull(data) ? "" : data.businessId}
                />
                <TextField
                  disabled={props.isReadOnly}
                  id="outlined-disabled"
                  label="PDV ID"
                  inputRef={pdvIdInputRef}
                  defaultValue={isNull(data) ? "" : data.pdvId}
                />
              </div>
              <Divider />
              <div>
                <TextField
                  disabled={props.isReadOnly}
                  id="outlined-required"
                  label="Email"
                  inputRef={emailInputRef}
                  defaultValue={data && data.email}
                />
                <TextField
                  disabled={props.isReadOnly}
                  id="outlined-disabled"
                  label="Telephone"
                  inputRef={telephoneInputRef}
                  defaultValue={isNull(data) ? "" : data.telephone}
                />
                <TextField
                  disabled={props.isReadOnly}
                  id="outlined-disabled"
                  label="Mobile"
                  inputRef={mobileInputRef}
                  defaultValue={isNull(data) ? "" : data.mobile}
                />
                <TextField
                  disabled={props.isReadOnly}
                  id="outlined-disabled"
                  label="Web"
                  inputRef={webInputRef}
                  defaultValue={isNull(data) ? "" : data.web}
                />
              </div>
              <Divider />
              <div>
                <TextField
                  disabled={props.isReadOnly}
                  id="outlined-required"
                  label="Address"
                  inputRef={addressInputRef}
                  defaultValue={data && data.address}
                />
                <TextField
                  disabled={props.isReadOnly}
                  id="outlined-disabled"
                  label="City"
                  inputRef={cityInputRef}
                  defaultValue={data && data.city}
                />
                <TextField
                  disabled={props.isReadOnly}
                  id="outlined-disabled"
                  label="Country"
                  inputRef={countryInputRef}
                  defaultValue={data && data.country}
                />
                <TextField
                  disabled={props.isReadOnly}
                  id="outlined-disabled"
                  label="ZipCode"
                  inputRef={zipCodeInputRef}
                  defaultValue={data && data.zipCode}
                />
              </div>
              <div>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-label">
                    Currency
                  </InputLabel>
                  <Select
                    disabled={props.isReadOnly}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Currency"
                    onChange={handleCurrencySelect}
                    defaultValue={isNull(data) ? "" : data.currency}
                  >
                    {currencies.map((key) => {
                      return (
                        <MenuItem value={key}>{CurrencyEnum[key]}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-label">
                    Language
                  </InputLabel>
                  <Select
                    disabled={props.isReadOnly}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Language"
                    onChange={handleLanguageSelect}
                    defaultValue={isNull(data) ? "" : data.language}
                  >
                    {languages.map((key) => {
                      return (
                        <MenuItem value={key}>{LanguageEnum[key]}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-label">Tax</InputLabel>
                  <Select
                    disabled={props.isReadOnly}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Language"
                    onChange={handleTaxSelect}
                    defaultValue={isNull(data) ? "" : data.tax && data.tax.id}
                  >
                    {taxes.map((data) => {
                      return (
                        <MenuItem value={data.id}>
                          {data.taxName + " " + data.taxRate + "%"}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            className="cancelButton"
            variant="contained"
            onClick={props.onHide}
          >
            {props.isReadOnly ? "Close" : "Cancel"}
          </Button>
          <Button
            disabled={props.isReadOnly}
            variant="contained"
            onClick={data && data.id ? hadleOnUpdateClick : hadleOnSaveClick}
          >
            {data && data.id ? "Update" : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const CustomerModal = (props) => {
  const onHide = () => {
    props.closeModalHandler();
  };

  return (
    <Fragment>
      {ReactDOM.createPortal(
        <ModalContent
          isVisible={props.isVisible}
          onHide={onHide}
          data={props.modalData}
          taxes={props.taxes}
          isReadOnly={props.isReadOnly}
        />,
        document.getElementById("modal-root")
      )}
    </Fragment>
  );
};

export default CustomerModal;
