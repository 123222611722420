import ReactDOM from "react-dom";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Typography,
  Autocomplete,
} from "@mui/material";
import "./Modal.css";
import { Fragment, useContext, useEffect, useState } from "react";
import AuthContext from "../../store/auth-context";
import {
  SendGetRequest,
  SendPostRequest,
  SendPutRequest,
} from "../../actions/Actions";
import { isNull } from "../../utils/Utils";
import { baseUrl } from "../../Config";
import WorktimeTypeEnum from "../../enum/WorktimeTypeEnum";
import { QuickTimeInsert, QuickTimeInsertFullTime } from "../../Data/Data";
import BackdropContext from "../../store/backdrop-context";

const ModalContent = (props) => {
  let data = props.data;
  const engagements = props.engagements;
  const projects = [].concat(...engagements.map((eng) => eng.project));

  const [loading, setLoading] = useState(true);
  const [errorDetails, setErrorDetails] = useState({
    showError: false,
    errorCode: "",
    errorMessage: "",
  });

  const authContext = useContext(AuthContext);
  const backdropContext = useContext(BackdropContext);

  const [date, setDate] = useState(props.selectedDate);
  const [project, setProject] = useState(null);
  const [employee, setEmployee] = useState(props.employee);
  const [worktimeType, setWorktimeType] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [task, setTask] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [description, setDescription] = useState(null);
  const [errors, setErrors] = useState({
    worktimeType: true,
    date: false,
    project: true,
    task: true,
    startTime: true,
    endTime: true,
  });
  const [formValid, setFormValid] = useState(false);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (!isNull(project)) {
      backdropContext.toggle();
      const url = baseUrl + `/api/task/all/active/${employee.id}/${project.id}`;
      SendGetRequest(url, authContext.token, getTasksCallback);
    }
  }, [project, employee]);

  const getTasksCallback = (data) => {
    console.log(data);
    setTasks(data);
    backdropContext.toggle();
  };

  useEffect(() => {
    setDate(props.selectedDate);
    setEmployee(props.employee);
  }, [props.selectedDate, props.employee]);

  const clearStates = () => {
    setDate(props.selectedDate);
    setWorktimeType(null);
    setProject(null);
    setStartTime(null);
    setEndTime(null);
    setTask(null);
    setTasks([]);
    setDescription(null);
    setErrors({
      worktimeType: true,
      date: false,
      project: true,
      task: true,
      startTime: true,
      endTime: true,
    });
  };

  useEffect(() => {
    if (!isNull(data)) {
      setDate(data.date);
      setWorktimeType(data.worktimeType);
      setProject(data.project);
      setEmployee(data.employee);
      setStartTime(data.startTime);
      setEndTime(data.endTime);
      setTask(data.task);
      setDescription(data.description);
      setErrors({});
    } else {
      clearStates();
    }
  }, [data]);

  const handleWorktimeTypeSelect = (event) => {
    setWorktimeType(event.target.value);
    blurHandler(event);
  };

  const handleProjectSelect = (event) => {
    const project = projects.find((c) => c.id === event.target.value);
    setProject(project);
    blurHandler(event);
  };

  const handleQuickInsertSelect = (qi) => {
    setStartTime(qi.startTime);
    setEndTime(qi.endTime);
    setErrors((prev) => ({
      ...errors,
      startTime: false,
      endTime: false,
    }));
  };

  const hadleOnSaveClick = () => {
    backdropContext.toggle();
    const worktime = {
      employee: employee,
      worktimeType: worktimeType,
      date: date,
      project: project,
      task: task,
      description: description,
      startTime: startTime,
      endTime: endTime,
    };
    saveHandler(worktime);
  };

  const hadleOnUpdateClick = () => {
    backdropContext.toggle();
    const worktime = {
      id: data.id,
      employee: employee,
      worktimeType: worktimeType,
      date: date,
      project: project,
      task: task,
      description: description,
      startTime: startTime,
      endTime: endTime,
    };
    updateHandler(worktime);
  };

  const saveOrUpdateCallback = () => {
    clearStates();
    backdropContext.toggle();
    props.onHide();
  };

  const saveHandler = async (worktime) => {
    const url = baseUrl + "/api/worktime";
    SendPostRequest(url, worktime, authContext.token, saveOrUpdateCallback);
  };

  const updateHandler = async (worktime) => {
    const url = baseUrl + "/api/worktime";
    SendPutRequest(url, worktime, authContext.token, saveOrUpdateCallback);
  };

  const closeHandler = () => {
    clearStates();
    data = null;
    props.onHide();
  };

  const blurHandler = (event) => {
    setErrors((prev) => ({
      ...errors,
      [event.target.name]: !event.target.value,
    }));
  };

  useEffect(() => {
    validateForm();
  }, [errors]);

  const validateForm = () => {
    const bools = Object.values(errors);
    setFormValid((prev) => {
      return !bools.includes(true);
    });
  };

  return (
    <div>
      <Dialog open={props.isVisible} maxWidth="lg">
        <DialogTitle>
          {isNull(data) ? "New Worktime" : "Edit Worktime"}
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <div>
                <FormControl
                  sx={{ m: 1, minWidth: 120 }}
                  required
                  error={errors.worktimeType}
                >
                  <InputLabel id="demo-simple-select-label">Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="worktimeType"
                    name="worktimeType"
                    onChange={handleWorktimeTypeSelect}
                    value={isNull(worktimeType) ? "" : worktimeType}
                  >
                    {Object.keys(WorktimeTypeEnum).map((key) => {
                      return (
                        <MenuItem value={key}>{WorktimeTypeEnum[key]}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <TextField
                  required
                  error={errors.date}
                  sx={{ m: 1, minWidth: 200 }}
                  id="outlined-required"
                  label="Date"
                  type="date"
                  name="date"
                  value={date || ""}
                  onChange={(e) => setDate(e.target.value)}
                  onBlur={blurHandler}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div>
                <FormControl
                  sx={{ m: 1, minWidth: "50%" }}
                  required
                  error={errors.project}
                >
                  <InputLabel id="demo-simple-select-label">Project</InputLabel>
                  <Select
                    required
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Project"
                    name="project"
                    onChange={handleProjectSelect}
                    onBlur={blurHandler}
                    value={isNull(project) ? "" : project.id}
                  >
                    {projects &&
                      projects.map((data) => {
                        return <MenuItem value={data.id}>{data.name}</MenuItem>;
                      })}
                  </Select>
                </FormControl>
              </div>
              <div>
                <Autocomplete
                  disabled={isNull(tasks)}
                  value={task || ""}
                  onChange={(event, task) => {
                    setTask(task);
                  }}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  id="controllable-states-demo"
                  options={[].concat(...tasks.map((t) => t.name))}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="task"
                      required
                      label="Task"
                      error={errors.task && !isNull(tasks)}
                      onBlur={blurHandler}
                      disabled={isNull(tasks)}
                    />
                  )}
                />
              </div>
              <Typography sx={{ m: 1 }}>Quick time insert</Typography>
              <div>
                {QuickTimeInsert.map((qi, index) => {
                  return (
                    <Chip
                      clickable="true"
                      label={qi.name}
                      onClick={() => handleQuickInsertSelect(qi)}
                      variant="outlined"
                      sx={{ m: 1 }}
                    />
                  );
                })}
              </div>
              <div>
                {QuickTimeInsertFullTime.map((qi, index) => {
                  return (
                    <Chip
                      clickable="true"
                      label={qi.name}
                      onClick={() => handleQuickInsertSelect(qi)}
                      variant="outlined"
                      sx={{ m: 1 }}
                    />
                  );
                })}
              </div>

              <div>
                <TextField
                  required
                  error={errors.startTime}
                  sx={{ m: 1, minWidth: 200 }}
                  id="startTime"
                  label="Start time"
                  type="time"
                  name="startTime"
                  step="600"
                  value={startTime}
                  onChange={(e) => setStartTime(e.target.value)}
                  onBlur={blurHandler}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  required
                  error={errors.endTime}
                  sx={{ m: 1, minWidth: 200 }}
                  id="endtime"
                  label="End time"
                  type="time"
                  name="endTime"
                  value={endTime}
                  onChange={(e) => setEndTime(e.target.value)}
                  onBlur={blurHandler}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>

              <div>
                <TextField
                  sx={{ m: 1, minWidth: 200 }}
                  id="outlined-required"
                  label="Description (optional)"
                  type="description"
                  value={description || ""}
                  onChange={(e) => setDescription(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            className="cancelButton"
            variant="contained"
            onClick={closeHandler}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={!formValid}
            onClick={data && data.id ? hadleOnUpdateClick : hadleOnSaveClick}
            type="submit"
          >
            {data && data.id ? "Update" : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const WorktimeModal = (props) => {
  const onHide = () => {
    props.closeModalHandler();
  };
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <ModalContent
          isVisible={props.isVisible}
          onHide={onHide}
          data={props.modalData}
          engagements={props.engagements}
          selectedDate={props.selectedDate}
          employee={props.employee}
        />,
        document.getElementById("modal-root")
      )}
    </Fragment>
  );
};

export default WorktimeModal;
