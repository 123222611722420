import React, { useContext, useEffect, useRef } from "react";
import "./Login.css";
import Logo from "../../imgs/logo.svg";
import { useNavigate } from "react-router-dom";
import { TextField, Button, CircularProgress, Typography } from "@mui/material";
import AuthContext from "../../store/auth-context";
import { baseUrl } from "../../Config";
import { useState } from "react";
import Color from "../../enum/Color";
import { color } from "@mui/system";

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const usernameInputRef = useRef();
  const passwordInputRef = useRef();

  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  const getUserInfo = () => {
    fetch(baseUrl + "/api/auth/user", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: sessionStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          return res.json().then((data) => {
            let alert = "Failed while getting user info";
            throw new Error(alert);
          });
        }
      })
      .then((data) => {
        sessionStorage.setItem("user", JSON.stringify(data));
        getCompanyInfo();
      })
      .catch((err) => {
        console.log(err.message);
        setIsLoading(false);
      });
  };

  const getCompanyInfo = () => {
    fetch(baseUrl + "/api/companyInfo", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: sessionStorage.getItem("token"),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          return res.json().then((data) => {
            let alert = "Failed while getting company info";
            throw new Error(alert);
          });
        }
      })
      .then((data) => {
        sessionStorage.setItem("companyInfo", JSON.stringify(data));
        setIsLoading(false);
        navigate("/", { replace: true });
      })
      .catch((err) => {
        console.log(err.message);
        setIsLoading(false);
      });
  };

  const loginHandler = () => {
    const inputUsername = usernameInputRef.current.value;
    const inputPassword = passwordInputRef.current.value;

    setIsLoading(true);
    setIsError(false);

    fetch(baseUrl + "/auth", {
      method: "POST",
      body: new URLSearchParams({
        username: inputUsername,
        password: inputPassword,
      }),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          return res.json().then((data) => {
            let alert = "Authentication failed";
            setIsError(true);
            throw new Error(alert);
          });
        }
      })
      .then((data) => {
        authContext.login(data.access_token);
        getUserInfo();
      })
      .catch((err) => {
        console.log(err.message);
        setIsLoading(false);
      });
  };

  const keyPressHandler = (event) => {
    if (event.key === "Enter") {
      loginHandler();
    }
  };

  return (
    <div className="background">
      <div className="card">
        {/* logo */}
        <div className="logo">
          <img src={Logo} alt="logo" />
        </div>

        <h3>Sign In</h3>

        {isError && <Typography variant={"body1"} style={{color: Color.DANGER, textAlign: "center"}}>Incorrect username or password.</Typography>}

        <form>
          <div>
            <TextField
              id="outlined-username-input"
              name="username"
              label="Username"
              className="input-field"
              inputRef={usernameInputRef}
            />
          </div>

          <div>
            <TextField
              id="outlined-password-input"
              name="passsword"
              label="Password"
              type="password"
              autoComplete="current-password"
              className="input-field"
              inputRef={passwordInputRef}
              onKeyDown={keyPressHandler}
            />
          </div>
          {isLoading && (
            <CircularProgress
              sx={{
                "& .MuiCircularProgress-circleIndeterminate": {
                  color: Color.BACKGROUND,
                },
              }}
            />
          )}
          <Button variant="contained" className="button" onClick={loginHandler}>
            LOGIN
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Login;
