import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../../store/auth-context";
import { baseUrl } from "../../Config";
import InvoicesTable from "../Table/InvoicesTable";
import { CircularProgress, TextField } from "@mui/material";
import { isNull } from "../../utils/Utils";
import { SendGetRequest } from "../../actions/Actions";
import Color from "../../enum/Color";

const SubscriptionInvoices = (props) => {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);

  const authContext = useContext(AuthContext);
  const subscription = props.subscription;

  const url = baseUrl + "/api/invoice/subscription/" + subscription.id;

  useEffect(() => {
    setLoading(true);
    SendGetRequest(url, authContext.token, invoicesCallback);
  }, []);

  const invoicesCallback = (data) => {
    setInvoices(data);
    setLoading(false);
  }

  const refreshHandler = () => {
    SendGetRequest(url, authContext.token, invoicesCallback);
  }

  return (
    <div className="InvoicesPage">
      {loading ? <CircularProgress /> :
        <InvoicesTable data={invoices} refreshHandler={refreshHandler} />
      }
    </div>
  );
};

export default SubscriptionInvoices;
