import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { UilAngleDown } from "@iconscout/react-unicons";
import { containsRoles, isNull } from "../../utils/Utils";
import { useState } from "react";
import { useEffect } from "react";
import Color from "../../enum/Color";

const DropdownMenu = (props) => {
  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem("user")));
  const [userRoles, setUserRoles] = useState([]);

  useEffect(() => {
    if (!isNull(user)) {
      setUserRoles(user.roles.map((role) => role.name));
    }
  }, [user]);

  return (
    <Accordion key={props.text} style={{backgroundColor: Color.ACCENT2, margin:0}}
     expanded={props.isOpen}>
      <AccordionSummary
        expandIcon={<UilAngleDown />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        onClick={props.onClick}
      >
        <Typography>{props.text}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ width: "100%", padding: 0 }}>
        <Divider/>
        {props.menu.map((item, index) => {
          if (containsRoles(userRoles, item.roles)) {
            return (
              <ListItem
                key={index}
                disablePadding
                onClick={() => props.navigationHandler(item)}
                selected={props.pathname === item.path}
              >
                <ListItemButton style={{paddingLeft: "32px"}}>
                  <ListItemIcon>
                    <item.icon style={{ width: "20px" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={item.heading}
                    primaryTypographyProps={{ fontSize: "14px" }}
                  />
                </ListItemButton>
              </ListItem>
            );
          }
        })}
      </AccordionDetails>
    </Accordion>
  );
};

export default DropdownMenu;
