import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../../store/auth-context";
import { baseUrl } from "../../Config";
import "./SubscriptionsPage.css";
import { SendGetRequest } from "../../actions/Actions";
import SubscriptionsTable from "../Table/SubscriptionsTable";

const SubscriptionPage = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [items, setItems] = useState([]);
  const [customers, setCustomers] = useState([]);

  const authContext = useContext(AuthContext);

  const subscriptionUrl = baseUrl + "/api/subscription/all";
  const itemsUrl = baseUrl + "/api/item/all";
  const customersUrl = baseUrl + "/api/customer/all";

  useEffect(() => {
    SendGetRequest(subscriptionUrl, authContext.token, subscriptionsHandler);
    SendGetRequest(itemsUrl, authContext.token, itemsHandler);
    SendGetRequest(customersUrl, authContext.token, customersHandler);
  }, []);

  const subscriptionsHandler = (data) => {
    setSubscriptions(data);
  }

  const itemsHandler = (data) => {
    setItems(data);
  }

  const customersHandler = (data) => {
    setCustomers(data);
  }

  const refreshHandler = () => {
    SendGetRequest(subscriptionUrl, authContext.token, subscriptionsHandler);
    SendGetRequest(itemsUrl, authContext.token, itemsHandler);
    SendGetRequest(customersUrl, authContext.token, customersHandler);
  }

  return (
    <div className="SubscriptionPage">
      <SubscriptionsTable customers={customers} subscriptions={subscriptions} items={items} refreshHandler={refreshHandler} />
    </div>
  );
};

export default SubscriptionPage;
