import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
} from "@mui/material";
import { useContext, useState } from "react";
import CurrencyEnum from "../../enum/CurrencyEnum";
import AuthContext from "../../store/auth-context";
import { SendGetRequest, SendPutRequest } from "../../actions/Actions";
import {baseUrl} from "../../Config";

const CompanyInfoForm = () => {
  const currencies = Object.keys(CurrencyEnum);
  const [companyInfo, setCompanyInfo] = useState(JSON.parse(sessionStorage.getItem("companyInfo")));

  const [companyName, setCompanyName] = useState(companyInfo.companyName);
  const [businessId, setBusinessId] = useState(companyInfo.businessId);
  const [pdvId, setPdvId] = useState(companyInfo.pdvId);
  const [email, setEmail] = useState(companyInfo.email);
  const [telephone, setTelephone] = useState(companyInfo.telephone);
  const [mobile, setMobile] = useState(companyInfo.mobile);
  const [web, setWeb] = useState(companyInfo.web);
  const [address, setAddress] = useState(companyInfo.address);
  const [city, setCity] = useState(companyInfo.city);
  const [country, setConutry] = useState(companyInfo.country);
  const [zipCode, setZipCode] = useState(companyInfo.zipCode);
  const [iban, setIban] = useState(companyInfo.iban);
  const [swift, setSwift] = useState(companyInfo.swift);
  const [localCurrency, setLocalCurrency] = useState(companyInfo.localCurrency);
  const [eurExchangeRate, setEurExchangeRate] = useState(
    companyInfo.eurExchangeRate
  );
  const [defaultNotes, setNotes] = useState(companyInfo.defaultNotes);
  const [defaultTermsAndConditions, setTermsAndConditions] = useState(
    companyInfo.defaultTermsAndConditions
  );
  const [notesEng, setNotesEng] = useState(companyInfo.notesEng);
  const [termsAndConditionsEng, setTermsAndConditionsEng] = useState(
    companyInfo.termsAndConditionsEng
  );
  const [displayEuro, setDisplayEuro] = useState(companyInfo.displayEuro);

  const authContext = useContext(AuthContext);

  const url = baseUrl + "/api/companyInfo";

  const companyInfoCallback = (data) => {
    setCompanyInfo(data);
  };

  const hadleOnSaveClick = () => {
    const info = {
      id: companyInfo.id,
      companyName: companyName,
      businessId: businessId,
      pdvId: pdvId,
      email: email,
      telephone: telephone,
      mobile: mobile,
      web: web,
      address: address,
      city: city,
      country: country,
      zipCode: zipCode,
      iban: iban,
      swift: swift,
      localCurrency: localCurrency,
      eurExchangeRate: eurExchangeRate,
      defaultNotes: defaultNotes,
      defaultTermsAndConditions: defaultTermsAndConditions,
      notesEng: notesEng,
      termsAndConditionsEng: termsAndConditionsEng,
      displayEuro: displayEuro,
    };
    updateHandler(info);
  };

  const updateHandler = async (companyInfo) => {
    SendPutRequest(url, companyInfo, authContext.token, refreshHandler);
    sessionStorage.setItem("companyInfo", JSON.stringify(companyInfo))
  };

  const refreshHandler = () => {
    SendGetRequest(companyInfo, authContext.token);
  }

  return (
    <>
        <div>
          <TextField
            sx={{ m: 1, minWidth: 200 }}
            id="full-width-text-field"
            label="Comapny name"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            sx={{ m: 1, minWidth: 200 }}
            id="outlined-required"
            label="Busines Id"
            value={businessId}
            onChange={(e) => setBusinessId(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            sx={{ m: 1, minWidth: 200 }}
            id="outlined-required"
            label="PDV Id"
            value={pdvId}
            onChange={(e) => setPdvId(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            sx={{ m: 1, minWidth: 200 }}
            id="date"
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>

        <div>
          <TextField
            sx={{ m: 1, minWidth: 200 }}
            id="full-width-text-field"
            label="Telephone"
            value={telephone}
            onChange={(e) => setTelephone(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            sx={{ m: 1, minWidth: 200 }}
            id="outlined-required"
            label="Mobile"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            sx={{ m: 1, minWidth: 200 }}
            id="outlined-required"
            label="Web"
            value={web}
            onChange={(e) => setWeb(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            sx={{ m: 1, minWidth: 200 }}
            id="outlined-required"
            label="Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div>
          <TextField
            sx={{ m: 1, minWidth: 200 }}
            id="outlined-required"
            label="City"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            sx={{ m: 1, minWidth: 200 }}
            id="outlined-required"
            label="Country"
            value={country}
            onChange={(e) => setConutry(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            sx={{ m: 1, minWidth: 200 }}
            id="outlined-required"
            label="Zip Code"
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            sx={{ m: 1, minWidth: 200 }}
            id="outlined-required"
            label="IBAN"
            value={iban}
            onChange={(e) => setIban(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>

        <div>
          <TextField
            sx={{ m: 1, minWidth: 200 }}
            id="outlined-required"
            label="SWIFT"
            value={swift}
            onChange={(e) => setSwift(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <FormControl sx={{ m: 1, minWidth: 250 }}>
            <InputLabel id="demo-simple-select-label">Currency</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Currency"
              onChange={(event) => setLocalCurrency(event.target.value)}
              defaultValue={localCurrency}
            >
              {currencies.map((key) => {
                return <MenuItem value={key}>{CurrencyEnum[key]}</MenuItem>;
              })}
            </Select>
          </FormControl>
          <TextField
            sx={{ m: 1, minWidth: 200 }}
            id="time"
            label="EUR Exchange Rate"
            type="decimal"
            value={eurExchangeRate}
            onChange={(e) => setEurExchangeRate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <div style={
            {
              margin:"8px"
            }
          }>
          <span>Display Euro?</span>
          <Switch
            checked={displayEuro}
            onChange={(e) => setDisplayEuro(e.target.checked)}
            label="End"
          />
          </div>
          <div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                marginTop: "8px",
                paddingBottom: "16px",
                gap: "1.5rem"
              }}
            >
              <TextField
                label="Notes"
                style={{ width: "40%" }}
                placeholder="MultiLine with rows: 2 and rowsMax: 4"
                value={defaultNotes}
                onChange={(e) => setNotes(e.target.value)}
                multiline
                rows={5}
                maxRows={10}
              />
              <TextField
                label="Terms and Conditions"
                style={{ width: "40%" }}
                placeholder="MultiLine with rows: 2 and rowsMax: 4"
                value={defaultTermsAndConditions}
                onChange={(e) => setTermsAndConditions(e.target.value)}
                multiline
                rows={5}
                maxRows={10}
              />
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                marginTop: "8px",
                paddingBottom: "16px",
                gap: "1.5rem"
              }}
            >
              <TextField
                label="Notes (ENG)"
                style={{ width: "40%" }}
                placeholder="MultiLine with rows: 2 and rowsMax: 4"
                value={notesEng}
                onChange={(e) => setNotesEng(e.target.value)}
                multiline
                rows={5}
                maxRows={10}
              />
              <TextField
                label="Terms and Conditions (ENG)"
                style={{ width: "40%" }}
                placeholder="MultiLine with rows: 2 and rowsMax: 4"
                value={termsAndConditionsEng}
                onChange={(e) => setTermsAndConditionsEng(e.target.value)}
                multiline
                rows={5}
                maxRows={10}
              />
            </div>
            
          </div>
          <Button variant="contained" onClick={hadleOnSaveClick} style={{width: "200px", height: "50px", margin: "8px"}}>
              Save
            </Button>
        </div>
    </>
  );
};

export default CompanyInfoForm;
