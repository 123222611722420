import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../../store/auth-context";
import useApiRequest from "../../store/use-http";
import "./InvoicePage.css"
import { baseUrl } from "../../Config";
import InvoicesTable from "../Table/InvoicesTable";
import { TextField } from "@mui/material";
import { isNull } from "../../utils/Utils";
import { SendGetRequest } from "../../actions/Actions";

const InvoicesPage = () => {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState("");
  const [year, setYear] = useState(new Date().getFullYear());
  const [errorDetails, setErrorDetails] = useState({
    showError: false,
    errorCode: "",
    errorMessage: "",
  });

  const authContext = useContext(AuthContext);

  const url = baseUrl + "/api/invoice/all?year=" + year;

  useEffect(() => {
    SendGetRequest(url, authContext.token, invoicesCallback);
  }, []);

  useEffect(() => {
    SendGetRequest(url, authContext.token, invoicesCallback);
  }, [year]);

  const invoicesCallback = (data) => {
    setInvoices(data);
  }

  const refreshHandler = () => {
    SendGetRequest(url, authContext.token, invoicesCallback);
  }

  return (
    <div className="InvoicesPage">
      <TextField
                value={isNull(year) ? "" : year}
                type="number"
                onChange={(e) => setYear(e.target.value)}
                label="Year"
                InputLabelProps={{
                  shrink: true,
                }}
              />
      <InvoicesTable data={invoices} refreshHandler={refreshHandler}/>
    </div>
  );
};

export default InvoicesPage;
