import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../../store/auth-context";
import useApiRequest from "../../store/use-http";
import { baseUrl } from "../../Config";
import "./CalendarPage.css";
import Calendar from "./Calendar";
import {
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
} from "@mui/material";
import {
  containsRoles,
  convertMinutesToTime,
  getFullName,
  isNull,
} from "../../utils/Utils";
import { SendGetRequest } from "../../actions/Actions";
import BackdropContext from "../../store/backdrop-context";

const CalendarPage = () => {
  const [users, setUsers] = useState([]);
  const [engagements, setEngagements] = useState([]);
  const [calendar, setCalendar] = useState(null);
  const [updateCalendar, setUpdateCalendar] = useState(true);
  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem("user")));
  const [employee, setEmployee] = useState(
    JSON.parse(sessionStorage.getItem("user"))
  );
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear());

  const authContext = useContext(AuthContext);
  const backdropContext = useContext(BackdropContext);

  /*Fetch engagements*/
  const engagementsUrl = baseUrl + "/api/engagement/all/" + employee.id;

  const engagementsCallback = (data) => {
    setEngagements(data);
  };

  useEffect(() => {
    SendGetRequest(engagementsUrl, authContext.token, engagementsCallback);
  }, [employee]);

  /*Fetch users*/
  const usersUrl = baseUrl + "/api/users";

  const usersCallback = (data) => {
    setUsers((prev) => {
      return data;
    });
  };

  useEffect(() => {
    SendGetRequest(usersUrl, authContext.token, usersCallback);
  }, []);

  /*Fetch calendar*/
  const calendarUrl =
    baseUrl + "/api/calendar/all/" + employee.id + "/" + year + "/" + month;

  const calendarCallback = (data) => {
    setCalendar((prev) => {
      return data;
    });
    backdropContext.toggle();
  };
  useEffect(() => {
    backdropContext.toggle();
    SendGetRequest(calendarUrl, authContext.token, calendarCallback);
  }, [updateCalendar, employee, month]);

  /***********/

  const refreshHandler = () => {
    setUpdateCalendar((prev) => !prev);
  };

  const handleEmployeeSelect = (event) => {
    const employee = users.find((c) => c.id === event.target.value);
    setEmployee(prev => {return employee;});
  };

  const handleYearMonthChange = (year, month) => {
    setYear(year);
    setMonth(month);
  };

  return (
    <div className="CalendarPage">
      {containsRoles(
        user.roles.map((role) => role.name),
        "ROLE_ADMIN"
      ) && (
        <div>
          <FormControl sx={{ m: 1, minWidth: 500 }}>
            <InputLabel id="demo-simple-select-label">Employee</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Language"
              onChange={handleEmployeeSelect}
              value={isNull(employee) ? "" : employee.id}
            >
              {users &&
                users.map((data) => {
                  return (
                    <MenuItem value={data.id} key={data.id}>
                      {getFullName(data)}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </div>
      )}
      <Calendar
        calendar={calendar}
        employee={employee}
        users={users}
        engagements={engagements}
        refreshHandler={refreshHandler}
        handleYearMonthChange={handleYearMonthChange}
      />

      <TableContainer component={Paper} sx={{ marginTop: 1 }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ border: 0 }}>
                <span>
                  <b>Regular work: </b>
                </span>
                <span>
                  {isNull(calendar)
                    ? 0
                    : convertMinutesToTime(
                        calendar.worktimeSummaryDto.regularWork
                      )}
                </span>
              </TableCell>
              <TableCell style={{ border: 0 }}>
                <span>
                  <b>Overtime work: </b>
                </span>
                <span>
                  {isNull(calendar)
                    ? 0
                    : convertMinutesToTime(
                        calendar.worktimeSummaryDto.overtimeWork
                      )}
                </span>
              </TableCell>
              <TableCell style={{ border: 0 }}>
                <span>
                  <b>Passive overtime work: </b>
                </span>
                <span>
                  {isNull(calendar)
                    ? 0
                    : convertMinutesToTime(
                        calendar.worktimeSummaryDto.passiveOvertimeWork
                      )}
                </span>
              </TableCell>
              <TableCell style={{ border: 0 }}>
                <span>
                  <b>Total logged time: </b>
                </span>
                <span>
                  {isNull(calendar)
                    ? 0
                    : convertMinutesToTime(
                        calendar.worktimeSummaryDto.totalLoggedTime
                      )}
                </span>
              </TableCell>
              <TableCell style={{ border: 0 }}>
                <span>
                  <b>Used vacation days ({calendar && calendar.worktimeSummaryDto.year}.): </b>
                </span>
                <span>
                  {isNull(calendar) ? 0 : calendar.worktimeSummaryDto.usedVacationDays
                  }
                </span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CalendarPage;
