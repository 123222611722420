import AbsentTimeTypeEnum from "../enum/AbsentTimeTypeEnum";
import Color from "../enum/Color";

export const isNull = (data) => {
  return data == null || data === undefined || data.length === 0;
};

export const roundToTwo = (num) => {
  return +(Math.round(num + "e+2") + "e-2");
};

export const convertToEur = (amount) => {
  const companyInfo = JSON.parse(sessionStorage.getItem("companyInfo"));
  return roundToTwo(amount * companyInfo.eurExchangeRate);
};

export const formatPrice = (x) => {
  return Number.parseFloat(x)
    .toFixed(2)
    .toString()
    .replace(".", ",")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const formatBarcodePrice = (price) => {
  let init = "000000000000000";
  const priceString = Number.parseFloat(price)
    .toFixed(2)
    .toString()
    .replace(".", "")
    .replace(",", "");

  return init.substring(0, 15 - priceString.length) + priceString;
};

export const formatDate = (date) => {
  const dateArray = date.split("-");
  return dateArray[2] + "." + dateArray[1] + "." + dateArray[0] + ".";
};

export const formatTime = (time) => {
  const timeArray = time.split(":");
  return timeArray[0] + ":" + timeArray[1];
};

export const formatDateTime = (dateTime) => {
  const dateTimeArray = dateTime.split("T");
  const date = formatDate(dateTimeArray[0]);
  const time = formatTime(dateTimeArray[1]);
  return date + " " + time;
};

export const containsRoles = (userRoles, definedRoles) => {
  return userRoles.some((role) => definedRoles.indexOf(role) !== -1);
};

export const getFullName = (employee) => {
  if (isNull(employee)) {
    return "";
  } else {
    return employee.firstName + " " + employee.lastName;
  }
};

export const getCurrentDate = () => {
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();

  today = yyyy + "-" + mm + "-" + dd;
  return today;
};

export const convertDate = (date) => {
  let dd = String(date.getDate()).padStart(2, "0");
  let mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = date.getFullYear();

  return yyyy + "-" + mm + "-" + dd;
};

export const generateCalendarEvents = (calendar) => {
  let events = [];
  if(isNull(calendar)) {
    return []
  } else {
    calendar.worktimes.forEach(wt => {
      events.push(
        {
          id: wt.id,
          date: wt.date,
          title: wt.startTime.substring(0,5) + "-" + wt.endTime.substring(0,5) + " " + wt.task,
          color: Color.SUCCESS
        }
      )
    });

    calendar.overtimes.forEach(wt => {
      events.push(
        {
          id: wt.id,
          date: wt.date,
          title: wt.startTime.substring(0,5) + "-" + wt.endTime.substring(0,5) + " " + wt.task,
          color: Color.WARNING
        }
      )
    });

    calendar.passiveOvertimes.forEach(wt => {
      events.push(
        {
          id: wt.id,
          date: wt.date,
          title: wt.startTime.substring(0,5) + "-" + wt.endTime.substring(0,5) + " " + wt.task,
          color: Color.BACKGROUND
        }
      )
    });

    calendar.absentTimes.forEach(at => {
      let end = new Date(at.endDate);
      events.push(
        {
          id: at.id,
          start: at.startDate,
          end: end.setDate(end.getDate() + 1),
          allDay: true,
          title: AbsentTimeTypeEnum[at.type],
          color: Color.HOLIDAY,
          description: "AT"
        }
      )
    });

    calendar.holidays.forEach(h => {
      events.push(
        {
          date: h.date,
          title: h.name,
          color: Color.DANGER
        }
      )
    });
  }
  return events;
}

export const convertMinutesToTime = (min) => {
  if(isNull(min)) {
    return "00:00"
  } else {
    let hours = Math.trunc(min/60);
    let minutes = min % 60;
    if(minutes.toString().length === 1) {
      minutes = minutes.toString() + "0";
    }
    return hours +" h "+ minutes + " min";
  }
}
