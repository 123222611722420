import ReactDOM from "react-dom";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import "./Modal.css";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import CurrencyEnum from "../../enum/CurrencyEnum";
import UnitEnum from "../../enum/UnitEnum";
import AuthContext from "../../store/auth-context";
import BackdropContext from "../../store/backdrop-context";
import { SendPostRequest, SendPutRequest } from "../../actions/Actions";
import { isNull } from "../../utils/Utils";
import { baseUrl } from "../../Config";

const ModalContent = (props) => {
  const currencies = Object.keys(CurrencyEnum);
  const units = Object.keys(UnitEnum);
  const data = props.data;
  const taxes = props.taxes;
  const [loading, setLoading] = useState(true);
  const [errorDetails, setErrorDetails] = useState({
    showError: false,
    errorCode: "",
    errorMessage: "",
  });

  const authContext = useContext(AuthContext);
  const backdropContext = useContext(BackdropContext);

  const nameInputRef = useRef();
  const priceInputRef = useRef();


  const [itemType, setItemType] = useState();
  const [itemCurrency, setItemCurrency] = useState();
  const [itemUnit, setItemUnit] = useState();
  const [itemTax, setTax] = useState();

  useEffect(() => {
    if (!isNull(data)) {
      setItemType(data.isService);
      setItemCurrency(data.currency);
      setItemUnit(data.unit);
      setTax(data.tax);
    }
  }, [data]);

  const handleItemTypeSelect = (event) => {
    setItemType(event.target.value);
  };

  const handleCurrencySelect = (event) => {
    setItemCurrency(event.target.value);
  };

  const handleUnitSelect = (event) => {
    setItemUnit(event.target.value);
  };

  const handleTaxSelect = (event) => {
    const tax = taxes.find((t) => t.id === event.target.value);
    setTax(tax);
  };

  const hadleOnSaveClick = () => {
    const item = {
      isService: itemType,
      name: nameInputRef.current.value,
      price: priceInputRef.current.value,
      currency: itemCurrency,
      unit: itemUnit,
      tax: itemTax,
    };
    saveHandler(item);
    backdropContext.toggle();
  };

  const hadleOnUpdateClick = () => {
    const item = {
      id: data.id,
      isService: itemType,
      name: nameInputRef.current.value,
      price: priceInputRef.current.value,
      currency: itemCurrency,
      unit: itemUnit,
      tax: itemTax,
    };
    updateHandler(item);
    backdropContext.toggle();
  };

  const saveHandler = async (item) => {
    const url = baseUrl + "/api/item";
    SendPostRequest(url, item, authContext.token, itemsHandler);
  };

  const updateHandler = async (item) => {
    const url = baseUrl + "/api/item";
    SendPutRequest(url, item, authContext.token, itemsHandler);
  };

  const itemsHandler = () => {
    backdropContext.toggle();
    props.onHide();
  }

  return (
    <div>
      <Dialog open={props.isVisible} maxWidth="lg">
        <DialogTitle>
          {isNull(data) ? "New Item" : data.name}
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <div>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Item type
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={handleItemTypeSelect}
                    defaultValue={isNull(data) ? "" : data.isService}
                  >
                    <FormControlLabel
                      disabled={props.isReadOnly}
                      value="true"
                      control={<Radio />}
                      label="Service"
                    />
                    <FormControlLabel
                      disabled={props.isReadOnly}
                      value="false"
                      control={<Radio />}
                      label="Goods"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div>
                <TextField
                  id="full-width-text-field"
                  fullWidth
                  disabled={props.isReadOnly}
                  label="Name"
                  inputRef={nameInputRef}
                  defaultValue={isNull(data) ? "" : data.name}
                />
              </div>
              <div>
                <TextField
                  disabled={props.isReadOnly}
                  id="outlined-disabled"
                  label="Price"
                  inputRef={priceInputRef}
                  defaultValue={isNull(data) ? "" : data.price}
                />
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-label">
                    Unit
                  </InputLabel>
                  <Select
                    disabled={props.isReadOnly}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Unit"
                    onChange={handleUnitSelect}
                    defaultValue={isNull(data) ? "" : data.unit}
                  >
                    {units.map((key) => {
                      return (
                        <MenuItem value={key}>{UnitEnum[key]}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-label">
                    Currency
                  </InputLabel>
                  <Select
                    disabled={props.isReadOnly}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Currency"
                    onChange={handleCurrencySelect}
                    defaultValue={isNull(data) ? "" : data.currency}
                  >
                    {currencies.map((key) => {
                      return (
                        <MenuItem value={key}>{CurrencyEnum[key]}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              <div>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-label">Tax</InputLabel>
                  <Select
                    disabled={props.isReadOnly}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Tax"
                    onChange={handleTaxSelect}
                    defaultValue={isNull(data) ? "" : data.tax && data.tax.id}
                  >
                    {taxes.map((data) => {
                      return (
                        <MenuItem value={data.id}>
                          {data.taxName + " " + data.taxRate + "%"}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            className="cancelButton"
            variant="contained"
            onClick={props.onHide}
          >
            {props.isReadOnly ? "Close" : "Cancel"}
          </Button>
          <Button
            disabled={props.isReadOnly}
            variant="contained"
            onClick={data && data.id ? hadleOnUpdateClick : hadleOnSaveClick}
          >
            {data && data.id ? "Update" : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const ItemModal = (props) => {
  const onHide = () => {
    props.closeModalHandler();
  };

  return (
    <Fragment>
      {ReactDOM.createPortal(
        <ModalContent
          isVisible={props.isVisible}
          onHide={onHide}
          data={props.modalData}
          taxes={props.taxes}
          isReadOnly={props.isReadOnly}
        />,
        document.getElementById("modal-root")
      )}
    </Fragment>
  );
};

export default ItemModal;
