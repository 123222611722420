import React, { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import {
  containsRoles,
  convertDate,
  generateCalendarEvents,
  getCurrentDate,
  isNull,
} from "../../utils/Utils";
import styled from "@emotion/styled";
import Color from "../../enum/Color";
import WorktimeModal from "../Modal/WorktimeModal";
import {
  UilPlus,
  UilBag,
  UilAngleDown,
  UilCopy,
  UilTrashAlt,
} from "@iconscout/react-unicons";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import AbsentTimeModal from "../Modal/AbsentTimeModal";
import CopyWorktimeModal from "../Modal/CopyWorktimeModal";
import DeleteWorktimeModal from "../Modal/DeleteWorktimeModal";

export const StyleWrapper = styled.div`
  .fc .fc-non-business {
    background-color: ${Color.DANGER};
    opacity: 0.1;
  }
  .fc .fc-daygrid-day.fc-day-today {
    background-color: ${Color.ACCENT1};
  }

  .fc-h-event {
    white-space: normal;
  }

  .fc .fc-button-primary {
    background-color: ${Color.BACKGROUND};
    border-color: ${Color.ACCENT3};
  }

  .fc .fc-button-primary:active {
    background-color: ${Color.BACKGROUND};
    border-color: ${Color.ACCENT3};
  }

  .fc .fc-daygrid-day-number {
    width: 100%;
  }

  .fc .fc-daygrid-body-natural {
    width: 100% !important;
  }
  .fc .fc-scrollgrid-sync-table {
    width: 100% !important;
  }
  .fc .fc-col-header {
    width: 100% !important;
  }
`;

const dayContentStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
};

const Calendar = (props) => {
  const [showWTModal, setWTShowModal] = useState(false);
  const [modalDataWT, setModalDataWT] = useState("");
  const [showCopyWTModal, setCopyWTShowModal] = useState(false);
  const [showDeleteWTModal, setDeleteWTShowModal] = useState(false);
  const [deleteModalData, setDeleteModalData] = useState([]);
  const [showATModal, setATShowModal] = useState(false);
  const [modalDataAT, setModalDataAT] = useState("");
  const [selectedDate, setSelectedDate] = useState(getCurrentDate());
  const [contextMenu, setContextMenu] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [contextArgs, setContextArgs] = useState(null);

  const engagements = props.engagements;
  const users = props.users;
  const calendar = props.calendar;
  const loggedinUser = JSON.parse(sessionStorage.getItem("user"));

  const closeModalHandlerWT = () => {
    setModalDataWT((prev) => {
      return null;
    });
    setWTShowModal((prev) => {
      return !prev;
    });
    setSelectedDate((prev) => {
      return getCurrentDate();
    });

    props.refreshHandler();
  };

  const closeModalHandlerCopyWT = () => {
    setCopyWTShowModal((prev) => {
      return !prev;
    });
    setSelectedDate((prev) => {
      return getCurrentDate();
    });

    props.refreshHandler();
  };

  const closeModalHandlerDeleteWT = () => {
    setDeleteWTShowModal((prev) => {
      return !prev;
    });
    setSelectedDate((prev) => {
      return getCurrentDate();
    });

    props.refreshHandler();
  };

  const showDetailsWT = (rowData) => {
    setWTShowModal((prev) => {
      return !prev;
    });
    setModalDataWT((prev) => {
      return rowData;
    });
  };

  const showDetailsCopyWT = (rowData) => {
    setCopyWTShowModal((prev) => {
      return !prev;
    });
  };

  const closeModalHandlerAT = () => {
    setModalDataAT((prev) => {
      return null;
    });
    setATShowModal((prev) => {
      return !prev;
    });
    setSelectedDate((prev) => {
      return getCurrentDate();
    });

    props.refreshHandler();
  };

  const showDetailsAT = (rowData) => {
    setATShowModal((prev) => {
      return !prev;
    });
    setModalDataAT((prev) => {
      return rowData;
    });
  };

  const handleEventClick = (event) => {
    const eventId = event.id;
    const source = event.extendedProps;
    if (!isNull(eventId)) {
      if (isNull(source.description)) {
        const worktimes = []
          .concat(...calendar.worktimes)
          .concat(...calendar.overtimes)
          .concat(...calendar.passiveOvertimes);
        const selectedWorktime = worktimes.find((wt) => wt.id === +eventId);
        showDetailsWT(selectedWorktime);
      } else {
        const selectedAT = calendar.absentTimes.find(
          (at) => at.id === +eventId
        );
        showDetailsAT(selectedAT);
      }
    }
  };

  function getCalendarData(fetchInfo, successCallback) {
    try {
      let year = new Date().getFullYear();
      let monthStart = new Date().getMonth() + 1;
      let monthEnd = new Date().getMonth() + 1;

      if (fetchInfo) {
        year = new Date(fetchInfo.start).getFullYear();
        monthStart = new Date(fetchInfo.start).getMonth() + 1;
        monthEnd = new Date(fetchInfo.end).getMonth() + 1;
      }

      let month = monthStart;

      if (monthEnd - monthStart === 1) {
        month = monthStart;
      } else {
        month = monthStart + 1;
      }

      if (month === 13) {
        month = 1;
        year = year + 1;
      }
      props.handleYearMonthChange(year, month);
      successCallback(!isNull(calendar) && generateCalendarEvents(calendar));
    } catch (error) {
      console.log(error);
    }
  }

  const handleDayWTClick = (args) => {
    console.log(args);
    setSelectedDate((prevState) => {
      return convertDate(args.date);
    });
    showDetailsWT(null);
    handleClose();
  };

  const handleDayCopyWTClick = (args) => {
    setSelectedDate((prevState) => {
      return convertDate(args.date);
    });
    showDetailsCopyWT(null);
    handleClose();
  };

  const handleDayDeleteWTClick = (args) => {
    const worktimes = calendar.worktimes.filter(
      (w) => w.date === convertDate(args.date)
    );
    setDeleteModalData(worktimes);
    setDeleteWTShowModal(true);
    handleClose();
  };

  const handleDayATClick = (args) => {
    setSelectedDate((prevState) => {
      return convertDate(args.date);
    });
    showDetailsAT(null);
    handleClose();
  };

  const checkIfHoliday = (date) => {
    if (isNull(props.calendar)) {
      return false;
    } else {
      const holidayDates = [].concat(
        ...props.calendar.holidays.map((h) => h.date)
      );
      const calendarDate = convertDate(date);
      return holidayDates.includes(calendarDate);
    }
  };

  const checkIfAbsentTime = (date) => {
    const absentTimes = isNull(props.calendar)
      ? []
      : props.calendar.absentTimes;
    const calendarDate = convertDate(date);
    if (isNull(absentTimes)) {
      return false;
    } else {
      let isInRange = false;
      absentTimes.forEach((at) => {
        if (
          (date > new Date(at.startDate) && date < new Date(at.endDate)) ||
          calendarDate === at.startDate ||
          calendarDate === at.endDate
        ) {
          isInRange = true;
        }
      });
      return isInRange;
    }
  };

  const handleMenuOpen = (args, event) => {
    setAnchorEl(event.currentTarget);
    setContextMenu(true);
    setContextArgs(args);
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const injectCellContent = (args) => {
    let today = new Date();
    let date = args.date;
    let space = today.getDay() === 0 || today.getDay() === 6 ? 5 : 3;

    const diffDays = Math.ceil(Math.abs(today - date) / (1000 * 60 * 60 * 24));

    return (
      <div style={dayContentStyle}>
        <div>
          {(containsRoles([].concat(...loggedinUser.roles.map((r) => r.name)), [
            "ROLE_ADMIN",
          ]) ||
            diffDays <= space) &&
            !args.isFuture &&
            !checkIfHoliday(date) &&
            !checkIfAbsentTime(date) && (
              <IconButton
                onClick={(e) => handleMenuOpen(args, e)}
                id="dropdown-icon"
                aria-controls={contextMenu ? "menu" : undefined}
                aria-haspopup="true"
                aria-expanded={contextMenu ? "true" : undefined}
              >
                <UilAngleDown style={{ width: "14px", height: "14px" }} />
              </IconButton>
            )}

          {(diffDays <= space || args.isFuture) &&
            !checkIfHoliday(date) &&
            !checkIfAbsentTime(date) && (
              <IconButton onClick={() => handleDayATClick(args)}>
                <UilBag style={{ width: "14px", height: "14px" }} />
              </IconButton>
            )}
        </div>

        <div style={{ height: "30px" }}></div>
        <span>{args.dayNumberText}</span>
      </div>
    );
  };

  return (
    <div style={{ marginTop: "16px" }}>
      <WorktimeModal
        isVisible={showWTModal}
        closeModalHandler={closeModalHandlerWT}
        modalData={modalDataWT}
        engagements={engagements}
        users={users}
        selectedDate={selectedDate}
        employee={props.employee}
      />

      <DeleteWorktimeModal
        isVisible={showDeleteWTModal}
        closeModalHandler={closeModalHandlerDeleteWT}
        modalData={deleteModalData}
        selectedDate={selectedDate}
        employee={props.employee}
      />

      <CopyWorktimeModal
        isVisible={showCopyWTModal}
        closeModalHandler={closeModalHandlerCopyWT}
        modalData={modalDataWT}
        users={users}
        selectedDate={selectedDate}
        employee={props.employee}
      />

      <AbsentTimeModal
        isVisible={showATModal}
        closeModalHandler={closeModalHandlerAT}
        modalData={modalDataAT}
        selectedDate={selectedDate}
        employee={props.employee}
      />

      <StyleWrapper>
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          events={(fetchInfo, successCallback, failureCallback) =>
            getCalendarData(fetchInfo, successCallback, failureCallback)
          }
          contentHeight="auto"
          businessHours={{
            // days of week. an array of zero-based day of week integers (0=Sunday)
            daysOfWeek: [1, 2, 3, 4, 5], // Monday - Thursday
          }}
          firstDay={1}
          customButtons={{
            newWorktime: {
              text: "New Worktime",
              click: function () {
                showDetailsWT();
              },
            },
          }}
          headerToolbar={{
            right: "prev,today,next",
            center: "title",
            left: "newWorktime",
          }}
          eventClick={(ev) => handleEventClick(ev.event)}
          dayCellContent={(args) => injectCellContent(args)}
        />
      </StyleWrapper>
      <Menu
          role="menu"
          keepMounted={true}
          open={contextMenu || null}
          onClose={handleClose}
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          disableAutoFocusItem
          disableScrollLock={true}
          variant="menu"
          MenuListProps={{
            "aria-labelledby":"dropdown-icon"
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem onClick={() => handleDayWTClick(contextArgs)}>
            <ListItemIcon>
              <UilPlus style={{ width: "14px", height: "14px" }} />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="subtitle2">Add worktime</Typography>
            </ListItemText>
          </MenuItem>
          <MenuItem onClick={() => handleDayCopyWTClick(contextArgs)}>
            <ListItemIcon>
              <UilCopy style={{ width: "14px", height: "14px" }} />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="subtitle2">
                Copy worktime from another day
              </Typography>
            </ListItemText>
          </MenuItem>
          <MenuItem onClick={() => handleDayDeleteWTClick(contextArgs)}>
            <ListItemIcon>
              <UilTrashAlt style={{ width: "14px", height: "14px" }} />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="subtitle2">Delete worktime</Typography>
            </ListItemText>
          </MenuItem>
        </Menu>
    </div>
  );
};

export default Calendar;
