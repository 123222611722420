import ReactDOM from "react-dom";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Box,
  FormControl,
  FormControlLabel,
  Divider,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Typography,
  Checkbox,
} from "@mui/material";
import "./Modal.css";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../../store/auth-context";
import BackdropContext from "../../store/backdrop-context";
import { SendPostRequest, SendPutRequest } from "../../actions/Actions";
import { isNull } from "../../utils/Utils";
import { baseUrl } from "../../Config";
import UserStatusEnum from "../../enum/UserStatusEnum";
import EmploymentTypeEnum from "../../enum/EmploymentTypeEnum";

const ModalContent = (props) => {
  const userStatuses = Object.keys(UserStatusEnum);
  const employmentTypes = Object.keys(EmploymentTypeEnum);
  const [data, setData] = useState({
    firstName: null,
    lastName: null,
    email: null,
    username: null,
    roles: [],

    userStatus: null,
    employmentType: null,
  });
  const [userRoles, setUserRoles] = useState([]);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorDetails, setErrorDetails] = useState({
    showError: false,
    errorCode: "",
    errorMessage: "",
  });


  const authContext = useContext(AuthContext);
  const backdropContext = useContext(BackdropContext);

  const firstNameInputRef = useRef();
  const lastNameInputRef = useRef();
  const emailInputRef = useRef();
  const usernameInputRef = useRef();

  const [userStatus, setUserStatus] = useState(null);
  const [employmentType, setEmploymentType] = useState(null);

  useEffect(() => {
    setRoles(props.roles);
    if(!isNull(props.data)) {
      setData(props.data);
      setUserRoles(props.data.roles.map(role => role.name));
    }
  }, [props]);

  useEffect(() => {
    if (!isNull(data)) {
      setUserStatus(data.userStatus);
      setEmploymentType(data.employmentType);
    }
  }, [data]);

  const handleUserStatusSelect = (event) => {
    setUserStatus(prev => {return event.target.value});
    setData(prev => {
      return{...prev,
      userStatus: event.target.value}
    })
    
  };

  const handleEmploymentTypeSelect = (event) => {
    setEmploymentType(prev => {return event.target.value});
    setData(prev => {
      return{...prev,
      employmentType: event.target.value}
    })
    
  };

  const hadleOnSaveClick = () => {
    const user = {
      firstName: firstNameInputRef.current.value,
      lastName: lastNameInputRef.current.value,
      email: emailInputRef.current.value,
      username: usernameInputRef.current.value,
      roles: data.roles,

      userStatus: userStatus,
      employmentType: employmentType,
    };
    saveHandler(user);
    backdropContext.toggle();
  };

  const hadleOnUpdateClick = () => {
    const user = {
      id: data.id,
      firstName: firstNameInputRef.current.value,
      lastName: lastNameInputRef.current.value,
      email: emailInputRef.current.value,
      username: usernameInputRef.current.value,
      roles: data.roles,

      userStatus: userStatus,
      employmentType: employmentType,
    };
    updateHandler(user);
    backdropContext.toggle();
  };

  const handleRoleSelect = (role, isChecked) => {

    let newRoles = [];
    if(isChecked) {
      newRoles = isNull(data) ? [] : data.roles;
      newRoles.push(role)
    } else {
      newRoles = data.roles.filter(r => r.id !== role.id);
    }

    setData(prev => {
      return{...prev,
      roles: newRoles.length > 0 ? newRoles : []}
    })

    setUserRoles(prev => {
      return newRoles.length > 0 ? newRoles.map(r => r.name) : [];
    })

  }

  const saveHandler = async (user) => {
    const url = baseUrl + "/api/user";
    SendPostRequest(url, user, authContext.token, usersHandler);
  };

  const updateHandler = async (user) => {
    const url = baseUrl + "/api/user";
    SendPutRequest(url, user, authContext.token, usersHandler);
  };

  const usersHandler = () => {
    backdropContext.toggle();
    props.onHide();
  }

  return (
    <div>
      <Dialog open={props.isVisible} maxWidth="lg">
        <DialogTitle>
          {isNull(data.firstName) ? "New User" : data.firstName + " " + data.lastName}
        </DialogTitle>
        <DialogContent style={{ display: "flex", flexDirection: "row" }}>
          <Box
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <div>
                <TextField
                  disabled={props.isReadOnly}
                  id="outlined-required"
                  label="First Name"
                  inputRef={firstNameInputRef}
                  defaultValue={isNull(data) ? "" : data.firstName}
                />
                <TextField
                  disabled={props.isReadOnly}
                  id="outlined-disabled"
                  label="Last Name"
                  inputRef={lastNameInputRef}
                  defaultValue={isNull(data) ? "" : data.lastName}
                />
                <TextField
                  disabled={props.isReadOnly}
                  id="outlined-required"
                  label="Username"
                  inputRef={usernameInputRef}
                  defaultValue={isNull(data) ? "" : data.username}
                />
              </div>

              <Divider />
              <div>
                <TextField
                  sx={{ minWidth: 300 }}
                  disabled={props.isReadOnly}
                  id="outlined-required"
                  label="Email"
                  inputRef={emailInputRef}
                  defaultValue={data && data.email}
                />
              </div>
              <Divider />
              <div>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-label">Status</InputLabel>
                  <Select
                    disabled={props.isReadOnly}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Currency"
                    onChange={handleUserStatusSelect}
                    defaultValue={isNull(data) ? "" : data.userStatus}
                  >
                    {userStatuses.map((key) => {
                      return (
                        <MenuItem value={key}>{UserStatusEnum[key]}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-label">Type</InputLabel>
                  <Select
                    disabled={props.isReadOnly}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Language"
                    onChange={handleEmploymentTypeSelect}
                    defaultValue={isNull(data) ? "" : data.employmentType}
                  >
                    {employmentTypes.map((key) => {
                      return (
                        <MenuItem value={key}>
                          {EmploymentTypeEnum[key]}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
          </Box>

          <Divider orientation="vertical" flexItem style={{ margin: "8px" }} />
          <Box>
            <Typography variant="h6">Permissions:</Typography>
            <Grid container spacing={2}>
              {
                !isNull(roles) && roles.map(role => {
                  return <Grid item xs={6}>
                  <FormControlLabel control={
                    <Checkbox 
                      disabled={props.isReadOnly} 
                      checked={userRoles.includes(role.name)} />} 
                      label={role.name}
                      onChange={(e) => handleRoleSelect(role, e.target.checked)} 
                  />
                </Grid>
                })
              }
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            className="cancelButton"
            variant="contained"
            onClick={props.onHide}
          >
            {props.isReadOnly ? "Close" : "Cancel"}
          </Button>
          <Button
            disabled={props.isReadOnly}
            variant="contained"
            onClick={data && data.id ? hadleOnUpdateClick : hadleOnSaveClick}
          >
            {data && data.id ? "Update" : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const UserModal = (props) => {
  const onHide = () => {
    props.closeModalHandler();
  };

  return (
    <Fragment>
      {ReactDOM.createPortal(
        <ModalContent
          isVisible={props.isVisible}
          onHide={onHide}
          data={props.modalData}
          roles={props.roles}
          isReadOnly={props.isReadOnly}
        />,
        document.getElementById("modal-root")
      )}
    </Fragment>
  );
};

export default UserModal;
