import {
  Button,
  TextField,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../store/auth-context";
import { SendGetRequest, SendPutRequest } from "../../actions/Actions";
import {baseUrl} from "../../Config";

const LocalTravelSettingsPage = () => {
 
  const [minDailyDistance, setMinDailyDistance] = useState();
  const [maxDailyDistance, setMaxDailyDistance] = useState();
  const [maxWeekendDistance, setMaxWeekendDistance] = useState();
  const [localTravelSettings, setLocalTravelSettings] = useState(null);


  const authContext = useContext(AuthContext);

  const url = baseUrl + "/api/local-travel/settings";

  useEffect(() => {
    SendGetRequest(url, authContext.token, localTravelSettingsCallback);
  }, [])

  const localTravelSettingsCallback = (data) => {
    setLocalTravelSettings(data);
    setMinDailyDistance(data.minDailyDistance);
    setMaxDailyDistance(data.maxDailyDistance);
    setMaxWeekendDistance(data.maxWeekendDistance);
  };

  const hadleOnSaveClick = () => {
    const info = {
      id: localTravelSettings.id,
      minDailyDistance: minDailyDistance,
      maxDailyDistance: maxDailyDistance,
      maxWeekendDistance, maxWeekendDistance
    };
    updateHandler(info);
  };

  const updateHandler = async (data) => {
    SendPutRequest(url, data, authContext.token, refreshHandler);
  };

  const refreshHandler = () => {
    SendGetRequest(url, authContext.token, localTravelSettingsCallback);
  }

  return (
    <>
        <div>
          <TextField
            sx={{ m: 1, minWidth: 200 }}
            id="full-width-text-field"
            label="Min daily distance"
            value={minDailyDistance}
            onChange={(e) => setMinDailyDistance(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            sx={{ m: 1, minWidth: 200 }}
            id="outlined-required"
            label="Max daily distance"
            value={maxDailyDistance}
            onChange={(e) => setMaxDailyDistance(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            sx={{ m: 1, minWidth: 200 }}
            id="outlined-required"
            label="Max weeneknd distance"
            value={maxWeekendDistance}
            onChange={(e) => setMaxWeekendDistance(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <Button variant="contained" onClick={hadleOnSaveClick} style={{width: "200px", height: "50px", margin: "8px"}}>
              Save
        </Button>
    </>
  );
};

export default LocalTravelSettingsPage;
