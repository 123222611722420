import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../../store/auth-context";
import useApiRequest from "../../store/use-http";
import "./ItemsPage.css"
import { baseUrl } from "../../Config";
import ItemsTable from "../Table/ItemsTable";
import { SendGetRequest } from "../../actions/Actions";

const ItemsPage = () => {
  const [items, setItems] = useState([]);
  const [taxData, setTaxData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState("");
  const [errorDetails, setErrorDetails] = useState({
    showError: false,
    errorCode: "",
    errorMessage: "",
  });

  const authContext = useContext(AuthContext);

  const taxUrl = baseUrl + "/api/tax/all";
  const url = baseUrl + "/api/item/all";

  useEffect(() => {
    SendGetRequest(taxUrl, authContext.token, taxCallback);
    SendGetRequest(url, authContext.token, itemsCallback);

  }, [])

  const taxCallback = (data) => {
    setTaxData(data);
  }

  const itemsCallback = (data) => {
    setItems(data);
  }

  const refreshHandler = () => {
    SendGetRequest(taxUrl, authContext.token, taxCallback);
    SendGetRequest(url, authContext.token, itemsCallback);
  }

  return (
    <div className="ItemsPage">
      <ItemsTable data={items} taxes={taxData} refreshHandler={refreshHandler} />
    </div>
  );
};

export default ItemsPage;
