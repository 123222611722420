import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../../store/auth-context";
import { baseUrl } from "../../Config";
import "./ProjectsPage.css";
import ProjectsTable from "../Table/ProjectsTable";
import { SendGetRequest } from "../../actions/Actions";

const ProjectsPage = () => {
  const [customers, setCustomers] = useState([]);
  const [projects, setProjects] = useState([]);

  const authContext = useContext(AuthContext);

  const projectsUrl = baseUrl + "/api/project/all";
  const customersUrl = baseUrl + "/api/customer/all";

  useEffect(() => {
    SendGetRequest(projectsUrl, authContext.token, projectsHandler);
    SendGetRequest(customersUrl, authContext.token, customersHandler);
  }, [])

  const projectsHandler = (data) => {
    setProjects(data);
  }

  const customersHandler = (data) => {
    setCustomers(data);
  }

  const refreshHandler = () => {
    SendGetRequest(projectsUrl, authContext.token, projectsHandler);
    SendGetRequest(customersUrl, authContext.token, customersHandler);
  }

  return (
    <div className="CustomerPage">
      <ProjectsTable projects={projects} customers={customers} refreshHandler={refreshHandler} />
    </div>
  );
};

export default ProjectsPage;
