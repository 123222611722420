import ReactDOM from "react-dom";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Box,
} from "@mui/material";
import "./Modal.css";
import { Fragment, useContext, useEffect, useState } from "react";
import AuthContext from "../../store/auth-context";
import {
  SendPostRequest,
} from "../../actions/Actions";
import { isNull } from "../../utils/Utils";
import { baseUrl } from "../../Config";
import BackdropContext from "../../store/backdrop-context";

const ModalContent = (props) => {
  let data = props.data;

  const authContext = useContext(AuthContext);
  const backdropContext = useContext(BackdropContext);

  const [date, setDate] = useState(props.selectedDate);
  const [copyDate, setCopyDate] = useState(null);
  const [employee, setEmployee] = useState(props.employee);
  const [errors, setErrors] = useState({
    copyDate: true,
    date: false,
  });
  const [formValid, setFormValid] = useState(false);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setDate(props.selectedDate);
    setEmployee(props.employee);
  }, [props.selectedDate, props.employee]);

  const clearStates = () => {
    setDate(props.selectedDate);
    setCopyDate(null);
    setErrors({
      copyDate: true,
      date: false,
    });
  };

  useEffect(() => {
    if (!isNull(data)) {
      setDate(data.date);
      setEmployee(data.employee);
      setErrors({});
    } else {
      clearStates();
    }
  }, [data]);

  const hadleOnSaveClick = () => {
    backdropContext.toggle();
    const copyRequest={
      date: date,
      copyDate: copyDate,
      employeeId: employee.id
    }
    saveHandler(copyRequest);
  };


  const saveCallback = () => {
    clearStates();
    backdropContext.toggle();
    props.onHide();
  };

  const saveHandler = async (copyRequest) => {
    const url = baseUrl + "/api/worktime/copy";
    SendPostRequest(url, copyRequest, authContext.token, saveCallback);
  };

  const closeHandler = () => {
    clearStates();
    data = null;
    props.onHide();
  };

  const blurHandler = (event) => {
    setErrors((prev) => ({
      ...errors,
      [event.target.name]: !event.target.value,
    }));
  };

  useEffect(() => {
    validateForm();
  }, [errors]);

  const validateForm = () => {
    const bools = Object.values(errors);
    setFormValid((prev) => {
      return !bools.includes(true);
    });
  };

  const handleDateSelect = (event, value) => {
    setCopyDate(value);
    blurHandler(event);
  };

  return (
    <div>
      <Dialog open={props.isVisible} maxWidth="lg">
        <DialogTitle>
          {"Copy worktime from another day"}
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <div>
                <TextField
                  required
                  error={errors.date}
                  sx={{ m: 1, minWidth: 200 }}
                  id="outlined-required"
                  label="Date"
                  type="date"
                  name="date"
                  value={date || ""}
                  onChange={(e) => setDate(e.target.value)}
                  onBlur={blurHandler}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>

              <div>
                <TextField
                  required
                  error={errors.copyDate}
                  sx={{ m: 1, minWidth: 200 }}
                  id="outlined-required"
                  label="Copy date"
                  type="date"
                  name="copyDate"
                  value={copyDate || ""}
                  onChange={(e) => handleDateSelect(e, e.target.value)}
                  onBlur={blurHandler}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            className="cancelButton"
            variant="contained"
            onClick={closeHandler}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={!formValid}
            onClick={hadleOnSaveClick}
            type="submit"
          >
            {"Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const CopyWorktimeModal = (props) => {
  const onHide = () => {
    props.closeModalHandler();
  };
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <ModalContent
          isVisible={props.isVisible}
          onHide={onHide}
          data={props.modalData}
          selectedDate={props.selectedDate}
          employee={props.employee}
        />,
        document.getElementById("modal-root")
      )}
    </Fragment>
  );
};

export default CopyWorktimeModal;
