import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Backdrop,
  CircularProgress,
  Drawer,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  UilBars,
  UilSignOutAlt,
  UilTimesCircle,
  UilKeySkeletonAlt,
} from "@iconscout/react-unicons";
import {
  CommonMenu,
  InvoiceMenu,
  ProjectManagementMenu,
  SettingsMenu,
  TravelManagementMenu,
  UserManagementMenu,
} from "../../Data/Data";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import Logo from "../../imgs/logo.svg";
import Color from "../../enum/Color";
import { containsRoles, isNull } from "../../utils/Utils";
import BackdropContext from "../../store/backdrop-context";
import DropdownMenu from "../Common/DropdownMenu";
import { APP_VERSION } from "../../Constants/Constants";

const drawerWidth = 240;

const logo = {
  display: "flex",
  height: "5rem",
  gap: "1rem",
  alignItems: "center",
  justifyContent: "center",
};

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const Board = (props) => {
  const [user, setUser] = useState(JSON.parse(sessionStorage.getItem("user")));
  const [open, setOpen] = useState(true);
  const [pageName, setPageName] = useState("Calendar");
  const [userRoles, setUserRoles] = useState([]);

  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const backdropContext = useContext(BackdropContext);
  const location = useLocation();

  useEffect(() => {
    if (!isNull(user)) {
      setUserRoles(user.roles.map((role) => role.name));
    }
  }, [user]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logoutHandler = () => {
    authContext.logout();
    navigate("/login", { replace: true });
  };

  const changePasswordHandler = () => {
    setPageName("Change password");
    navigate("/password-reset", { replace: true });
  };

  const navigationHandler = (item) => {
    setPageName(item.heading);
    navigate(item.path);
  };

  const getPageName = () => {
    if (location.pathname.includes("/invoices/form")) {
      return "Invoice Form";
    } else if (location.pathname.includes("/invoices/preview")) {
      return "Invice Preview";
    } else {
      return pageName;
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const hasInvoiceManagementRoles = () => {
    return containsRoles(
      userRoles,
      [].concat(...InvoiceMenu.map((r) => r.roles))
    );
  };

  const hasUserManagementRoles = () => {
    return containsRoles(
      userRoles,
      [].concat(...UserManagementMenu.map((r) => r.roles))
    );
  };

  const hasProjectManagementRoles = () => {
    return containsRoles(
      userRoles,
      [].concat(...ProjectManagementMenu.map((r) => r.roles))
    );
  };

  const hasTravelManagementRoles = () => {
    return containsRoles(
      userRoles,
      [].concat(...TravelManagementMenu.map((r) => r.roles))
    );
  };

  const hasSettingsRoles = () => {
    return containsRoles(
      userRoles,
      [].concat(...SettingsMenu.map((r) => r.roles))
    );
  };

  const [isIMMenuOpen, setIsIMMenuOpen] = useState(false);
  const [isUMMenuOpen, setIsUMMenuOpen] = useState(false);
  const [isPMMenuOpen, setIsPMMenuOpen] = useState(false);
  const [isTMMenuOpen, setIsTMMenuOpen] = useState(false);
  const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useState(false);

  const closeAllMenus = () => {
    setIsIMMenuOpen(false);
    setIsUMMenuOpen(false);
    setIsPMMenuOpen(false);
    setIsSettingsMenuOpen(false);
    setIsTMMenuOpen(false);
  };

  const onIMMenuClick = () => {
    if (isIMMenuOpen) {
      closeAllMenus();
      setIsIMMenuOpen((prev) => !prev);
    }
    if (!isIMMenuOpen) {
      closeAllMenus();
    }
    setIsIMMenuOpen((prev) => !prev);
  };
  const onUMMenuClick = () => {
    if (isUMMenuOpen) {
      closeAllMenus();
      setIsUMMenuOpen((prev) => !prev);
    }
    if (!isUMMenuOpen) {
      closeAllMenus();
    }
    setIsUMMenuOpen((prev) => !prev);
  };

  const onPMMenuClick = () => {
    if (isPMMenuOpen) {
      closeAllMenus();
      setIsPMMenuOpen((prev) => !prev);
    }
    if (!isPMMenuOpen) {
      closeAllMenus();
    }
    setIsPMMenuOpen((prev) => !prev);
  };

  const onTMMenuClick = () => {
    if (isTMMenuOpen) {
      closeAllMenus();
      setIsTMMenuOpen((prev) => !prev);
    }
    if (!isTMMenuOpen) {
      closeAllMenus();
    }
    setIsTMMenuOpen((prev) => !prev);
  };

  const onSettingsMenuClick = () => {
    if (isSettingsMenuOpen) {
      closeAllMenus();
      setIsSettingsMenuOpen((prev) => !prev);
    }
    if (!isSettingsMenuOpen) {
      closeAllMenus();
    }
    setIsSettingsMenuOpen((prev) => !prev);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{ background: Color.BACKGROUND }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerOpen}
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <UilBars />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {getPageName()}
          </Typography>
          <React.Fragment>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Tooltip title="Account settings">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={openMenu ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMenu ? "true" : undefined}
                >
                  <Avatar sx={{ width: 40, height: 40 }}>
                    {user && user.firstName.charAt(0) + user.lastName.charAt(0)}
                  </Avatar>
                </IconButton>
              </Tooltip>
            </Box>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={openMenu}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              disableScrollLock={true}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "10px",
                }}
              >
                <Avatar sx={{ width: 56, height: 56 }} />
                <div>
                  <Typography variant={"body1"}>
                    {user && user.firstName + " " + user.lastName}
                  </Typography>
                  <Typography variant={"body2"}>
                    {user && user.email}
                  </Typography>
                </div>
              </div>
              <Divider />
              <MenuItem onClick={changePasswordHandler}>
                <ListItemIcon>
                  <UilKeySkeletonAlt style={{ width: "20px" }} />
                </ListItemIcon>
                <ListItemText
                  primary={"Change password"}
                  primaryTypographyProps={{ fontSize: "14px" }}
                />
              </MenuItem>
              <Divider style={{ marginTop: "16px" }} />
              <MenuItem onClick={logoutHandler}>
                <ListItemIcon>
                  <UilSignOutAlt style={{ width: "20px" }} />
                </ListItemIcon>
                <ListItemText
                  primary={"Logout"}
                  primaryTypographyProps={{ fontSize: "14px" }}
                />
              </MenuItem>
            </Menu>
          </React.Fragment>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        PaperProps={{
          sx: {
            backgroundColor: Color.ACCENT2,
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <div style={logo}>
            <img src={Logo} width="100%" alt="logo" />
          </div>
          <IconButton onClick={handleDrawerClose}>
            <UilTimesCircle />
          </IconButton>
        </DrawerHeader>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <div>
            <Divider style={{ margin: "8px" }} />
            <List>
              {CommonMenu.map((item, index) => {
                if (containsRoles(userRoles, item.roles)) {
                  return (
                    <ListItem
                      key={index}
                      disablePadding
                      onClick={() => navigationHandler(item)}
                      selected={location.pathname === item.path}
                    >
                      <ListItemButton>
                        <ListItemIcon>
                          <item.icon style={{ width: "20px" }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={item.heading}
                          primaryTypographyProps={{ fontSize: "14px" }}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                }
              })}
              
              {hasSettingsRoles() && (
                <DropdownMenu
                  text="Settings"
                  menu={SettingsMenu}
                  navigationHandler={navigationHandler}
                  pathname={location.pathname}
                  onClick={onSettingsMenuClick}
                  isOpen={isSettingsMenuOpen}
                />
              )}

              {hasInvoiceManagementRoles() && (
                <DropdownMenu
                  text="Invoice management"
                  menu={InvoiceMenu}
                  navigationHandler={navigationHandler}
                  pathname={location.pathname}
                  onClick={onIMMenuClick}
                  isOpen={isIMMenuOpen}
                />
              )}
              {hasUserManagementRoles() && (
                <DropdownMenu
                  text="User management"
                  menu={UserManagementMenu}
                  navigationHandler={navigationHandler}
                  pathname={location.pathname}
                  onClick={onUMMenuClick}
                  isOpen={isUMMenuOpen}
                />
              )}
              {hasProjectManagementRoles() && (
                <DropdownMenu
                  text="Project management"
                  menu={ProjectManagementMenu}
                  navigationHandler={navigationHandler}
                  pathname={location.pathname}
                  onClick={onPMMenuClick}
                  isOpen={isPMMenuOpen}
                />
              )}
              {hasTravelManagementRoles() && (
                <DropdownMenu
                  text="Travel management"
                  menu={TravelManagementMenu}
                  navigationHandler={navigationHandler}
                  pathname={location.pathname}
                  onClick={onTMMenuClick}
                  isOpen={isTMMenuOpen}
                />
              )}
            </List>
          </div>
          <Typography
            style={{
              textAlign: "center",
              margin: "8px",
            }}
            variant="caption"
          >
            {APP_VERSION}
          </Typography>
        </div>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => 999999 }}
            open={backdropContext.open}
            onClick={handleClose}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography sx={{ marginBottom: 1 }}>Loading...</Typography>
              <CircularProgress style={{ color: Color.BACKGROUND }} />
            </div>
          </Backdrop>
          {props.children}
        </div>
      </Main>
    </Box>
  );
};

export default Board;
