import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";

import {
  Box,
  Button,
  IconButton,
  TablePagination,
  TableSortLabel,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { UilTrashAlt, UilPen } from "@iconscout/react-unicons";
import Color from "../../enum/Color";
import {
  formatDate,
  isNull,
} from "../../utils/Utils";
import { SendDeleteRequest, SendPostRequest, SendPutRequest } from "../../actions/Actions";
import { useContext } from "react";
import AuthContext from "../../store/auth-context";
import { baseUrl } from "../../Config";
import ConfirmationModal from "../Modal/ConfirmationModal";
import {
  DELETE_RECORD_MESSAGE,
  DELETE_RECORD_TITLE,
} from "../../Constants/Constants";
import { useRef } from "react";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "",
  }
];

function EnhancedTableHead(props) {
  const { order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = () => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Holidays
      </Typography>
    </Toolbar>
  );
};

const HolidayTable = (props) => {
  const [showDialog, setShowDialog] = useState(false);
  const [date, setDate] = useState(null);
  const [name, setName] = useState(null);

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("id");
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const holidays = props.holidays;

  const authContext = useContext(AuthContext);
  const deleteId = useRef();
  const editId = useRef();

  const toggleConfirmationDialog = () => {
    setShowDialog((prev) => {
      return !prev;
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onDeleteClick = (row) => {
    deleteId.current = row.id;
    toggleConfirmationDialog();
  };

  const deleteHandler = () => {
    const url = baseUrl + "/api/holiday?id=" + deleteId.current;
    SendDeleteRequest(url, authContext.token, refreshDeleteHandler);
  };

  const onEditClick = (row) => {
    editId.current = row.id;
    setDate(prev=>{return row.date})
    setName(prev=>{return row.name})
  }

  const onAddClick = () => {
    const holiday = {
      id: editId.current,
      date: date,
      name: name
    };
    if(isNull(editId.current)) {
      saveHandler(holiday);
    } else {
      updateHandler(holiday);
    }

    setDate(null);
    setName(null);
  }

  const saveHandler = (hoiday) => {
    const url = baseUrl + "/api/holiday"
    SendPostRequest(url, hoiday, authContext.token, refreshHandler)
  }

  const updateHandler = (hoiday) => {
    const url = baseUrl + "/api/holiday"
    SendPutRequest(url, hoiday, authContext.token, refreshHandler)
  }

  const refreshDeleteHandler = () => {
    deleteId.current = null;
    editId.current = null;
    toggleConfirmationDialog();
    props.refreshHandler();
  };

  const refreshHandler = () => {
    deleteId.current = null;
    editId.current = null;
    props.refreshHandler();
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - holidays.length) : 0;

  return (
    <div className="Table">
      <ConfirmationModal
        isVisible={showDialog}
        confirmAction={deleteHandler}
        denieAction={toggleConfirmationDialog}
        title={DELETE_RECORD_TITLE}
        message={DELETE_RECORD_MESSAGE}
      />

      <Paper sx={{ marginBottom: 2 }}>
        <Typography sx={{ m: 1 }}>Add holiday:</Typography>
        <TextField
          sx={{ m: 1, minWidth: 200 }}
          id="outlined-required"
          label="Date"
          type="date"
          value={date || ""}
          onChange={(e) => setDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          sx={{ m: 1, minWidth: 200 }}
          id="outlined-required"
          label="Name"
          value={name || ""}
          onChange={(e) => setName(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button
          variant="contained"
          sx={{
            height: "56px",
            marginTop: "8px",
            backgroundColor: Color.BACKGROUND,
          }}
          onClick={onAddClick}
        >
          ADD
        </Button>
      </Paper>

      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }} elevation={5}>
          <EnhancedTableToolbar />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={holidays.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(holidays, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow hover tabIndex={-1} key={row.id}>
                        <TableCell align="left">
                          {!isNull(row.date) && formatDate(row.date)}
                        </TableCell>
                        <TableCell align="left">
                          {!isNull(row.name) && row.name}
                        </TableCell>
                        <TableCell align={"left"} className="Details">
                        <Tooltip title="Edit holiday">
                            <IconButton onClick={() => onEditClick(row)}>
                              <UilPen style={{ color: Color.BACKGROUND }} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete holiday">
                            <IconButton onClick={() => onDeleteClick(row)}>
                              <UilTrashAlt style={{ color: Color.DANGER }} />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={holidays.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </div>
  );
};

export default HolidayTable;
