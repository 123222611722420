import ReactDOM from "react-dom";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import "./Modal.css";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import CurrencyEnum from "../../enum/CurrencyEnum";
import AuthContext from "../../store/auth-context";
import BackdropContext from "../../store/backdrop-context";
import { SendPostRequest, SendPutRequest } from "../../actions/Actions";
import { isNull } from "../../utils/Utils";
import { baseUrl } from "../../Config";
import ProjectTypeEnum from "../../enum/ProjectTypeEnum";
import ProjectManagementStatusEnum from "../../enum/ProjectManagementStatusEnum";

const ModalContent = (props) => {
  const currencies = Object.keys(CurrencyEnum);
  const projectTypes = Object.keys(ProjectTypeEnum);
  const pmStatuses = Object.keys(ProjectManagementStatusEnum);

  const data = props.data;
  const customers = props.customers;

  const [loading, setLoading] = useState(true);
  const [errorDetails, setErrorDetails] = useState({
    showError: false,
    errorCode: "",
    errorMessage: "",
  });

  const authContext = useContext(AuthContext);
  const backdropContext = useContext(BackdropContext);

  const projectNameInputRef = useRef();

  const [customer, setCustomer] = useState(null);
  const [projectCurrency, setCurrency] = useState();
  const [projectType, setType] = useState();
  const [projectStatus, setStatus] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    if (!isNull(data)) {
      setStartDate(data.startDate);
      setEndDate(data.endDate);
      setCurrency(data.currency);
      setType(data.projectType);
      setStatus(data.status);
      setCustomer(data.customer);
    }
  }, [data]);

  const handleCurrencySelect = (event) => {
    setCurrency(event.target.value);
  };

  const handleProjectTypeSelect = (event) => {
    setType(event.target.value);
  };
  const handlePmStatusSelect = (event) => {
    setStatus(event.target.value);
  };

  const handleClientSelect = (event) => {
    const client = customers.find((c) => c.id === event.target.value);
    setCustomer(client);
  };

  const hadleOnSaveClick = () => {
    const project = {
      name: projectNameInputRef.current.value,
      customer: customer,
      startDate: startDate,
      endDate: endDate,
      currency: projectCurrency,
      projectType: projectType,
      status: projectStatus
    };
    saveHandler(project);
    backdropContext.toggle();
  };

  const hadleOnUpdateClick = () => {
    const project = {
      id: data.id,
      name: projectNameInputRef.current.value,
      customer: customer,
      startDate: startDate,
      endDate: endDate,
      currency: projectCurrency,
      projectType: projectType,
      status: projectStatus
    };
    updateHandler(project);
    backdropContext.toggle();
  };

  const saveHandler = async (client) => {
    const url = baseUrl + "/api/project";
    SendPostRequest(url, client, authContext.token, projectsHandler);
  };

  const updateHandler = async (client) => {
    const url = baseUrl + "/api/project";
    SendPutRequest(url, client, authContext.token, projectsHandler);
  };

  const projectsHandler = () => {
    backdropContext.toggle();
    props.onHide();
  }
  return (
    <div>
      <Dialog open={props.isVisible} maxWidth="lg">
        <DialogTitle>
          {isNull(data) ? "New Project" : data.name}
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <TextField
                disabled={props.isReadOnly}
                id="outlined-required"
                label="Project name"
                inputRef={projectNameInputRef}
                defaultValue={isNull(data) ? "" : data.name}
              />
            </div>
            <div>
              <div>
                <FormControl sx={{ m: 1, minWidth: 500 }}>
                  <InputLabel id="demo-simple-select-label">
                    Customer
                  </InputLabel>
                  <Select
                    disabled={props.isReadOnly}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Language"
                    onChange={handleClientSelect}
                    value={isNull(customer) ? "" : customer.id}
                  >
                    {customers &&
                      customers.map((data) => {
                        return (
                          <MenuItem value={data.id}>
                            {data.companyDisplayName}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </div>

              <div>
                <TextField
                  sx={{ m: 1, minWidth: 200 }}
                  disabled={props.isReadOnly}
                  id="date"
                  label="Start Date"
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  sx={{ m: 1, minWidth: 200 }}
                  disabled={props.isReadOnly}
                  id="date"
                  label="End Date"
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              
              <div>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-label">
                    Currency
                  </InputLabel>
                  <Select
                    disabled={props.isReadOnly}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Currency"
                    onChange={handleCurrencySelect}
                    defaultValue={isNull(data) ? "" : data.currency}
                  >
                    {currencies.map((key) => {
                      return (
                        <MenuItem value={key}>{CurrencyEnum[key]}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 200 }}>
                  <InputLabel id="demo-simple-select-label">
                    Project Type
                  </InputLabel>
                  <Select
                    disabled={props.isReadOnly}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Project Type"
                    onChange={handleProjectTypeSelect}
                    defaultValue={isNull(data) ? "" : data.projectType}
                  >
                    {projectTypes.map((key) => {
                      return (
                        <MenuItem value={key}>{ProjectTypeEnum[key]}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-label">
                    Status
                  </InputLabel>
                  <Select
                    disabled={props.isReadOnly}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Status"
                    onChange={handlePmStatusSelect}
                    defaultValue={isNull(data) ? "" : data.status}
                  >
                    {pmStatuses.map((key) => {
                      return (
                        <MenuItem value={key}>{ProjectManagementStatusEnum[key]}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            className="cancelButton"
            variant="contained"
            onClick={props.onHide}
          >
            {props.isReadOnly ? "Close" : "Cancel"}
          </Button>
          <Button
            disabled={props.isReadOnly}
            variant="contained"
            onClick={data && data.id ? hadleOnUpdateClick : hadleOnSaveClick}
          >
            {data && data.id ? "Update" : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const ProjectModal = (props) => {
  const onHide = () => {
    props.closeModalHandler();
  };

  return (
    <Fragment>
      {ReactDOM.createPortal(
        <ModalContent
          isVisible={props.isVisible}
          onHide={onHide}
          data={props.modalData}
          customers={props.customers}
          isReadOnly={props.isReadOnly}
        />,
        document.getElementById("modal-root")
      )}
    </Fragment>
  );
};

export default ProjectModal;
