import ReactDOM from "react-dom";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
} from "@mui/material";
import "./Modal.css";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../../store/auth-context";
import { SendPostRequest, SendPutRequest } from "../../actions/Actions";
import { isNull } from "../../utils/Utils";
import { baseUrl } from "../../Config";
import BackdropContext from "../../store/backdrop-context";

const ModalContent = (props) => {
  let data = props.data;
  const user = props.user;

  const authContext = useContext(AuthContext);
  const backdropContext = useContext(BackdropContext);

  const [make, setMake] = useState("");
  const [model, setModel] = useState("null");
  const [registration, setRegistration] = useState("");
  const [active, setActive] = useState(true);
  const [ownerId, setOwnerId] = useState(props.user.id);
  const [errors, setErrors] = useState({
    make: true,
    model: true,
    registration: true,
    active: false,
  });
  const [formValid, setFormValid] = useState(false);

  const clearStates = () => {
    setMake("");
    setModel("");
    setRegistration("");
    setActive(true);
 
    setErrors({
      make: true,
      model: true,
      registration: true,
      active: false,
    });
  };

  useEffect(() => {
    if (!isNull(data)) {
      setMake(data.make);
      setModel(data.model);
      setRegistration(data.registration);
      setActive(data.active)
      setErrors({});
    } else {
      clearStates();
    }
  }, [data]);


  const hadleOnSaveClick = () => {
    backdropContext.toggle();
    const vehicle = {
      ownerId: user.id,
      make: make,
      model: model,
      registration: registration,
      active: true,
    };
    saveHandler(vehicle);
  };

  const hadleOnUpdateClick = () => {
    backdropContext.toggle();
    const vehicle = {
      id: data.id,
      ownerId: user.id,
      make: make,
      model: model,
      registration: registration,
      active: active,
    };
    updateHandler(vehicle);
  };

  const saveOrUpdateCallback = () => {
    clearStates();
    backdropContext.toggle();
    props.onHide();
  };

  const saveHandler = async (vehicle) => {
    const url = baseUrl + "/api/vehicle";
    SendPostRequest(url, vehicle, authContext.token, saveOrUpdateCallback);
  };

  const updateHandler = async (vehicle) => {
    const url = baseUrl + "/api/vehicle";
    SendPutRequest(url, vehicle, authContext.token, saveOrUpdateCallback);
  };

  const closeHandler = () => {
    clearStates();
    data = null;
    props.onHide();
  };

  const blurHandler = (event) => {
    setErrors((prev) => ({
      ...errors,
      [event.target.name]: !event.target.value,
    }));
  };

  useEffect(() => {
    validateForm();
  }, [errors]);

  const handleToogle = (event) => {
    setActive(event.target.checked)
  }

  const validateForm = () => {
    const bools = Object.values(errors);
    setFormValid((prev) => {
      return !bools.includes(true);
    });
  };

  return (
    <div>
      <Dialog open={props.isVisible} maxWidth="lg">
        <DialogTitle>{isNull(data) ? "New Vehicle" : "Edit Vehicle"}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <div>
                <TextField
                  required
                  error={errors.make}
                  sx={{ m: 1, minWidth: 200 }}
                  id="outlined-required"
                  label="Make"
                  type="text"
                  name="make"
                  value={make || ""}
                  onChange={(e) => setMake(e.target.value)}
                  onBlur={blurHandler}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  required
                  error={errors.model}
                  sx={{ m: 1, minWidth: 200 }}
                  id="outlined-required"
                  label="Model"
                  type="text"
                  name="model"
                  value={model || ""}
                  onChange={(e) => setModel(e.target.value)}
                  onBlur={blurHandler}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div>
                <TextField
                  required
                  error={errors.registration}
                  sx={{ m: 1, minWidth: 200 }}
                  id="outlined-required"
                  label="Registration"
                  type="text"
                  name="registration"
                  value={registration || ""}
                  onChange={(e) => setRegistration(e.target.value)}
                  onBlur={blurHandler}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Switch
                   color="success"
                   checked={active}
                   onChange={handleToogle}
                />
              </div>
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            className="cancelButton"
            variant="contained"
            onClick={closeHandler}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={!formValid}
            onClick={data && data.id ? hadleOnUpdateClick : hadleOnSaveClick}
            type="submit"
          >
            {data && data.id ? "Update" : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const VehicleModal = (props) => {
  const onHide = () => {
    props.closeModalHandler();
  };
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <ModalContent
          isVisible={props.isVisible}
          onHide={onHide}
          data={props.modalData}
          user={props.user}
        />,
        document.getElementById("modal-root")
      )}
    </Fragment>
  );
};

export default VehicleModal;
