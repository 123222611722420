import { useState, useEffect } from "react";

const useApiRequest = (url, method, token) => {
  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState({
    showError: false,
    errorCode: "",
    errorMessage: "",
  });

  useEffect( () => {
    async function fetchData() {
        try {
      const response = await fetch(url, {
        method: method,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*",
            "Authorization": token,
          },
      });
      if (!response.ok) {
        setError({
          showError: true,
          errorCode: response.status,
          errorMessage: response.statusText,
        });
        throw new Error(response.statusText)
      }

      const parsedData = await response.json();

      setData(parsedData);
      setIsLoaded(true);
    } catch (error) {
      if(error.showError) {
        setError({
          showError: true,
          errorMessage: error.message
        });
      }
    }
    }

    fetchData();
  }, [url, method, token]);

  return { error, isLoaded, data };
};

export default useApiRequest;
