import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
} from "@mui/material";
import Color from "../../enum/Color";
import { formatDate, formatDateTime, isNull, roundToTwo } from "../../utils/Utils";

import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useParams } from "react-router-dom";
import AuthContext from "../../store/auth-context";
import { baseUrl } from "../../Config";
import { SendGetRequest } from "../../actions/Actions";

const TravelWarrantPreview = (props) => {
  const companyInfo = JSON.parse(sessionStorage.getItem("companyInfo"));
  const params = useParams();

  const [travelWarrant, setTravelWarrant] = useState(null);
  const [docTitle, setDocTitle] = useState("Putni nalog")

  const authContext = useContext(AuthContext);

  const travelWarrantUrl = baseUrl + "/api/travel-warrant?id=" + params.id;

    const travelWarrantCallback = (data) => {
      setTravelWarrant((prev) => {
        return data;
      });
      setDocTitle("Putni nalog " + data.name + " - " + data.owner + " - " + data.location)
    };
  
    useEffect(() => {
      SendGetRequest(travelWarrantUrl, authContext.token, travelWarrantCallback);
    }, []);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: docTitle
    
  });

  const getRowColor = (item, index) => {
    if(!isNull(item) && (item.holiday || item.absentTime)) {
      return Color.HOLIDAY
    } else {
      return index%2!==0 ? Color.ACCENT1 : "white"
    }
  }
  
  return (
    <div
      className="ReportPage"
      style={{ backgroundColor: Color.ACCENT1, paddingBottom: "8px" }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Toolbar
          style={{
            display: "flex",
            gap: "1rem",
            justifyContent: "end",
            backgroundColor: Color.ACCENT2,
          }}
        >
          <Button
            variant="contained"
            style={{ backgroundColor: Color.BACKGROUND }}
            onClick={handlePrint}
          >
            Download report
          </Button>
        </Toolbar>

        <div className="invoicePage" ref={componentRef}>
          <div
            className="details-container clearfix invoicePage"
            style={{ margiLeft: "auto", margiRight: "auto" }}
          >
            <div className="flex">
              <div>
                <div className="pcs-template-body">
                <div style={{marginTop: "2rem"}}>
                    <div>
                      <span>Naziv tvrtke: </span>{" "}
                      <span>{companyInfo.companyName}</span>
                    </div>
                    <div>
                      <span>Mjesto i adresa: {companyInfo.address}, {companyInfo.zipCode} {companyInfo.city}</span>
                    </div>
                    <div>
                      <span>OIB: {companyInfo.businessId}</span>
                    </div>
                  </div>

                  <h3 style={{marginTop: "2rem"}}> Putni nalog {!isNull(travelWarrant) && travelWarrant.name}</h3>
                  <div style={{width: "100%", height: "3px", backgroundColor: Color.BACKGROUND, marginTop: "2rem"}}></div>
                  <div style={{marginTop: "2rem"}}>
                    <div>
                      Ime i prezime: <b>{!isNull(travelWarrant) && travelWarrant.owner}</b>
                    </div>
                    <div style={{marginTop: "2rem", display: "flex", justifyContent:"space-between"}}>
                      <div style={{width:"50%"}}>
                        Registarska oznaka automobila: <b>{!isNull(travelWarrant) && travelWarrant.vehicle.registration}</b>
                      </div>
                      <div style={{width:"40%"}}>
                        Marka automobila: <b>{!isNull(travelWarrant) && travelWarrant.vehicle.make + " " + travelWarrant.vehicle.model}</b>
                      </div>
                    </div>
                    <div style={{marginTop: "2rem", display: "flex", justifyContent:"space-between"}}>
                      <div style={{width:"40%"}}>
                        Svrha putovanja: <b>{!isNull(travelWarrant) && travelWarrant.reason}</b>
                      </div>
                      <div style={{width:"40%"}}>
                        Lokacija: <b>{!isNull(travelWarrant) && travelWarrant.location}</b>
                      </div>
                    </div>
                    <div style={{marginTop: "2rem", display: "flex", justifyContent:"space-between"}}>
                      <div style={{width:"40%"}}>
                        Datum i vrijeme polaska: <br/> <b>{!isNull(travelWarrant) && formatDateTime(travelWarrant.departure)}</b>
                      </div>
                      <div style={{width:"40%"}}>
                      Datum i vrijeme dolaska: <br/> <b>{!isNull(travelWarrant) && formatDateTime(travelWarrant.arrival)}</b>
                      </div>
                    </div>
                    <div style={{marginTop: "2rem", display: "flex", justifyContent:"space-between"}}>
                      <div style={{width:"40%"}}>
                        Prijeđena kilometraža: <b>{!isNull(travelWarrant) && travelWarrant.distance + " km"}</b>
                      </div>
                      <div style={{width:"40%"}}>
                        Način putovanja: <b>{!isNull(travelWarrant) && travelWarrant.travelType}</b>
                      </div>
                    </div>
                    <div style={{marginTop: "2rem"}}> 
                      Izvještaj: <b>{!isNull(travelWarrant) && travelWarrant.report}</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TravelWarrantPreview;
