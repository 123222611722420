export const SendPostRequest = async (url, requestBody, token, callback) => {
    let data;
    let error;
    let loading = true;
    try {
        const response = await fetch(url, {
          method: "POST",
          body: JSON.stringify(requestBody),
          headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              "Authorization": token,
            },
        });
        if (!response.ok) {
          error = {
            showError: true,
            errorCode: response.status,
            errorMessage: response.statusText,
          };
          throw new Error(response.statusText)
        }
  
        const parsedData = await response.json();

        data = parsedData;
        loading = false;
      } catch (error) {
        if(error.showError) {
          error = {
            showError: true,
            errorMessage: error.message
          };
        }
      }
      if(typeof(callback) === "function") {
        callback(data);
      }
    return {data, error, loading};
}

export const SendPutRequest = async (url, requestBody, token, callback) => {
  let data;
  let error;
  let loading = true;
  try {
      const response = await fetch(url, {
        method: "PUT",
        body: JSON.stringify(requestBody),
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": token,
          },
      });
      if (!response.ok) {
        error = {
          showError: true,
          errorCode: response.status,
          errorMessage: response.statusText,
        };
        throw new Error(response.statusText)
      }

      const parsedData = await response.json();

      data = parsedData;
      loading = false;
    } catch (error) {
      if(error.showError) {
        error = {
          showError: true,
          errorMessage: error.message
        };
      }
    }
    if(typeof(callback) === "function") {
      callback(data);
    }
  return {data, error, loading};
}

export const SendDeleteRequest = async (url, token, callback) => {
  let data;
  let error;
  let loading = true;
  try {
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": token,
          },
      });
      if (!response.ok) {
        error = {
          showError: true,
          errorCode: response.status,
          errorMessage: response.statusText,
        };
        throw new Error(response.statusText)
      }

      const parsedData = await response.json();

      data = parsedData;
      loading = false;
    } catch (error) {
      if(error.showError) {
        error = {
          showError: true,
          errorMessage: error.message
        };
      }
    }
    if(typeof(callback) === "function") {
      callback(data);
    }
  return {data, error, loading};
}

export const SendGetRequest = async (url, token, callback) => {
  let data;
  let error;
  let loading = true;
  try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": token,
          },
      });
      if (!response.ok) {
        error = {
          showError: true,
          errorCode: response.status,
          errorMessage: response.statusText,
        };
        throw new Error(response.statusText)
      }

      const parsedData = await response.json();

      data = parsedData;
      loading = false;
    } catch (error) {
      if(error.showError) {
        error = {
          showError: true,
          errorMessage: error.message
        };
      }
    }
    callback(data);
  return {data, error, loading};
}