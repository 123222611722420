import ReactDOM from "react-dom";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import "./Modal.css";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../../store/auth-context";
import { SendPostRequest, SendPutRequest } from "../../actions/Actions";
import { isNull } from "../../utils/Utils";
import { baseUrl } from "../../Config";
import BackdropContext from "../../store/backdrop-context";

const ModalContent = (props) => {
  let data = props.data;
  const engagements = props.engagements;
  const projects = [].concat(...engagements.map((eng) => eng.project));

  const authContext = useContext(AuthContext);
  const backdropContext = useContext(BackdropContext);

  const [name, setName] = useState("");
  const [project, setProject] = useState(null);
  const [employee, setEmployee] = useState(props.employee);
  const [errors, setErrors] = useState({
    name: true,
    project: true,
  });
  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    setEmployee(props.employee);
  }, [props.employee]);

  const clearStates = () => {
    setName("");
    setProject(null);
    setErrors({
      name: true,
      project: true,
    });
  };

  useEffect(() => {
    if (!isNull(data)) {
      setName(data.name);
      setProject(data.project);
      setEmployee(data.employee);
      setErrors({});
    } else {
      clearStates();
    }
  }, [data]);

  const handleProjectSelect = (event) => {
    const project = projects.find((c) => c.id === event.target.value);
    setProject(project);
    blurHandler(event);
  };

  const hadleOnSaveClick = () => {
    backdropContext.toggle();
    const task = {
      userId: employee.id,
      name: name,
      project: project,
      active: true,
    };
    saveHandler(task);
  };

  const hadleOnUpdateClick = () => {
    backdropContext.toggle();
    const task = {
      id: data.id,
      userId: data.userId,
      name: name,
      project: project,
      active: data.active,
    };
    updateHandler(task);
  };

  const saveOrUpdateCallback = () => {
    clearStates();
    backdropContext.toggle();
    props.onHide();
  };

  const saveHandler = async (task) => {
    const url = baseUrl + "/api/task";
    SendPostRequest(url, task, authContext.token, saveOrUpdateCallback);
  };

  const updateHandler = async (task) => {
    const url = baseUrl + "/api/task";
    SendPutRequest(url, task, authContext.token, saveOrUpdateCallback);
  };

  const closeHandler = () => {
    clearStates();
    data = null;
    props.onHide();
  };

  const blurHandler = (event) => {
    setErrors((prev) => ({
      ...errors,
      [event.target.name]: !event.target.value,
    }));
  };

  useEffect(() => {
    validateForm();
  }, [errors]);

  const validateForm = () => {
    const bools = Object.values(errors);
    setFormValid((prev) => {
      return !bools.includes(true);
    });
  };

  return (
    <div>
      <Dialog open={props.isVisible} maxWidth="lg">
        <DialogTitle>{isNull(data) ? "New Task" : "Edit Task"}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <div>
                <TextField
                  required
                  error={errors.name}
                  sx={{ m: 1, minWidth: 200 }}
                  id="outlined-required"
                  label="Name"
                  type="text"
                  name="name"
                  value={name || ""}
                  onChange={(e) => setName(e.target.value)}
                  onBlur={blurHandler}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div>
                <FormControl
                  sx={{ m: 1, minWidth: "50%" }}
                  required
                  error={errors.project}
                >
                  <InputLabel id="demo-simple-select-label">Project</InputLabel>
                  <Select
                    required
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Project"
                    name="project"
                    onChange={handleProjectSelect}
                    value={isNull(project) ? "" : project.id}
                  >
                    {projects &&
                      projects.map((data) => {
                        return <MenuItem value={data.id}>{data.name}</MenuItem>;
                      })}
                  </Select>
                </FormControl>
              </div>
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            className="cancelButton"
            variant="contained"
            onClick={closeHandler}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={!formValid}
            onClick={data && data.id ? hadleOnUpdateClick : hadleOnSaveClick}
            type="submit"
          >
            {data && data.id ? "Update" : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const TaskModal = (props) => {
  const onHide = () => {
    props.closeModalHandler();
  };
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <ModalContent
          isVisible={props.isVisible}
          onHide={onHide}
          data={props.modalData}
          engagements={props.engagements}
          employee={props.employee}
        />,
        document.getElementById("modal-root")
      )}
    </Fragment>
  );
};

export default TaskModal;
