import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../../store/auth-context";
import useApiRequest from "../../store/use-http";
import { baseUrl } from "../../Config";
import "./UsersPage.css";
import UsersTable from "../Table/UsersTable";
import { SendGetRequest } from "../../actions/Actions";

const UsersPage = () => {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState("");
  const [errorDetails, setErrorDetails] = useState({
    showError: false,
    errorCode: "",
    errorMessage: "",
  });

  const authContext = useContext(AuthContext);

  const usersUrl = baseUrl + "/api/users";
  const rolesUrl = baseUrl + "/api/roles";

  useEffect(() => {
    SendGetRequest(usersUrl, authContext.token, usersHandler);
    SendGetRequest(rolesUrl, authContext.token, rolesHandler)
  }, [])

  const usersHandler = (data) => {
    setUsers(data);
  }

  const rolesHandler = (data) => {
    setRoles(data);
  }

  const refreshHandler = () => {
    SendGetRequest(usersUrl, authContext.token, usersHandler);
    SendGetRequest(rolesUrl, authContext.token, rolesHandler)
  }

  return (
    <div className="UsersPage">
      <UsersTable data={users} roles={roles} refreshHandler={refreshHandler} />
    </div>
  );
};

export default UsersPage;
