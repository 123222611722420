import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../../store/auth-context";
import { baseUrl } from "../../Config";
import "./SettingsPage.css";
import { SendGetRequest } from "../../actions/Actions";
import HolidayTable from "../Table/HolidayTable";

const SettingsPage = () => {
  const [holidays, setHolidays] = useState([]);

  const authContext = useContext(AuthContext);

  /*Fetch engagements*/
  const holidayUrl = baseUrl + "/api/holiday/all/currentYear";

  const holidaysCallback = (data) => {
    setHolidays(data);
  };

  useEffect(() => {
    SendGetRequest(holidayUrl, authContext.token, holidaysCallback);
  }, []);

  /***********/
  const refreshHandler = () => {
    SendGetRequest(holidayUrl, authContext.token, holidaysCallback);
  }

  return (
    <div className="SettingsPage">
      <HolidayTable holidays={holidays} refreshHandler={refreshHandler} />
    </div>
  );
};

export default SettingsPage;
