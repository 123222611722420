import React from "react";
import {

  Navigate, Outlet
} from "react-router-dom";

import { containsRoles } from "../../utils/Utils";


function ProtectedRoute(props) {

    const user = JSON.parse(sessionStorage.getItem("user"));

    const userRoutes = user.roles.map(role => role.name)

  return containsRoles(userRoutes, props.allowedRoles) ? <Outlet/> : <Navigate to={"/403"} replace={true}/>
    
}

export default ProtectedRoute;