import {
  Box, Button,
  Dialog, DialogActions, DialogContent, DialogTitle, FormControl,
  InputLabel, MenuItem, Select, TextField
} from "@mui/material";
import { Fragment, useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import {
  SendPostRequest,
  SendPutRequest
} from "../../actions/Actions";
import { baseUrl } from "../../Config";
import AbsentTimeTypeEnum from "../../enum/AbsentTimeTypeEnum";
import AuthContext from "../../store/auth-context";
import BackdropContext from "../../store/backdrop-context";
import { isNull } from "../../utils/Utils";
import "./Modal.css";

const ModalContent = (props) => {
  let data = props.data;

  const authContext = useContext(AuthContext);
  const backdropContext = useContext(BackdropContext);

  const [startDate, setStartDate] = useState(props.selectedDate);
  const [endDate, setEndDate] = useState(null);
  const [employeeId, setEmployeeId] = useState(props.employee.id);
  const [absentTimeType, setAbsentTimeType] = useState(null);
  const [note, setNote] = useState(null);
  const [errors, setErrors] = useState({
    startDate: false,
    endDate: true,
    type: true,
  });
  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    setStartDate(props.selectedDate);
    setEmployeeId(props.employee.id);
  }, [props.selectedDate, props.employee]);

  const clearStates = () => {
    setStartDate(props.selectedDate);
    setEndDate(null);
    setAbsentTimeType(null);
    setNote(null);
    setErrors({
      startDate: false,
      endDate: true,
      type: true,
    });
  };

  useEffect(() => {
    if (!isNull(data)) {
      setStartDate(data.startDate);
      setEndDate(data.endDate);
      setAbsentTimeType(data.type);
      setEmployeeId(data.employeeId);
      setNote(data.note);
      setErrors({});
    } else {
      clearStates();
    }
  }, [data]);

  const handleTypeSelect = (event) => {
    setAbsentTimeType(event.target.value);
    blurHandler(event);
  };

  const hadleOnSaveClick = () => {
    backdropContext.toggle();
    const absentTime = {
      employeeId: employeeId,
      type: absentTimeType,
      startDate: startDate,
      endDate: endDate,
      note: note,
    };
    console.log(absentTime);
    saveHandler(absentTime);
  };

  const hadleOnUpdateClick = () => {
    backdropContext.toggle();
    const absentTime = {
      id: data.id,
      employeeId: employeeId,
      type: absentTimeType,
      startDate: startDate,
      endDate: endDate,
      note: note,
    };
    updateHandler(absentTime);
  };

  const saveOrUpdateCallback = () => {
    clearStates();
    backdropContext.toggle();
    props.onHide();
  };

  const saveHandler = async (worktime) => {
    const url = baseUrl + "/api/absenttime";
    SendPostRequest(url, worktime, authContext.token, saveOrUpdateCallback);
  };

  const updateHandler = async (worktime) => {
    const url = baseUrl + "/api/absenttime";
    SendPutRequest(url, worktime, authContext.token, saveOrUpdateCallback);
  };

  const closeHandler = () => {
    clearStates();
    data = null;
    props.onHide();
  };

  const blurHandler = (event) => {
    setErrors((prev) => ({
      ...errors,
      [event.target.name]: !event.target.value,
    }));
  };

  useEffect(() => {
    validateForm();
  }, [errors]);

  const validateForm = () => {
    const bools = Object.values(errors);
    setFormValid((prev) => {
      return !bools.includes(true);
    });
  };

  return (
    <div>
      <Dialog open={props.isVisible} maxWidth="lg">
        <DialogTitle>
          {isNull(data) ? "New Absent Time" : "Edit Absent Time"}
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <div>
                <FormControl
                  sx={{ m: 1, minWidth: 120 }}
                  required
                  error={errors.type}
                >
                  <InputLabel id="demo-simple-select-label">Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="worktimeType"
                    name="type"
                    onChange={handleTypeSelect}
                    value={isNull(absentTimeType) ? "" : absentTimeType}
                  >
                    {Object.keys(AbsentTimeTypeEnum).map((key) => {
                      return (
                        <MenuItem value={key}>{AbsentTimeTypeEnum[key]}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <TextField
                  required
                  error={errors.startDate}
                  sx={{ m: 1, minWidth: 200 }}
                  id="outlined-required"
                  label="Start Date"
                  type="date"
                  name="startDate"
                  value={startDate || ""}
                  onChange={(e) => setStartDate(e.target.value)}
                  onBlur={blurHandler}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  required
                  error={errors.endDate}
                  sx={{ m: 1, minWidth: 200 }}
                  id="outlined-required"
                  label="End Date"
                  type="date"
                  name="endDate"
                  value={endDate || ""}
                  onChange={(e) => setEndDate(e.target.value)}
                  onBlur={blurHandler}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

              <div>
                <TextField
                  sx={{ m: 1, minWidth: 200 }}
                  id="outlined-required"
                  label="Note (optional)"
                  type="text"
                  value={note || ""}
                  onChange={(e) => setNote(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              </div>
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            className="cancelButton"
            variant="contained"
            onClick={closeHandler}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={!formValid}
            onClick={data && data.id ? hadleOnUpdateClick : hadleOnSaveClick}
            type="submit"
          >
            {data && data.id ? "Update" : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const AbsentTimeModal = (props) => {
  const onHide = () => {
    props.closeModalHandler();
  };
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <ModalContent
          isVisible={props.isVisible}
          onHide={onHide}
          data={props.modalData}
          selectedDate={props.selectedDate}
          employee={props.employee}
        />,
        document.getElementById("modal-root")
      )}
    </Fragment>
  );
};

export default AbsentTimeModal;
