import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./components/Login/Login";
import { AuthContextProvider } from "./store/auth-context";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import Board from "./components/Board/Board";
import MainDash from "./components/MainDash/MainDash";
import CompanyInfoForm from "./components/CompanyInfo/CompanyInfoForm";
import CustomersPage from "./components/Customers/CustomerPage";
import TaxesPage from "./components/Tax/TaxPage";
import ItemsPage from "./components/Items/ItemsPage";
import InvoicesPage from "./components/Invoice/InvoicesPage";
import InvoiceForm from "./components/Invoice/InvoiceForm";
import InvoicePreview from "./components/Invoice/InvoicePreview";
import Error403 from "./components/ErrorPages/Error403";
import AuthRoute from "./components/ProtectedRoute/AuthRoute";
import UsersPage from "./components/Users/UsersPage";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import ProjectsPage from "./components/Project/ProjectsPage";
import EngagementsPage from "./components/Engagement/EngagementsPage";
import WorktimePage from "./components/Worktime/WorktimePage";
import CalendarPage from "./components/Calendar/CalendarPage";
import { BackdropContextProvider } from "./store/backdrop-context";
import ReportPage from "./components/Report/ReportPage";
import SettingsPage from "./components/Settings/SettingsPage";
import TasksPage from "./components/Tasks/TasksPage";
import AbsentTimePage from "./components/AbsentTime/AbsentTimePage";
import VehiclesPage from "./components/Vehicles/VehiclesPage";
import TravelWarrantsPage from "./components/TravelWarrants/TravelWarrantsPage";
import TravelWarrantPreview from "./components/TravelWarrants/TravelWarrantPreview";
import LocalTravelSettingsPage from "./components/LocalTravel/LocalTravelSettingsPage";
import LocalTravelPage from "./components/LocalTravel/LocalTravelPage";
import SubscriptionsPage from "./components/Subscriptions/SubscriptionsPage";

function App() {
  return (
    <AuthContextProvider>
      <BackdropContextProvider>
        <Router>
          <div>
            <Routes>
              {/*public routes*/}
              <Route path="/login" element={<Login />} />
              <Route path="/403" element={<Error403 />} />
              <Route element={<AuthRoute />}>
                <Route
                  element={
                    <Board>
                      <ProtectedRoute
                        allowedRoles={["ROLE_USER", "ROLE_ADMIN"]}
                      />
                    </Board>
                  }
                >
                  {/*basic user routes*/}
                  <Route path="/calendar" element={<CalendarPage />} />
                  <Route path="/password-reset" element={<ResetPassword />} />
                  <Route path="/worktime" element={<WorktimePage />} />
                  <Route path="/absenttime" element={<AbsentTimePage />} />
                  <Route path="/tasks" element={<TasksPage />} />
                  <Route path="/vehicles" element={<VehiclesPage />} />
                  <Route path="/travel-warrants" element={<TravelWarrantsPage />} />
                  <Route path="/travel-warrants/preview/:id" element={<TravelWarrantPreview />}/>
                  <Route path="/local-travel" element={<LocalTravelPage />} />
                  <Route path="*" element={<Navigate replace to="/calendar" />}
                  />
                </Route>
                <Route
                  element={
                    <Board>
                      <ProtectedRoute
                        allowedRoles={["ROLE_PM_USER", "ROLE_ADMIN"]}
                      />
                    </Board>
                  }
                >
                  {/*PM user routes*/}
                  <Route path="/projects" element={<ProjectsPage />} />
                  <Route path="/engagements" element={<EngagementsPage />} />
                  <Route path="/reports" element={<ReportPage />} />
                </Route>

                <Route
                  element={
                    <Board>
                      <ProtectedRoute
                        allowedRoles={["ROLE_ADMIN", "ROLE_INVOICE_USER"]}
                      />
                    </Board>
                  }
                >
                   {/*RI user routes*/}
                  <Route path="/settings" element={<SettingsPage />} />
                  <Route path="/local-travel-settings" element={<LocalTravelSettingsPage />} />
                  <Route path="/info" element={<CompanyInfoForm />} />
                  <Route path="/analytics" element={<MainDash />} />
                  <Route path="/customers" element={<CustomersPage />} />
                  <Route path="/taxes" element={<TaxesPage />} />
                  <Route path="/items" element={<ItemsPage />} />
                  <Route path="/invoices" element={<InvoicesPage />} />
                  <Route path="/subscriptions" element={<SubscriptionsPage />} />
                  <Route
                    path="/invoices/form/:invoiceId"
                    element={<InvoiceForm />}
                  />
                  <Route path="/invoices/form/" element={<InvoiceForm />} />
                  <Route
                    path="/invoices/preview/:invoiceId"
                    element={<InvoicePreview />}
                  />
                </Route>

                <Route
                  element={
                    <Board>
                      <ProtectedRoute allowedRoles={["ROLE_ADMIN"]} />
                    </Board>
                  }
                >
                  {/*Admin user routes*/}
                  <Route path="/users" element={<UsersPage />} />
                </Route>
              </Route>
            </Routes>
          </div>
        </Router>
      </BackdropContextProvider>
    </AuthContextProvider>
  );
}

export default App;
