import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Box,
} from "@mui/material";
import Collapse from '@mui/material/Collapse';
import TableContainer from '@mui/material/TableContainer';
import {
  UilPlus,
  UilCog,
  UilAngleDown,
  UilAngleUp
} from "@iconscout/react-unicons";
import { formatDate, isNull } from "../../utils/Utils";
import Color from "../../enum/Color";
import CreateButton from "../Common/CreateButton";
import SubscriptionModal from "../Modal/SubscriptionModal";
import SubscriptionStatusEnum from "../../enum/SubscriptionStatusEnum";
import SubscriptionInvoices from "../Subscriptions/SubscriptionInvoices";

const statusStyle = (status) => {
  if (status === SubscriptionStatusEnum.ACTIVE) {
    return {
      backgroundColor: Color.SUCCESS,
      color: Color.FOREGROUND,
    };
  } else if (status === SubscriptionStatusEnum.CANCELED || status === SubscriptionStatusEnum.TERMINATED) {
    return {
      backgroundColor: Color.DANGER,
      color: Color.FOREGROUND,
    };
  } else if (status === SubscriptionStatusEnum.PENDING) {
    return {
      backgroundColor: Color.WARNING,
      color: Color.FOREGROUND,
    };
  } else {
    return {
      backgroundColor: Color.BACKGROUND,
      color: Color.FOREGROUND,
    };
  }
};

const SubscriptionsTable = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState("");
  const [isReadOnly, setIsReadOnly] = useState(false);


  const subscriptions = props.subscriptions;
  const customers = props.customers;
  const items = props.items;

  const closeModalHandler = () => {
    setShowModal((prev) => {
      return !prev;
    });

    props.refreshHandler();
  };

  const showDetails = (rowData, isReadOnly) => {
    setShowModal((prev) => {
      return !prev;
    });
    setModalData(() => {
      return rowData;
    });
    setIsReadOnly(isReadOnly);
  };

  const newModal = () => {
    setModalData(null);
    setShowModal((prev) => {
      return !prev;
    });
  };

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <TableRow >
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <UilAngleUp /> : <UilAngleDown />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.customer.companyDisplayName}
          </TableCell>
          <TableCell align="right">{isNull(row.startDate) ? "N/A" : formatDate(row.startDate)}</TableCell>
          <TableCell align="right">{isNull(row.endDate) ? "N/A" : formatDate(row.endDate)}</TableCell>
          <TableCell align="right">{row.item.name}</TableCell>
          <TableCell align="right">{row.paymentType}</TableCell>
          <TableCell align="center">
            <Chip
              label={row.status}
              style={statusStyle(row.status)}

            />
          </TableCell>
          <TableCell align={"left"} className="Details">
            <Tooltip title="Manage subscription">
              <IconButton onClick={() => showDetails(row, false)}>
                <UilCog
                  style={{ color: Color.BACKGROUND }}
                />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <SubscriptionInvoices subscription={row}/>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <div className="Table">
      <SubscriptionModal
        isVisible={showModal}
        isReadOnly={isReadOnly}
        closeModalHandler={closeModalHandler}
        modalData={modalData}
        customers={customers}
        items={items}
      />
      <CreateButton action={newModal} icon={<UilPlus />} text="New Subscription" />

      <Box sx={{ width: "100%" }}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Customer</TableCell>
                <TableCell align="right">Start Date</TableCell>
                <TableCell align="right">End Date</TableCell>
                <TableCell align="right">Product</TableCell>
                <TableCell align="right">Payment Type</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {isNull(subscriptions) ? (
                <Typography sx={{ m: 1, textAlign: "center" }}>
                  There are no subscriptions at this time. Add a new one.
                </Typography>
              ) :
                subscriptions.map((subscription) => {
                  return (
                    <Row key={subscription.id} row={subscription} />
                  );
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
};

export default SubscriptionsTable;
