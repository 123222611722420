import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../../store/auth-context";
import { baseUrl } from "../../Config";
import "./AbsentTimePage.css";
import { SendGetRequest } from "../../actions/Actions";
import AbsentTimeTable from "../Table/AbsentTimeTable";

const AbsentTimePage = () => {
  const [absentTimes, setAbsentTimes] = useState([]);

  const user = JSON.parse(sessionStorage.getItem("user"))
  const authContext = useContext(AuthContext);

  /*Fetch calendar*/
  const worktimeUrl = baseUrl + "/api/absenttime/all/" + user.id;

  const absentTimesCallback = (data) => {
    setAbsentTimes((prev) => {
      return data;
    });
  };
  useEffect(() => {
    SendGetRequest(worktimeUrl, authContext.token, absentTimesCallback);
  }, []);

  /***********/
  const refreshHandler = () => {
    SendGetRequest(worktimeUrl, authContext.token, absentTimesCallback);
  }

  return (
    <div className="AbsentTimePage">
      <AbsentTimeTable absentTimes={absentTimes} employee={user} refreshHandler={refreshHandler} />
    </div>
  );
};

export default AbsentTimePage;
