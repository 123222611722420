import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../../store/auth-context";
import { baseUrl } from "../../Config";
import "./ReportPage.css";
import { SendGetRequest, SendPostRequest } from "../../actions/Actions";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Color from "../../enum/Color";
import { convertMinutesToTime, isNull } from "../../utils/Utils";
import { UilAngleDown } from "@iconscout/react-unicons";
import BackdropContext from "../../store/backdrop-context";
import ReportPreview from "./ReportPreview";

const ReportPage = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [project, setProject] = useState(null);
  const [projects, setProjects] = useState([]);
  const [reports, setReports] = useState([]);

  const authContext = useContext(AuthContext);
  const backdropContext = useContext(BackdropContext);

  const handleProjectSelect = (event) => {
    const project = projects.find((c) => c.id === event.target.value);
    setProject(project);
  };

  /*Fetch reports*/
  const reportsUrl = baseUrl + "/api/reporting/project";

  const reportingCallback = (data) => {
    setReports(data);
    backdropContext.toggle();
  };

  const onSearch = () => {
    const searchRequest = {
      projectId: project.id,
      startDate: startDate,
      endDate: endDate,
    };
    backdropContext.toggle();
    SendPostRequest(
      reportsUrl,
      searchRequest,
      authContext.token,
      reportingCallback
    );
  };

  /*Fetch projects*/
  const projectsUrl = baseUrl + "/api/project/all";

  const projectsCallback = (data) => {
    setProjects(data);
  };

  useEffect(() => {
    SendGetRequest(projectsUrl, authContext.token, projectsCallback);
  }, []);

  return (
    <div className="ReportPage">
      <Paper>
        <Typography sx={{ m: 1 }}>Filters:</Typography>
        <FormControl sx={{ m: 1, minWidth: "200px", width: "20%" }}>
          <InputLabel id="demo-simple-select-label">Project</InputLabel>
          <Select
            required
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Project"
            name="project"
            onChange={handleProjectSelect}
            value={isNull(project) ? "" : project.id}
          >
            {projects &&
              projects.map((data) => {
                return (
                  <MenuItem value={data.id} key={data.id}>
                    {data.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        <TextField
          sx={{ m: 1, minWidth: 200 }}
          id="outlined-required"
          label="Start date"
          type="date"
          value={startDate || ""}
          onChange={(e) => setStartDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          sx={{ m: 1, minWidth: 200 }}
          id="outlined-required"
          label="End date"
          type="date"
          value={endDate || ""}
          onChange={(e) => setEndDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button
          variant="contained"
          sx={{
            height: "56px",
            marginTop: "8px",
            backgroundColor: Color.BACKGROUND,
          }}
          onClick={onSearch}
        >
          Search
        </Button>
      </Paper>

      <div style={{ marginTop: "2rem" }}>
        {isNull(reports) ? (
          <Typography sx={{ m: 1, textAlign: "center" }}>
            Use filters to find reports.
          </Typography>
        ) : (
          reports.map((report) => {
            return (
              <Accordion key={report.employeeName} style={{marginTop: "1rem"}}>
                <AccordionSummary
                  expandIcon={<UilAngleDown />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>{report.employeeName}: {convertMinutesToTime(report.totalMinutes)}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <ReportPreview report={report}/>
                </AccordionDetails>
              </Accordion>
            );
          })
        )}
      </div>
    </div>
  );
};

export default ReportPage;
