import React, {useState} from "react";

const AuthContext = React.createContext({
    token: "",
    isLoggedIn: false,
    login: (token) => {},
    logout: () => {}
});

export const AuthContextProvider = (props) => {

    const [token, setToken] = useState(sessionStorage.getItem("token"));
    const userIsLoggedIn = token == null || token === "null" ? false :true ;

    const loginHandler = (token) => {
        setToken("Bearer " + token);
        sessionStorage.setItem("token", "Bearer " + token)
    };

    const logoutHandler = () => {
        setToken(null);
        sessionStorage.clear();
    };

    const contextValue = {
        token: token,
        isLoggedIn: userIsLoggedIn,
        login: loginHandler,
        logout: logoutHandler
    }

    return (<AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>);
}

export default AuthContext

