import React, { useState } from "react";
import "./Card.css";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { motion, AnimateSharedLayout } from "framer-motion";
import { UilTimes } from "@iconscout/react-unicons";
import Chart from "react-apexcharts";
import { formatPrice, isNull } from "../../utils/Utils";

// parent Card

const Card = (props) => {
  return (
    <AnimateSharedLayout>
      <CompactCard param={props} />
    </AnimateSharedLayout>
  );
};

// Compact Card
function CompactCard({ param, setExpanded }) {
  const Png = param.png;
  return (
    <motion.div
      className="CompactCard"
      style={{
        background: param.color.backGround,
      }}
      layoutId="expandableCard"
    >
      <div className="radialBar">
        {param.displayProgress && <CircularProgressbar
          value={param.barValue}
          text={`${param.barValue}%`}
        />}
        <span>{param.title}</span>
      </div>
      <div className="detail">
        {isNull(param.png) ? <div></div> : <Png />}
        <span>{!isNull(param.prefix1) && param.prefix1} {!isNull(param.value1) && formatPrice(param.value1)} {!isNull(param.sufix1) && param.sufix1} </span>
        <span>{!isNull(param.prefix2) && param.prefix2} {!isNull(param.value2) && formatPrice(param.value2)} {!isNull(param.sufix2) && param.sufix2} </span>
        <span>In year {new Date().getFullYear()}</span>
      </div>
    </motion.div>
  );
}

export default Card;
