import React from "react";
import "./ReportPage.css";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
} from "@mui/material";
import Color from "../../enum/Color";
import { formatDate, isNull, roundToTwo } from "../../utils/Utils";

import generatePDF, { Resolution, Margin } from "react-to-pdf";

const ReportPreview = (props) => {
  const report = props.report;

  const downloadPDF = () => {
    const options = {
      filename: (report.employeeName + " - " + report.projectName + " - " + formatDate(report.startDate) + "-" + formatDate(report.endDate)).slice(0, -1)+".pdf",
      method: "save",
      // default is Resolution.MEDIUM = 3, which should be enough, higher values
      // increases the image quality but also the size of the PDF, so be careful
      // using values higher than 10 when having multiple pages generated, it
      // might cause the page to crash or hang.
      resolution: Resolution.EXTREME,
      page: {
        // margin is in MM, default is Margin.NONE = 0
        margin: Margin.SMALL,
        // default is 'A4'
        format: "A4",
        // default is 'portrait'
        orientation: "portrait"
      },
      canvas: {
        // default is 'image/jpeg' for better size performance
        mimeType: "image/jpeg",
        qualityRatio: 1
      },
      // Customize any value passed to the jsPDF instance and html2canvas
      // function. You probably will not need this and things can break,
      // so use with caution.
      overrides: {
        // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
        pdf: {
          compress: false
        },
        // see https://html2canvas.hertzen.com/configuration for more options
        canvas: {
          useCORS: true
        }
      }
    };
    const getTargetElement = () => document.getElementById("report");
    generatePDF(getTargetElement, options);
  }

  const getRowColor = (item, index) => {
    if(!isNull(item) && (item.holiday || item.absentTime)) {
      return Color.HOLIDAY
    } else {
      return index%2!==0 ? Color.ACCENT1 : "white"
    }
  }
  
  return (
    <div
      className="ReportPage"
      style={{ backgroundColor: Color.ACCENT1, paddingBottom: "8px" }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Toolbar
          style={{
            display: "flex",
            gap: "1rem",
            justifyContent: "end",
            backgroundColor: Color.ACCENT2,
          }}
        >
          <Button
            variant="contained"
            style={{ backgroundColor: Color.BACKGROUND }}
            onClick={downloadPDF}
          >
            Download report
          </Button>
        </Toolbar>

        <div className="invoicePage" id="report">
          <div
            className="details-container clearfix invoicePage"
            style={{ margiLeft: "auto", margiRight: "auto" }}
          >
            <div className="flex">
              <div>
                <div className="pcs-template-body">
                  <h3 style={{marginTop: "2rem"}}>{report.projectName}</h3>

                  <div style={{marginTop: "2rem"}}>
                    <div>
                      <span>Employee name: </span>{" "}
                      <span>{report.employeeName}</span>
                    </div>
                    <div>
                      <span>Service period:</span>
                    </div>
                    <div>
                      <span style={{ marginRight: 10 }}>Start: </span>{" "}
                      <span style={{ marginRight: 50 }}>
                        {formatDate(report.startDate)}
                      </span>{" "}
                      <span style={{ marginRight: 10 }}>End: </span>{" "}
                      <span>{formatDate(report.endDate)}</span>
                    </div>
                  </div>

                  <div style={{marginTop: "2rem"}}>
                    <TableContainer>
                      <Table
                        sx={{
                          maxWidth: "100%",
                          "& .MuiTableCell-root": {
                            border: "1px solid rgba(0, 0, 0, 1)",
                            padding: "5px",
                            textAlign: "center",
                          },
                        }}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow sx={{ backgroundColor: Color.ACCENT3 }}>
                            <TableCell sx={{ width: "10%" }}>Date</TableCell>
                            <TableCell sx={{ width: "15%" }}>
                              Start time
                            </TableCell>
                            <TableCell sx={{ width: "15%" }}>
                              End time
                            </TableCell>
                            <TableCell sx={{ width: "5%" }}>Hours</TableCell>
                            <TableCell sx={{ width: "30%" }}>Task</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {report.worktimeItems.map((item, index) => {
                            return (
                              <TableRow key={index} style={{backgroundColor: getRowColor(item, index)}}>
                                <TableCell>{!isNull(item.date) && formatDate(item.date)}</TableCell>
                                <TableCell>
                                  {!isNull(item.startTime) && item.startTime.substring(0, 5)}
                                </TableCell>
                                <TableCell>
                                  {!isNull(item.startTime) && item.endTime.substring(0, 5)}
                                </TableCell>
                                <TableCell>
                                  {roundToTwo(+item.minutes / 60)}
                                </TableCell>
                                <TableCell>{item.task}</TableCell>
                              </TableRow>
                            );
                          })}
                          <TableRow
                            key={3}
                            style={{ marginTop: "8px" }}
                            sx={{
                              "& .MuiTableCell-root": {
                                border: "0px",
                              },
                            }}
                          >
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                          <TableRow
                            key={3}
                            style={{ marginTop: "8px" }}
                            sx={{
                              backgroundColor: Color.ACCENT2,
                              "& .MuiTableCell-root": {
                                border: "0px",
                              },
                            }}
                          >
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>Total:</TableCell>
                            <TableCell>
                              <b>{roundToTwo(+report.totalMinutes / 60)}h</b>
                            </TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportPreview;
