import {
  UilBill,
  UilMoneyWithdrawal,
  UilUsdSquare,
} from "@iconscout/react-unicons";
import React from "react";
import "./Cards.css";

import { Paper } from "@mui/material";
import Color from "../../enum/Color";
import { isNull } from "../../utils/Utils";
import Card from "../Card/Card";
import { useState } from "react";
import { useEffect } from "react";
import LineChartCard from "../Card/LineChartCard";

const Cards = (props) => {
  const [customerStats, setCustomerStats] = useState([]);
  const companyInfo = JSON.parse(sessionStorage.getItem("companyInfo"));
  console.log(props)

  useEffect(() => {
    if (!isNull(props.stats)) {
      setCustomerStats(props.stats.customerStatisticsList);
    }
  }, [props]);

  return (
    <div>
      <div className="Cards">
        <Paper elevation={10} className="parentContainer">
          <Card
            title="Total Sales"
            color={{
              backGround: Color.BACKGROUND,
            }}
            value1={isNull(props.stats) ? 0 : props.stats.totalSales}
            sufix1={companyInfo.localCurrency}
            png={UilUsdSquare}
            displayProgress={false}
          />
        </Paper>
        <Paper elevation={7} className="parentContainer">
          <Card
            title="Confirmed"
            color={{
              backGround: Color.SUCCESS,
            }}
            value1={isNull(props.stats) ? 0 : props.stats.confirmed}
            sufix1={companyInfo.localCurrency}
            barValue={isNull(props.stats) ? 0 : props.stats.confirmedPercentage}
            png={UilMoneyWithdrawal}
            displayProgress={true}
          />
        </Paper>
        <Paper elevation={7} className="parentContainer">
          <Card
            title="Pending"
            color={{
              backGround: Color.WARNING,
            }}
            value1={isNull(props.stats) ? 0 : props.stats.pending}
            sufix1={companyInfo.localCurrency}
            barValue={isNull(props.stats) ? 0 : props.stats.pendingPercentage}
            png={UilBill}
            displayProgress={true}
          />
        </Paper>
      </div>
      <div className="Cards">
        {!isNull(customerStats) &&
          customerStats.map((customerStat) => {
            return (
              <Paper elevation={10} className="parentContainer">
                <Card
                  key={customerStat.customer.id}
                  title={customerStat.customer.companyDisplayName}
                  color={{
                    backGround: Color.BACKGROUND,
                  }}
                  prefix1="Total sales:"
                  value2={isNull(customerStat) ? 0 : customerStat.totalSales}
                  sufix2={companyInfo.localCurrency}
                  barValue={
                    isNull(customerStat) ? 0 : customerStat.salesPercentage
                  }
                  displayProgress={true}
                />
                {console.log(customerStat.totalSales)}
              </Paper>
            );
          })}
      </div>
      {!isNull(props.stats) &&  
      !isNull(props.stats.salesChartData) && <div className="Cards">
        <div className="parentContainer">
          <LineChartCard
            title={"Sales by year"}
            color={{
              background: Color.BACKGROUND,
              boxShadow: `0px 10px 20px 0px ${Color.ACCENT1}`,
            }}
            series={[
              {
                name: "Revenue",
                data: props.stats.salesChartData.map(item => item.sales),
              },
            ]}
            categories={props.stats.salesChartData.map(item => item.year)}
          />
        </div>
      </div>
      }
    </div>
  );
};

export default Cards;
