// Sidebar imports
import {
  UilChartLine,
  UilUsersAlt,
  UilShoppingBasket,
  UilInvoice,
  UilInfo,
  UilBill,
  UilSuitcase,
  UilConstructor,
  UilHourglass,
  UilCalendarAlt,
  UilFileGraph,
  UilSetting,
  UilCheckSquare,
  UilBag,
  UilCarSideview,
  UilPlaneDeparture,
  UilStreering,
  UilMapMarker,
  UilEuroCircle,
} from "@iconscout/react-unicons";

// Sidebar Data
export const CommonMenu = [
  {
    icon: UilCalendarAlt,
    heading: "Calendar",
    path: "/calendar",
    roles: ["ROLE_USER"]
  },
];

export const SettingsMenu = [
  {
    icon: UilSetting,
    heading: "Settings",
    path: "/settings",
    roles: ["ROLE_ADMIN"]
  },
  {
    icon: UilStreering,
    heading: 'Local Travel Settings',
    path: "/local-travel-settings",
    roles: ["ROLE_ADMIN"]
  },
  {
    icon: UilInfo,
    heading: 'Company Info',
    path: "/info",
    roles: ["ROLE_ADMIN", "ROLE_INVOICE_USER"]
  },
];

export const InvoiceMenu = [
  {
    icon: UilChartLine,
    heading: "Analytics",
    path: "/analytics",
    roles: ["ROLE_ADMIN", "ROLE_INVOICE_USER"]
  },
  {
    icon: UilUsersAlt,
    heading: "Customers",
    path: "/customers",
    roles: ["ROLE_ADMIN", "ROLE_INVOICE_USER"]
  },
  {
    icon: UilBill,
    heading: "Taxes",
    path: "/taxes",
    roles: ["ROLE_ADMIN", "ROLE_INVOICE_USER"]
  },
  {
    icon: UilShoppingBasket,
    heading: "Items",
    path: "/items",
    roles: ["ROLE_ADMIN", "ROLE_INVOICE_USER"]
  },
  {
    icon: UilInvoice,
    heading: 'Invoices',
    path: "/invoices",
    roles: ["ROLE_ADMIN", "ROLE_INVOICE_USER"]
  },
  {
    icon: UilEuroCircle,
    heading: 'Subscriptions',
    path: "/subscriptions",
    roles: ["ROLE_ADMIN", "ROLE_INVOICE_USER"]
  },
  
];

export const UserManagementMenu = [
  {
    icon: UilUsersAlt,
    heading: 'Users',
    path: "/users",
    roles: ["ROLE_ADMIN"]
  },
]

export const ProjectManagementMenu = [
  {
    icon: UilSuitcase,
    heading: 'Projects',
    path: "/projects",
    roles: ["ROLE_ADMIN", "ROLE_PM_USER"]
  },
  {
    icon: UilConstructor,
    heading: 'Engagements',
    path: "/engagements",
    roles: ["ROLE_ADMIN", "ROLE_PM_USER"]
  },
  {
    icon: UilHourglass,
    heading: 'Worktime',
    path: "/worktime",
    roles: ["ROLE_ADMIN", "ROLE_USER"]
  },
  {
    icon: UilBag,
    heading: 'Absent Time',
    path: "/absenttime",
    roles: ["ROLE_ADMIN", "ROLE_USER"]
  },
  {
    icon: UilFileGraph,
    heading: 'Reports',
    path: "/reports",
    roles: ["ROLE_ADMIN", "ROLE_PM_USER"]
  },
  {
    icon: UilCheckSquare,
    heading: 'Tasks',
    path: "/tasks",
    roles: ["ROLE_ADMIN", "ROLE_USER"]
  },
]

export const TravelManagementMenu = [
  {
    icon: UilCarSideview,
    heading: 'Vehicles',
    path: "/vehicles",
    roles: ["ROLE_ADMIN", "ROLE_USER"]
  },
  {
    icon: UilPlaneDeparture,
    heading: 'Travel warrants',
    path: "/travel-warrants",
    roles: ["ROLE_ADMIN", "ROLE_USER"]
  },
  {
    icon: UilMapMarker,
    heading: 'Local travel report',
    path: "/local-travel",
    roles: ["ROLE_ADMIN", "ROLE_USER"]
  },
]

export const QuickTimeInsert = [
  {
    name: "8:00-15:30",
    startTime: "08:00",
    endTime: "15:30"
  },
  {
    name: "8:30-16:00",
    startTime: "08:30",
    endTime: "16:00"
  },
  {
    name: "9:00-16:30",
    startTime: "09:00",
    endTime: "16:30"
  },
]

export const QuickTimeInsertFullTime = [
  {
    name: "8:00-16:00",
    startTime: "08:00",
    endTime: "16:00"
  },
  {
    name: "8:30-16:30",
    startTime: "08:30",
    endTime: "16:30"
  },
  {
    name: "9:00-17:00",
    startTime: "09:00",
    endTime: "17:00"
  },
]

export const cardsData = [
  {
    series: [
      {
        name: "Revenue",
        data: [10, 100, 50,],
      },
    ],
  },
];
