import ReactDOM from "react-dom";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
} from "@mui/material";
import "./Modal.css";
import { Fragment,} from "react";


const ModalContent = (props) => {
  const handleCancelClick = () => {
    props.denieAction();
  };

  const handleOkClick = () => {
    props.confirmAction();
  };

  return (
    <div>
      <Dialog open={props.isVisible} maxWidth="lg">
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <Typography>{props.message}</Typography>
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            className="cancelButton"
            variant="contained"
            onClick={handleCancelClick}
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={handleOkClick}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const ConfirmationModal = (props) => {
  const onConfirm = () => {
    props.confirmAction();
  };

  const onDenie = () => {
    props.denieAction();
  };

  return (
    <Fragment>
      {ReactDOM.createPortal(
        <ModalContent
          isVisible={props.isVisible}
          confirmAction={onConfirm}
          denieAction={onDenie}
          title={props.title}
          message={props.message}
        />,
        document.getElementById("modal-root")
      )}
    </Fragment>
  );
};

export default ConfirmationModal;
