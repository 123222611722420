import React from "react";
import {

  Navigate, Outlet
} from "react-router-dom";


import { useContext } from "react";
import AuthContext from "../../store/auth-context";


function AuthRoute(props) {
    
    const authContext = useContext(AuthContext);

  return authContext.isLoggedIn ? <Outlet/> : <Navigate to={"/login"} replace={true}/>
    
}

export default AuthRoute;