import ReactDOM from "react-dom";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import "./Modal.css";
import { Fragment, useContext, useEffect, useState } from "react";
import AuthContext from "../../store/auth-context";
import {
  SendPostRequest,
  SendPutRequest,
} from "../../actions/Actions";
import { getFullName, isNull } from "../../utils/Utils";
import { baseUrl } from "../../Config";
import BackdropContext from "../../store/backdrop-context";
import LanguageEnum from "../../enum/LanguageEnum";
import { useNavigate } from "react-router-dom";

const ModalContent = (props) => {
  let data = props.data;
  const vehicles = props.vehicles;
  const employee = props.employee;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [errorDetails, setErrorDetails] = useState({
    showError: false,
    errorCode: "",
    errorMessage: "",
  });

  const authContext = useContext(AuthContext);
  const backdropContext = useContext(BackdropContext);

  const [reason, setReason] = useState("");
  const [vehicle, setVehicle] = useState(null);
  const [location, setLocation] = useState("");
  const [departure, setDeparture] = useState(null);
  const [arrival, setArrival] = useState(null);
  const [distance, setDistance] = useState(null);
  const [report, setReport] = useState(null);
  const [language, setLanguage] = useState(LanguageEnum.CRO);
  const [errors, setErrors] = useState({
    vehicle: true,
    reason: true,
    location: true,
    departure: true,
    arrival: true,
    distance: true,
  });
  const [formValid, setFormValid] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const clearStates = () => {
    setReason(null);
    setVehicle(null);
    setLocation(null);
    setDeparture(null);
    setArrival(null);
    setDistance([]);
    setReport(null);
    setLanguage(null)
    setErrors({
      vehicle: true,
      reason: true,
      location: true,
      departure: true,
      arrival: true,
      distance: true
    });
  };

  useEffect(() => {
    if (!isNull(data)) {
      setReason(data.reason);
      setVehicle(data.vehicle);
      setLocation(data.location);
      setDeparture(data.departure);
      setArrival(data.arrival);
      setDistance(data.distance);
      setReport(data.report);
      setLanguage(data.language);
      setErrors({});
    } else {
      clearStates();
    }
  }, [data]);

  const handleVehicleSelect = (event) => {
    const vehicle = vehicles.find((c) => c.id === event.target.value);
    setVehicle(vehicle);
    blurHandler(event);
  };

  const hadleOnSaveClick = () => {
    backdropContext.toggle();
    const travelWarrant = {
      owner: getFullName(employee),
      ownerId: employee.id,
      vehicle: vehicle,
      reason: reason,
      location: location,
      departure: departure,
      arrival: arrival,
      distance: distance,
      report: report,
      language: language,
    };
    saveHandler(travelWarrant);
  };

  const hadleOnUpdateClick = () => {
    backdropContext.toggle();
    const travelWarrant = {
      id: data.id,
      number: data.number,
      year: data.year,
      name: data.name,
      owner: getFullName(employee),
      ownerId: employee.id,
      vehicle: vehicle,
      reason: reason,
      location: location,
      departure: departure,
      arrival: arrival,
      distance: distance,
      report: report,
      language: language,
      travelType: data.travelType
    };
    updateHandler(travelWarrant);
  };

  const saveOrUpdateCallback = (data) => {
    clearStates();
    backdropContext.toggle();
    props.onHide();
    navigate("/travel-warrants/preview/" + data.id);
  };

  const saveHandler = async (worktime) => {
    const url = baseUrl + "/api/travel-warrant";
    SendPostRequest(url, worktime, authContext.token, saveOrUpdateCallback);
  };

  const updateHandler = async (worktime) => {
    const url = baseUrl + "/api/travel-warrant";
    SendPutRequest(url, worktime, authContext.token, saveOrUpdateCallback);
  };

  const closeHandler = () => {
    clearStates();
    data = null;
    props.onHide();
  };

  const blurHandler = (event) => {
    setErrors((prev) => ({
      ...errors,
      [event.target.name]: !event.target.value,
    }));
  };

  useEffect(() => {
    validateForm();
  }, [errors]);

  const validateForm = () => {
    const bools = Object.values(errors);
    setFormValid((prev) => {
      return !bools.includes(true);
    });
  };

  return (
    <div>
      <Dialog open={props.isVisible} maxWidth="lg">
        <DialogTitle>
          {isNull(data) ? "New Travel Warrant" : "Edit Travel Warrant"}
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <div>
                <FormControl
                  sx={{ m: 1, minWidth: 460 }}
                  required
                  error={errors.vehicle}
                >
                  <InputLabel id="demo-simple-select-label">Vehicle</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="vehicle"
                    name="vehicle"
                    onChange={handleVehicleSelect}
                    value={isNull(vehicle) ? "" : vehicle.id}
                  >
                    {vehicles.map((data) => {
                      return (
                        <MenuItem value={data.id}>{data.make + " " + data.model + " - " + data.registration}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              <div>
              <TextField
                  required
                  error={errors.reason}
                  sx={{ m: 1, minWidth: 200 }}
                  id="outlined-required"
                  label="Reason"
                  type="text"
                  name="reason"
                  value={reason || ""}
                  onChange={(e) => setReason(e.target.value)}
                  onBlur={blurHandler}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  required
                  error={errors.location}
                  sx={{ m: 1, minWidth: 200 }}
                  id="outlined-required"
                  label="Location"
                  type="text"
                  name="location"
                  value={location || ""}
                  onChange={(e) => setLocation(e.target.value)}
                  onBlur={blurHandler}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div>
                <TextField
                  required
                  error={errors.departure}
                  sx={{ m: 1, minWidth: 200 }}
                  id="departure"
                  label="Departure"
                  type="datetime-local"
                  name="departure"
                  value={departure}
                  onChange={(e) => setDeparture(e.target.value)}
                  onBlur={blurHandler}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  required
                  error={errors.arrival}
                  sx={{ m: 1, minWidth: 200 }}
                  id="arrival"
                  label="Arrival"
                  type="datetime-local"
                  name="arrival"
                  value={arrival}
                  onChange={(e) => setArrival(e.target.value)}
                  onBlur={blurHandler}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div>
              <TextField
                  required
                  error={errors.distance}
                  sx={{ m: 1, minWidth: 200 }}
                  id="outlined-required"
                  label="Distance"
                  type="number"
                  name="distance"
                  step="1"
                  value={distance || ""}
                  onChange={(e) => setDistance(e.target.value)}
                  onBlur={blurHandler}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div>
                <TextField
                  sx={{ m: 1, minWidth: 460 }}
                  id="outlined-required"
                  label="Report (optional)"
                  type="text"
                  value={report || ""}
                  onChange={(e) => setReport(e.target.value)}
                  multiline
                  rows={10}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            className="cancelButton"
            variant="contained"
            onClick={closeHandler}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={!formValid}
            onClick={data && data.id ? hadleOnUpdateClick : hadleOnSaveClick}
            type="submit"
          >
            {data && data.id ? "Update" : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const TravelWarrantModal = (props) => {
  const onHide = () => {
    props.closeModalHandler();
  };
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <ModalContent
          isVisible={props.isVisible}
          onHide={onHide}
          data={props.data}
          vehicles={props.vehicles}
          employee={props.employee}
        />,
        document.getElementById("modal-root")
      )}
    </Fragment>
  );
};

export default TravelWarrantModal;
