import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";

import {
  Box,
  IconButton,
  TablePagination,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { UilPlus, UilTrashAlt, UilPen } from "@iconscout/react-unicons";
import Color from "../../enum/Color";
import {
  formatDate,
  getCurrentDate,
  getFullName,
  isNull,
} from "../../utils/Utils";
import { SendDeleteRequest } from "../../actions/Actions";
import { useContext } from "react";
import AuthContext from "../../store/auth-context";
import { baseUrl } from "../../Config";
import ConfirmationModal from "../Modal/ConfirmationModal";
import {
  DELETE_RECORD_MESSAGE,
  DELETE_RECORD_TITLE,
} from "../../Constants/Constants";
import { useRef } from "react";
import CreateButton from "../Common/CreateButton";
import AbsentTimeTypeEnum from "../../enum/AbsentTimeTypeEnum";
import AbsentTimeModal from "../Modal/AbsentTimeModal";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "employee",
    numeric: false,
    disablePadding: false,
    label: "Employee",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },

  {
    id: "startDate",
    numeric: false,
    disablePadding: false,
    label: "Start Date",
  },
  {
    id: "endDate",
    numeric: false,
    disablePadding: false,
    label: "End date",
  },
  {
    id: "note",
    numeric: false,
    disablePadding: false,
    label: "Note",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "",
  },
  {
    id: "secondaryActions",
    numeric: false,
    disablePadding: false,
    label: "",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = () => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Absent Time
      </Typography>
    </Toolbar>
  );
};

const AbsentTimeTable = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [modalData, setModalData] = useState("");

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("id");
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const absentTimes = props.absentTimes;
  const employee = props.employee;

  const authContext = useContext(AuthContext);
  const deleteId = useRef();

  const closeModalHandler = () => {
    setShowModal((prev) => {
      return !prev;
    });

    props.refreshHandler();
  };

  const toggleConfirmationDialog = () => {
    setShowDialog((prev) => {
      return !prev;
    });
  };

  const showDetails = (rowData, isReadOnly) => {
    setShowModal((prev) => {
      return !prev;
    });
    setModalData(() => {
      return rowData;
    });
  };

  const newModal = () => {
    setShowModal((prev) => {
      return !prev;
    });
    setModalData(() => {
      return null;
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onDeleteClick = (row) => {
    deleteId.current = row.id;
    toggleConfirmationDialog();
  };

  const deleteHandler = () => {
    const url = baseUrl + "/api/absenttime?id=" + deleteId.current;
    SendDeleteRequest(url, authContext.token, refreshHandler);
  };

  const refreshHandler = () => {
    deleteId.current = null;
    toggleConfirmationDialog();
    props.refreshHandler();
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - absentTimes.length) : 0;

  return (
    <div className="Table">
      <AbsentTimeModal
        isVisible={showModal}
        closeModalHandler={closeModalHandler}
        modalData={modalData}
        selectedDate={getCurrentDate}
        employee={employee}
      /> 

      <ConfirmationModal
        isVisible={showDialog}
        confirmAction={deleteHandler}
        denieAction={toggleConfirmationDialog}
        title={DELETE_RECORD_TITLE}
        message={DELETE_RECORD_MESSAGE}
      />

      <CreateButton
        action={newModal}
        icon={<UilPlus />}
        text="New Absent Time"
      />
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }} elevation={5}>
          <EnhancedTableToolbar />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={absentTimes.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(absentTimes, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow hover tabIndex={-1} key={row.id}>
                        <TableCell align="left">
                          {getFullName(employee)}
                        </TableCell>
                        <TableCell align="left">
                          {!isNull(row.type) &&
                            AbsentTimeTypeEnum[row.type]}
                        </TableCell>
                        <TableCell align="left">
                          {!isNull(row.startDate) && formatDate(row.startDate)}
                        </TableCell>
                        <TableCell align="left">
                          {!isNull(row.endDate) && formatDate(row.endDate)}
                        </TableCell>
                        <TableCell align="left">
                          {row.note}
                        </TableCell>
                      
                        <TableCell align={"left"} className="Details">
                          <Tooltip title="Edit worktime">
                            <IconButton onClick={() => showDetails(row, false)}>
                              <UilPen style={{ color: Color.BACKGROUND }} />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell align={"left"} className="Details">
                          <Tooltip title="Delete worktime">
                            <IconButton onClick={() => onDeleteClick(row)}>
                              <UilTrashAlt style={{ color: Color.DANGER }} />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={absentTimes.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </div>
  );
};

export default AbsentTimeTable;
