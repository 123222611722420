import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../../store/auth-context";
import useApiRequest from "../../store/use-http";
import "./TaxPage.css"
import { baseUrl } from "../../Config";
import TaxesTable from "../Table/TaxesTable";
import { SendGetRequest } from "../../actions/Actions";

const TaxesPage = () => {

  const [taxes, setTaxes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState("");

  const [errorDetails, setErrorDetails] = useState({
    showError: false,
    errorCode: "",
    errorMessage: "",
  });

  
  
  const authContext = useContext(AuthContext);

  let url = baseUrl + "/api/tax/all";

  useEffect(() => {
    SendGetRequest(url, authContext.token, taxCallback);
  }, [])

  const taxCallback = (data) => {
    setTaxes(data);
  }

  const refreshHandler = () => {
    SendGetRequest(url, authContext.token, taxCallback);
  }

  return (
    <div className="TaxPage">
      <TaxesTable data={taxes} refreshHandler={refreshHandler}/>
    </div>
  );
};

export default TaxesPage;
