import React from "react";
import "./Card.css";
import "react-circular-progressbar/dist/styles.css";
import { motion, AnimateSharedLayout } from "framer-motion";
import Chart from "react-apexcharts";
import Color from "../../enum/Color";

const LineChartCard = (props) => {
  return (
    <AnimateSharedLayout>
      <CompactCard param={props} />
    </AnimateSharedLayout>
  );
};

// Compact Card
function CompactCard({ param, setExpanded }) {
  const data = {
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },

      dropShadow: {
        enabled: false,
        enabledOnSeries: undefined,
        top: 0,
        left: 0,
        blur: 3,
        color: "#000",
        opacity: 0.35,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        colors: [Color.WARNING],
      },
      grid: {
        show: true,
      },
      xaxis: {
        type: "integer",
        categories: param.categories,
        labels: {
          show: true,
          style: {
            colors: "#ffffff"
          }
        }
      },
      yaxis: {
        labels: {
          show: true,
          style: {
            colors: "#ffffff"
          }
        }
      },
    },
  };
  return (
    <motion.div
      className="ExpandedCard"
      style={{
        background: param.color.background,
        boxShadow: param.color.boxShadow,
      }}
      layoutId="expandableCard"
      onClick={setExpanded}
    >
      <span>{param.title}</span>
      <div className="chartContainer">
        <Chart options={data.options} series={param.series} type="line" height={"100%"} />
      </div>
    </motion.div>
  );
}

export default LineChartCard;
