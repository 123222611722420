import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../../store/auth-context";
import { baseUrl } from "../../Config";
import "./LocalTravelPage.css";
import { SendGetRequest, SendPostRequest } from "../../actions/Actions";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Color from "../../enum/Color";
import { convertMinutesToTime, formatDate, isNull } from "../../utils/Utils";
import { UilAngleDown } from "@iconscout/react-unicons";
import BackdropContext from "../../store/backdrop-context";
import { display } from "@mui/system";
import LocalTravelReport from "./LocalTravelReport";
//import ReportPreview from "./ReportPreview";

const LocalTravelPage = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [vehicle, setVehicle] = useState(null);
  const [vehicles, setVehicles] = useState([]);
  const [startingDistance, setStartingDistance] = useState();
  const [excludeDays, setExcludeDays] = useState([]);


  const [report, setReport] = useState([]);

  const authContext = useContext(AuthContext);
  const backdropContext = useContext(BackdropContext);

  const user = JSON.parse(sessionStorage.getItem("user"));

  const handleVehicleSelect = (event) => {
    const vehicle = vehicles.find((c) => c.id === event.target.value);
    setVehicle(vehicle);
  };

  const handleDateSelect = (date) => {
    setExcludeDays(prev => [...prev, date]);
  };

  const handleDeleteChip = (date) => {
    setExcludeDays(excludeDays.filter((d) => d !== date));
  };

  /*Fetch reports*/
  const reportsUrl = baseUrl + "/api/local-travel";

  const reportingCallback = (data) => {
    setReport(data);
    backdropContext.toggle();
  };

  const onSearch = () => {
    const searchRequest = {
      vehicle: vehicle,
      startDate: startDate,
      endDate: endDate,
      startingDistance: startingDistance,
      excludeDays: excludeDays
    };
    backdropContext.toggle();
    SendPostRequest(
      reportsUrl,
      searchRequest,
      authContext.token,
      reportingCallback
    );
  };

  /*Fetch vehicles*/
  const vehiclesUrl = baseUrl + "/api/vehicle/all/" + user.id;

  const vehiclesCallback = (data) => {
    setVehicles(data);
  };

  useEffect(() => {
    SendGetRequest(vehiclesUrl, authContext.token, vehiclesCallback);
  }, []);

  return (
    <div className="ReportPage">
      <Paper>
        <Typography sx={{ m: 1 }}>Filters:</Typography>
        <div>
          <FormControl sx={{ m: 1, minWidth: "200px", width: "20%" }}>
            <InputLabel id="demo-simple-select-label">Vehicle</InputLabel>
            <Select
              required
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Vehicle"
              name="vehicle"
              onChange={handleVehicleSelect}
              value={isNull(vehicle) ? "" : vehicle.id}
            >
              {vehicles &&
                vehicles.map((data) => {
                  return (
                    <MenuItem value={data.id} key={data.id}>
                      {data.make + " " + data.model + " - " + data.registration}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          <TextField
            sx={{ m: 1, minWidth: 200 }}
            id="outlined-required"
            label="Starting distance"
            type="number"
            value={startingDistance || ""}
            onChange={(e) => setStartingDistance(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            sx={{ m: 1, minWidth: 200 }}
            id="outlined-required"
            label="Start date"
            type="date"
            value={startDate || ""}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            sx={{ m: 1, minWidth: 200 }}
            id="outlined-required"
            label="End date"
            type="date"
            value={endDate || ""}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div style={{ display: "flex" }}>
          <TextField
            sx={{ m: 1, minWidth: 200 }}
            id="outlined-required"
            label="Exclude days"
            type="date"
            onChange={(e) => handleDateSelect(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Stack direction="row" spacing={1} sx={{ m: 1 }}
          >
            {excludeDays.map(date => <Chip label={formatDate(date)} onDelete={() => handleDeleteChip(date)} />)}
          </Stack>
        </div>
        <Button
          variant="contained"
          sx={{
            m: 1,
            height: "56px",
            marginTop: "8px",
            backgroundColor: Color.BACKGROUND,
          }}
          onClick={onSearch}
        >
          Generate report
        </Button>
      </Paper>

      <div style={{ marginTop: "2rem" }}>
        {isNull(report) ? (
          <Typography sx={{ m: 1, textAlign: "center" }}>
            Use filters to find reports.
          </Typography>
        ) : <LocalTravelReport report={report} />
        }
      </div>
    </div>
  );
};

export default LocalTravelPage;
