import React from "react";

import { formatBarcodePrice } from "../../utils/Utils";
import PDF417 from "pdf417-generator";

const InvoiceBarcode = (props) => {
  const companyInfo = JSON.parse(sessionStorage.getItem("companyInfo"));
  const invoice = props.invoice;
  const code = `HRVHUB30
                EUR
                ${formatBarcodePrice(invoice.total)}
                ${invoice.customer.companyDisplayName}
                ${invoice.customer.address}
                ${invoice.customer.zipCode + " " + invoice.customer.city}
                ${companyInfo.companyName}
                ${companyInfo.address}
                ${companyInfo.zipCode + " " + companyInfo.city}
                ${companyInfo.iban}
                HR00
                ${invoice.invoiceNumber}
                GDSV
                Uplata po nalogu ${invoice.invoiceNumber}`;

    let canvas = document.createElement('canvas');
    PDF417.draw(code, canvas, 4, -1, 3);


    return <img src={canvas.toDataURL()} alt="Kod" style={{width: "100%"}}/>
};

export default InvoiceBarcode;
