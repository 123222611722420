import ReactDOM from "react-dom";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import "./Modal.css";
import { Fragment, useContext, useEffect, useState } from "react";
import AuthContext from "../../store/auth-context";
import BackdropContext from "../../store/backdrop-context";
import { SendPostRequest, SendPutRequest } from "../../actions/Actions";
import { isNull } from "../../utils/Utils";
import { baseUrl } from "../../Config";
import SubscriptionStatusEnum from "../../enum/SubscriptionStatusEnum";
import PaymentTypeEnum from "../../enum/PaymentTypeEnum";

const ModalContent = (props) => {
  const statuses = Object.keys(SubscriptionStatusEnum);
  const paymentTypes = Object.keys(PaymentTypeEnum);

  let data = props.data;
  const customers = props.customers;
  const items = props.items;

  const authContext = useContext(AuthContext);
  const backdropContext = useContext(BackdropContext);

  const [customer, setCustomer] = useState(null);
  const [item, setItem] = useState(null);
  const [status, setStatus] = useState();
  const [startDate, setStartDate] = useState(null);
  const [contractNumber, setContractNumber] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [paymentType, setPaymentType] = useState(null);

  useEffect(() => {
    if (!isNull(data)) {
      setStartDate(data.startDate);
      setEndDate(data.endDate);
      setStatus(data.status);
      setCustomer(data.customer);
      setItem(data.item);
      setContractNumber(data.contractNumber);
      setPaymentType(data.paymentType);
    }
  }, [data]);

  const handleStatusSelect = (event) => {
    setStatus(event.target.value);
  };

  const handlePaymentTypeSelect = (event) => {
    setPaymentType(event.target.value);
  };

  const handleClientSelect = (event) => {
    const client = customers.find((c) => c.id === event.target.value);
    setCustomer(client);
  };

  const handleItemSelect = (event) => {
    const item = items.find((c) => c.id === event.target.value);
    setItem(item);
  };

  const hadleOnSaveClick = () => {
    const subscription = {
      customer: customer,
      item: item,
      startDate: startDate,
      endDate: endDate,
      status: status,
      contractNumber: contractNumber,
      paymentType: paymentType
    };
    saveHandler(subscription);
    backdropContext.toggle();
  };

  const hadleOnUpdateClick = () => {
    const subscription = {
      id: data.id,
      customer: customer,
      item: item,
      startDate: startDate,
      endDate: endDate,
      status: status,
      contractNumber: contractNumber,
      paymentType: paymentType
    };
    updateHandler(subscription);
    backdropContext.toggle();
  };

  const saveHandler = async (subscription) => {
    const url = baseUrl + "/api/subscription";
    SendPostRequest(url, subscription, authContext.token, subscriptionsHandler);
    clearStates();
  };

  const updateHandler = async (subscription) => {
    const url = baseUrl + "/api/subscription";
    SendPutRequest(url, subscription, authContext.token, subscriptionsHandler);
    clearStates();
  };

  const subscriptionsHandler = () => {
    backdropContext.toggle();
    props.onHide();
  }

  const clearStates = () => {
    setCustomer(null);
    setItem(null);
    setStatus(null);
    setStartDate(null);
    setContractNumber(null);
    setEndDate(null);
    setPaymentType(null);
  };

  const closeHandler = () => {
    clearStates();
    data = null;
    props.onHide();
  };
  return (
    <div>
      <Dialog open={props.isVisible} maxWidth="lg">
        <DialogTitle>
          {isNull(data) ? "New Subscription" : "Edit Subscription"}
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <div>
                <FormControl sx={{ m: 1, minWidth: 500 }}>
                  <InputLabel id="demo-simple-select-label">
                    Customer
                  </InputLabel>
                  <Select
                    disabled={props.isReadOnly}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Language"
                    onChange={handleClientSelect}
                    value={isNull(customer) ? "" : customer.id}
                  >
                    {customers &&
                      customers.map((data) => {
                        return (
                          <MenuItem value={data.id}>
                            {data.companyDisplayName}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </div>

              <div>
                <FormControl sx={{ m: 1, minWidth: 500 }}>
                  <InputLabel id="demo-simple-select-label">
                    Item
                  </InputLabel>
                  <Select
                    disabled={props.isReadOnly}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Language"
                    onChange={handleItemSelect}
                    value={isNull(item) ? "" : item.id}
                  >
                    {items &&
                      items.map((data) => {
                        return (
                          <MenuItem value={data.id}>
                            {data.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </div>

              <div>
                <FormControl sx={{ m: 1, minWidth: 500 }}>
                  <InputLabel id="demo-simple-select-label">
                    Payment Type
                  </InputLabel>
                  <Select
                    disabled={props.isReadOnly}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Language"
                    onChange={handlePaymentTypeSelect}
                    value={isNull(paymentType) ? "" : paymentType}
                  >
                    {paymentTypes &&
                      paymentTypes.map((data) => {
                        return (
                          <MenuItem value={data}>
                            {data}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </div>

              <div>
                <TextField
                  sx={{ m: 1, minWidth: 200 }}
                  disabled={props.isReadOnly}
                  id="date"
                  label="Start Date"
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  sx={{ m: 1, minWidth: 200 }}
                  disabled={props.isReadOnly}
                  id="date"
                  label="End Date"
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              
              <div>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-label">
                    Status
                  </InputLabel>
                  <Select
                    disabled={props.isReadOnly}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Status"
                    onChange={handleStatusSelect}
                    defaultValue={isNull(data) ? "" : data.status}
                  >
                    {statuses.map((key) => {
                      return (
                        <MenuItem value={key}>{SubscriptionStatusEnum[key]}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>

              <div>
                <TextField
                  sx={{ m: 1, minWidth: 200 }}
                  disabled={props.isReadOnly}
                  id="contractNumber"
                  label="Contract Number"
                  value={contractNumber}
                  onChange={(e) => setContractNumber(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            className="cancelButton"
            variant="contained"
            onClick={closeHandler}
          >
            {props.isReadOnly ? "Close" : "Cancel"}
          </Button>
          <Button
            disabled={props.isReadOnly}
            variant="contained"
            onClick={data && data.id ? hadleOnUpdateClick : hadleOnSaveClick}
          >
            {data && data.id ? "Update" : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const SubscriptionModal = (props) => {
  const onHide = () => {
    props.closeModalHandler();
  };

  return (
    <Fragment>
      {ReactDOM.createPortal(
        <ModalContent
          isVisible={props.isVisible}
          onHide={onHide}
          data={props.modalData}
          customers={props.customers}
          items={props.items}
          isReadOnly={props.isReadOnly}
        />,
        document.getElementById("modal-root")
      )}
    </Fragment>
  );
};

export default SubscriptionModal;
