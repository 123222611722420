import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../../store/auth-context";
import useApiRequest from "../../store/use-http";
import { baseUrl } from "../../Config";
import CustomersTable from "../Table/CustomersTable";
import "./CustomerPage.css";
import { SendGetRequest } from "../../actions/Actions";

const CustomersPage = () => {
  const [customers, setCustomers] = useState([]);
  const [taxData, setTaxData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState("");
  const [errorDetails, setErrorDetails] = useState({
    showError: false,
    errorCode: "",
    errorMessage: "",
  });

  const authContext = useContext(AuthContext);

  const taxUrl = baseUrl + "/api/tax/all";
  const url = baseUrl + "/api/customer/all";

  useEffect(() => {
    SendGetRequest(taxUrl, authContext.token, taxesHandler);
    SendGetRequest(url, authContext.token, customersHandler);
  }, []);

  const taxesHandler = (data) => {
    setTaxData(data);
  }

  const customersHandler = (data) => {
    setCustomers(data);
  }

  const refreshHandler = () => {
    SendGetRequest(taxUrl, authContext.token, taxesHandler);
    SendGetRequest(url, authContext.token, customersHandler);
  }

  return (
    <div className="CustomerPage">
      <CustomersTable data={customers} taxes={taxData} refreshHandler={refreshHandler} />
    </div>
  );
};

export default CustomersPage;
