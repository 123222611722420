import React, { useContext, useRef } from "react";
import { TextField, Button, CircularProgress, Typography } from "@mui/material";
import AuthContext from "../../store/auth-context";
import { baseUrl } from "../../Config";
import { useState } from "react";
import Color from "../../enum/Color";
import "./ResetPassword.css";

const ResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isRepeatPasswordError, setIsRepeatPasswordError] = useState(false);
  const [isNewPasswordError, setIsNewPasswordError] = useState(false);

  const oldPasswordInputRef = useRef();
  const passwordInputRef = useRef();
  const repeatPasswordInputRef = useRef();

  const authContext = useContext(AuthContext);

  const resetPasword = () => {
    const inputOldPAssword = oldPasswordInputRef.current.value;
    const inputPassword = passwordInputRef.current.value;

    setIsLoading(true);
    setIsError(false);
    setIsNewPasswordError(false);
    setIsRepeatPasswordError(false);

    fetch(baseUrl + "/api/user/password-reset", {
      method: "POST",
      body: new URLSearchParams({
        oldPassword: inputOldPAssword,
        newPassword: inputPassword,
      }),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
        "Authorization": authContext.token,
      },
    })
      .then((res) => {
        if (res.ok) {
        authContext.logout();
        } else {
          return res.json().then((data) => {
            let alert = "Password resset failed";
            setIsError(true);
            throw new Error(alert);
          });
        }
      })
      .catch((err) => {
        console.log(err.message);
        setIsLoading(false);
      });
  };

  const validateBforeLogin = () => {
    const inputOldPAssword = oldPasswordInputRef.current.value;
    const inputPassword = passwordInputRef.current.value;
    const inputRepeatPassword = repeatPasswordInputRef.current.value;
    let hasError = false;

    if(inputOldPAssword === inputPassword) {
        setIsNewPasswordError(prev => {return true});
        hasError = true;
    } else {
        setIsNewPasswordError(prev => {return false});
    }

    if(inputPassword !== inputRepeatPassword) {
        setIsRepeatPasswordError(prev => {return true});
        hasError = true
    } else {
        setIsRepeatPasswordError(prev => {return false});
    }

    if(!hasError) {
        resetPasword();
    }


  } 

  const keyPressHandler = (event) => {
    if (event.key === "Enter") {
      validateBforeLogin();
    }
  };

  return (
    <div className="rp-background">
      <div className="rp-card">

        <h4>Change password</h4>

        {isError && <p style={{color: Color.DANGER, textAlign: "center"}}>Faild to reset the password.</p>}

        <form>
          <div>
            <TextField
              id="outlined-username-input"
              name="oldPassword"
              label="Old Password"
              type="password"
              autoComplete="current-password"
              className="input-field"
              inputRef={oldPasswordInputRef}
              required
            />
          </div>
          {isNewPasswordError && <Typography variant={"caption"} style={{color: Color.DANGER, textAlign: "center"}}>New and old passwords are identical.</Typography>}
          <div>
            <TextField
              id="outlined-password-input"
              name="newPassword"
              label="New Password"
              type="password"
              autoComplete="current-password"
              className="input-field"
              inputRef={passwordInputRef}
              required
            />
          </div>
          {isRepeatPasswordError && <Typography variant={"caption"} style={{color: Color.DANGER, textAlign: "center"}}>Password does not match new password.</Typography>}
          <div>
            <TextField
              id="outlined-password-input"
              name="repeatPassword"
              label="Repeat Password"
              type="password"
              autoComplete="current-password"
              className="input-field"
              inputRef={repeatPasswordInputRef}
              onKeyDown={keyPressHandler}
              required
            />
          </div>
          {isLoading && (
            <CircularProgress
              sx={{
                "& .MuiCircularProgress-circleIndeterminate": {
                  color: Color.BACKGROUND,
                },
              }}
            />
          )}
          <Button variant="contained" className="button" onClick={validateBforeLogin}>
            Change password
          </Button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
