import React, { useContext, useEffect, useState } from "react";
import Cards from "../Cards/Cards";
import "./MainDash.css";
import AuthContext from "../../store/auth-context";
import { baseUrl } from "../../Config";
import MainDashTable from "../Table/MainDashTable";
import { SendGetRequest } from "../../actions/Actions";

const MainDash = () => {

  const [invoices, setInvoices] = useState([]);
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorDetails, setErrorDetails] = useState({
    showError: false,
    errorCode: "",
    errorMessage: "",
  });

  const authContext = useContext(AuthContext);

  const url = baseUrl + "/api/invoice/recent";
  const statsUrl = baseUrl + "/api/stats";

  useEffect(() => {
    SendGetRequest(url, authContext.token, getInvoicesCallback);
    SendGetRequest(statsUrl, authContext.token, getStatsCallback);
  }, []);

  const getInvoicesCallback = (data) => {
    setInvoices(data)
  }

  const getStatsCallback = (data) => {
    setStats(data)
  }
  
  return (
    <div className="MainDash">
      <Cards stats={stats} />
      <MainDashTable invoices={invoices}/>
    </div>
  );
};

export default MainDash;
