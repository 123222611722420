import ReactDOM from "react-dom";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import "./Modal.css";
import { Fragment, useContext, useEffect, useState } from "react";
import AuthContext from "../../store/auth-context";
import BackdropContext from "../../store/backdrop-context";
import { SendPostRequest, SendPutRequest } from "../../actions/Actions";
import { getFullName, isNull } from "../../utils/Utils";
import { baseUrl } from "../../Config";
import ProjectManagementStatusEnum from "../../enum/ProjectManagementStatusEnum";

const ModalContent = (props) => {
  const pmStatuses = Object.keys(ProjectManagementStatusEnum);

  const data = props.data;
  const projects = props.projects;
  const customers = props.customers;
  const users = props.users;

  const [loading, setLoading] = useState(true);
  const [errorDetails, setErrorDetails] = useState({
    showError: false,
    errorCode: "",
    errorMessage: "",
  });

  const authContext = useContext(AuthContext);
  const backdropContext = useContext(BackdropContext);

  const [customer, setCustomer] = useState(null);
  const [project, setProject] = useState(null);
  const [employee, setEmployee] = useState();
  const [projectStatus, setStatus] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    if (!isNull(data)) {
      setStartDate(data.startDate);
      setEndDate(data.endDate);
      setProject(data.project);
      setEmployee(data.employee);
      setStatus(data.status);
      setCustomer(data.customer);
    }
  }, [data]);

  const handlePmStatusSelect = (event) => {
    setStatus(event.target.value);
  };

  const handleClientSelect = (event) => {
    const client = customers.find((c) => c.id === event.target.value);
    setCustomer(client);
  };

  const handleProjectSelect = (event) => {
    const project = projects.find((c) => c.id === event.target.value);
    setProject(project);
  };

  const handleEmployeeSelect = (event) => {
    const employee = users.find((c) => c.id === event.target.value);
    setEmployee(employee);
  };

  const hadleOnSaveClick = () => {
    const engagement = {
      customer: customer,
      employee: employee,
      project: project,
      startDate: startDate,
      endDate: endDate,
      status: projectStatus
    };
    saveHandler(engagement);
    backdropContext.toggle();
  };

  const hadleOnUpdateClick = () => {
    const engagement = {
      id: data.id,
      customer: customer,
      employee: employee,
      project: project,
      startDate: startDate,
      endDate: endDate,
      status: projectStatus
    };
    updateHandler(engagement);
    backdropContext.toggle();
  };

  const saveHandler = async (engagement) => {
    const url = baseUrl + "/api/engagement";
    SendPostRequest(url, engagement, authContext.token, engagementsHandler);
  };

  const updateHandler = async (engagement) => {
    const url = baseUrl + "/api/engagement";
    SendPutRequest(url, engagement, authContext.token, engagementsHandler);
  };

  const engagementsHandler = () => {
    backdropContext.toggle();
    props.onHide();
  }
  return (
    <div>
      <Dialog open={props.isVisible} maxWidth="lg">
        <DialogTitle>
          {isNull(data) ? "New Engagement" : "Edit Engagement"}
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <div>
                <FormControl sx={{ m: 1, minWidth: 500 }}>
                  <InputLabel id="demo-simple-select-label">
                    Customer
                  </InputLabel>
                  <Select
                    disabled={props.isReadOnly}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Language"
                    onChange={handleClientSelect}
                    value={isNull(customer) ? "" : customer.id}
                  >
                    {customers &&
                      customers.map((data) => {
                        return (
                          <MenuItem value={data.id}>
                            {data.companyDisplayName}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </div>

              <div>
                <FormControl sx={{ m: 1, minWidth: 500 }}>
                  <InputLabel id="demo-simple-select-label">
                    Employee
                  </InputLabel>
                  <Select
                    disabled={props.isReadOnly}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Language"
                    onChange={handleEmployeeSelect}
                    value={isNull(employee) ? "" : employee.id}
                  >
                    {users &&
                      users.map((data) => {
                        return (
                          <MenuItem value={data.id}>
                            {getFullName(data)}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </div>

              <div>
                <FormControl sx={{ m: 1, minWidth: 500 }}>
                  <InputLabel id="demo-simple-select-label">
                    Project
                  </InputLabel>
                  <Select
                    disabled={props.isReadOnly || isNull(customer)}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Language"
                    onChange={handleProjectSelect}
                    value={isNull(project) ? "" : project.id}
                  >
                    {projects && !isNull(customer) &&
                      projects.filter(p => p.customer.id === customer.id && p.status === ProjectManagementStatusEnum.ACTIVE).map((data) => {
                        return (
                          <MenuItem value={data.id}>
                            {data.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </div>

              <div>
                <TextField
                  sx={{ m: 1, minWidth: 200 }}
                  disabled={props.isReadOnly}
                  id="date"
                  label="Start Date"
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  sx={{ m: 1, minWidth: 200 }}
                  disabled={props.isReadOnly}
                  id="date"
                  label="End Date"
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              
              <div>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-label">
                    Status
                  </InputLabel>
                  <Select
                    disabled={props.isReadOnly}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Status"
                    onChange={handlePmStatusSelect}
                    defaultValue={isNull(data) ? "" : data.status}
                  >
                    {pmStatuses.map((key) => {
                      return (
                        <MenuItem value={key}>{ProjectManagementStatusEnum[key]}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            className="cancelButton"
            variant="contained"
            onClick={props.onHide}
          >
            {props.isReadOnly ? "Close" : "Cancel"}
          </Button>
          <Button
            disabled={props.isReadOnly}
            variant="contained"
            onClick={data && data.id ? hadleOnUpdateClick : hadleOnSaveClick}
          >
            {data && data.id ? "Update" : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const EngagementModal = (props) => {
  const onHide = () => {
    props.closeModalHandler();
  };

  return (
    <Fragment>
      {ReactDOM.createPortal(
        <ModalContent
          isVisible={props.isVisible}
          onHide={onHide}
          data={props.modalData}
          customers={props.customers}
          projects={props.projects}
          users={props.users}
          isReadOnly={props.isReadOnly}
        />,
        document.getElementById("modal-root")
      )}
    </Fragment>
  );
};

export default EngagementModal;
