import React, { useContext, useEffect, useRef } from "react";
import Error from "../../imgs/error403.svg";
import { useNavigate } from "react-router-dom";
import { TextField, Button, CircularProgress, Typography } from "@mui/material";
import AuthContext from "../../store/auth-context";
import { baseUrl } from "../../Config";
import { useState } from "react";
import Color from "../../enum/Color";

const Error403 = () => {

    const navigate = useNavigate();

    const imageStyle = {
        width: "40%"
    };

    const pageStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: Color.ACCENT3,
        height: "100vh",
    }

    const buttonStyle = {
        background: Color.BACKGROUND,
        margin: "8px",
        marginTop: "32px",
    }

    const onClickHandler = () => {
        navigate("/dashboard");
    }

  return (
    <div style={pageStyle}>
      <img src={Error} alt="logo" style={imageStyle} />
      <div style={{textAlign: "center"}}>
      <Typography variant={"h3"}>We are Sorry...</Typography>
      <Typography variant={"body1"}>The page you are trying to access has restricted access.</Typography>
      <Typography variant={"body2"}>Please refer to your system administrator</Typography>
      </div>

      <Button variant="contained" style={buttonStyle} onClick={onClickHandler}>
        Go back
      </Button>
    </div>
  );
};

export default Error403;
