import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../../store/auth-context";
import "./TasksPage.css"
import { baseUrl } from "../../Config";
import { SendGetRequest } from "../../actions/Actions";
import TasksTable from "../Table/TasksTable";
import { isNull } from "../../utils/Utils";

const TasksPage = () => {

  const [tasks, setTasks] = useState([]);
  const [total, setTotal] = useState(0);
  const [engagements, setEngagements] = useState([]);

  const authContext = useContext(AuthContext);
  const user = JSON.parse(sessionStorage.getItem("user"));

  const [pagingInfo, setPagingInfo] = useState({
    page: 0,
    size: 5
  });

  const buildTasksUrl = (pagingInfo) => {
    return baseUrl + "/api/task/all/" + user.id + "?page=" + pagingInfo.page + "&size=" + pagingInfo.size
  }

  /*Fetch tasks*/
  const [tasksUrl, setTaskUrl] = useState(buildTasksUrl(pagingInfo));

  const tasksCallback = (data) => {
    console.log(data);
    if(!isNull(data) && !isNull(data.tasks)) {
      setTasks(data.tasks);
    };
    if(!isNull(data) && !isNull(data.total)) {
      setTotal(data.total);
    }
  };

  useEffect(() => {
    setTaskUrl(buildTasksUrl(pagingInfo));
  }, [pagingInfo]);

  useEffect(() => {
    SendGetRequest(tasksUrl, authContext.token, tasksCallback);
  }, []);

   /*Fetch engagements*/
   const engagementsUrl = baseUrl + "/api/engagement/all/" + user.id;

   const engagementsCallback = (data) => {
     setEngagements(data);
   };

   const setPagingInfoCallback = (newPagingInfo) => {
    setPagingInfo(newPagingInfo);
    SendGetRequest(buildTasksUrl(newPagingInfo), authContext.token, tasksCallback);
   }
 
   useEffect(() => {
     SendGetRequest(engagementsUrl, authContext.token, engagementsCallback);
   }, []);
 

  const refreshHandler = () => {
    SendGetRequest(tasksUrl, authContext.token, tasksCallback);
  }

  return (
    <div className="TaxPage">
    <TasksTable tasks={tasks} total={total} engagements={engagements} employee={user} refreshHandler={refreshHandler} pagingInfo={pagingInfo} setPagingInfoCallback={setPagingInfoCallback}/>
    </div>
  );
};

export default TasksPage;
