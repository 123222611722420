import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../../store/auth-context";
import useApiRequest from "../../store/use-http";
import { baseUrl } from "../../Config";
import "./EngagementsPage.css";
import EngagementsTable from "../Table/EngagementsTable";
import { SendGetRequest } from "../../actions/Actions";

const EngagementsPage = () => {
  const [users, setUsers] = useState([]);
  const [engagements, setEngagements] = useState([]);
  const [projects, setProjects] = useState([]);
  const [customers, setCustomers] = useState([]);

  const authContext = useContext(AuthContext);

  const engagementsUrl = baseUrl + "/api/engagement/all";
  const usersUrl = baseUrl + "/api/users";
  const projectsUrl = baseUrl + "/api/project/all";
  const customersUrl = baseUrl + "/api/customer/all";

  useEffect(() => {
    SendGetRequest(engagementsUrl, authContext.token, engagementsHandler);
    SendGetRequest(usersUrl, authContext.token, usersHandler);
    SendGetRequest(projectsUrl, authContext.token, projectsHandler);
    SendGetRequest(customersUrl, authContext.token, customersHandler);
  }, []);

  const engagementsHandler = (data) => {
    setEngagements(data);
  }

  const usersHandler = (data) => {
    setUsers(data);
  }

  const projectsHandler = (data) => {
    setProjects(data);
  }

  const customersHandler = (data) => {
    setCustomers(data);
  }

  const refreshHandler = () => {
    SendGetRequest(engagementsUrl, authContext.token, engagementsHandler);
    SendGetRequest(usersUrl, authContext.token, usersHandler);
    SendGetRequest(projectsUrl, authContext.token, projectsHandler);
    SendGetRequest(customersUrl, authContext.token, customersHandler);
  }

  return (
    <div className="EngagementsPage">
      <EngagementsTable engagements={engagements} users={users} projects={projects} customers={customers} refreshHandler={refreshHandler} />
    </div>
  );
};

export default EngagementsPage;
