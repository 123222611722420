import { Button } from "@mui/material";
import React from "react";

const CreateButton = (props) => {
  const containerStyle = {
    display: "flex",
    flexDirection: "row-reverse",
  };

  const buttonStyle = {
    background: "green",
    marginBottom: "16px",
  };

  return (
    <div style={containerStyle}>
      <Button
        style={buttonStyle}
        variant="contained"
        startIcon={props.icon}
        onClick={props.action}
      >
        {props.text}
      </Button>
    </div>
  );
};

export default CreateButton;
