import ReactDOM from "react-dom";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Box,
} from "@mui/material";
import "./Modal.css";
import { Fragment, useContext, useRef } from "react";
import AuthContext from "../../store/auth-context";
import BackdropContext from "../../store/backdrop-context";
import { SendPostRequest, SendPutRequest } from "../../actions/Actions";
import { baseUrl } from "../../Config";

const TaxContent = (props) => {
  const data = props.data;

  const authContext = useContext(AuthContext);
  const backdropContext = useContext(BackdropContext);

  const taxNameInputRef = useRef();
  const taxRateInputRef = useRef();

  const hadleOnSaveClick = () => {
    const tax = {
      taxName: taxNameInputRef.current.value,
      taxRate: taxRateInputRef.current.value,
    };
    saveHandler(tax);
    backdropContext.toggle();
  };

  const hadleOnUpdateClick = () => {
    const tax = {
      id: data.id,
      taxName: taxNameInputRef.current.value,
      taxRate: taxRateInputRef.current.value,
    };
    updateHandler(tax);
    backdropContext.toggle();
  };

  const saveHandler = async (tax) => {
    const url = baseUrl + "/api/tax";
    SendPostRequest(url, tax, authContext.token, taxCallback);
  };

  const updateHandler = async (tax) => {
    const url = baseUrl + "/api/tax";
    SendPutRequest(url, tax, authContext.token, taxCallback);
  };

  const taxCallback = () => {
    backdropContext.toggle();
    props.onHide();
  }

  return (
    <div>
      <Dialog open={props.isVisible} maxWidth="lg">
        <DialogTitle>
          {props.data == null || props.data === undefined
            ? "New Tax"
            : props.data.taxName}
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <div>
                <TextField
                  disabled={props.isReadOnly}
                  id="outlined-required"
                  label="Tax Name"
                  inputRef={taxNameInputRef}
                  defaultValue={data && data.taxName}
                />
                <TextField
                  disabled={props.isReadOnly}
                  id="outlined-disabled"
                  label="Tax Rate (%)"
                  inputRef={taxRateInputRef}
                  defaultValue={data && data.taxRate}
                />
              </div>
            </div>  
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
          
            className="cancelButton"
            variant="contained"
            onClick={props.onHide}
          >
            Cancel
          </Button>
          <Button 
          variant="contained" onClick={data && data.id ? hadleOnUpdateClick : hadleOnSaveClick}>
            {data && data.id ? "Update" : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const TaxModal = (props) => {
  const onHide = () => {
    props.closeModalHandler();
  };

  return (
    <Fragment>
      {ReactDOM.createPortal(
        <TaxContent
          isVisible={props.isVisible}
          onHide={onHide}
          data={props.modalData}
          isReadOnly={props.isReadOnly}
        />,
        document.getElementById("modal-root")
      )}
    </Fragment>
  );
};

export default TaxModal;
