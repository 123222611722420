import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../../store/auth-context";
import { baseUrl } from "../../Config";
import "./TravelWarrantsPage.css";
import { SendGetRequest } from "../../actions/Actions";
import { containsRoles, getFullName, isNull } from "../../utils/Utils";
import { Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Color from "../../enum/Color";
import TravelWarrantsTable from "../Table/TravelWarrantsTable";

const TravelWarrantsPage = () => {
  const [travelWarrants, setTravelWarrants] = useState([]);
  const [users, setUsers] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [employee, setEmployee] = useState(
    JSON.parse(sessionStorage.getItem("user"))
  );

  const authContext = useContext(AuthContext);
  const user = JSON.parse(sessionStorage.getItem("user"))

    /*Fetch vehicles*/
    const vehiclesUrl = baseUrl + "/api/vehicle/all/active/" + user.id;

    const vehiclesCallback = (data) => {
      setVehicles((prev) => {
        return data;
      });
    };
  
    useEffect(() => {
      SendGetRequest(vehiclesUrl, authContext.token, vehiclesCallback);
    }, []);

    /*Fetch users*/
    const usersUrl = baseUrl + "/api/users";

    const usersCallback = (data) => {
      setUsers((prev) => {
        return data;
      });
    };
  
    useEffect(() => {
      SendGetRequest(usersUrl, authContext.token, usersCallback);
    }, []);

    const handleEmployeeSelect = (event) => {
      const employee = users.find((c) => c.id === event.target.value);
      setEmployee(prev => {return employee;});
    };

    /*Fetch travel warrants*/
    let travelWarrantUrl = baseUrl;

    const travelWarrantCallback = (data) => {
      setTravelWarrants((prev) => {
        return data;
      });
    };
    useEffect(() => {
      if(!isNull(employee)) {
        travelWarrantUrl = travelWarrantUrl + "/api/travel-warrant/all/" + employee.id;
      } else {
        travelWarrantUrl = travelWarrantUrl + "/api/travel-warrant/all";
      }
      SendGetRequest(travelWarrantUrl, authContext.token, travelWarrantCallback);
    }, [employee]);
  
    /***********/
    const refreshHandler = () => {
      if(!isNull(employee)) {
        travelWarrantUrl = travelWarrantUrl + "/api/travel-warrant/all/" + employee.id;
      } else {
        travelWarrantUrl = travelWarrantUrl + "/api/travel-warrant/all";
      }
      SendGetRequest(travelWarrantUrl, authContext.token, travelWarrantCallback);
    }

  return (
    <div className="TravelWarrantPage">
      {containsRoles(
        user.roles.map((role) => role.name),
        "ROLE_ADMIN"
      ) && (
        <div>
          <FormControl sx={{ m: 1, minWidth: 500 }}>
            <InputLabel id="demo-simple-select-label">Employee</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Language"
              onChange={handleEmployeeSelect}
              value={isNull(employee) ? "" : employee.id}
            >
              {users &&
                users.map((data) => {
                  return (
                    <MenuItem value={data.id} key={data.id}>
                      {getFullName(data)}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          <Button
          variant="contained"
          sx={{
            height: "56px",
            marginTop: "8px",
            backgroundColor: Color.DANGER,
          }}
          onClick={() => setEmployee(null)}
        >
          Clear
        </Button>
        </div>
      )}
      <TravelWarrantsTable data={travelWarrants} employee={user} vehicles={vehicles} refreshHandler={refreshHandler} />
    </div>
  );
};

export default TravelWarrantsPage;
