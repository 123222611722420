const Color = {
    BACKGROUND: '#9bd6e3',
    FOREGROUND: '#fcfcfc',
    ACCENT1: "#eff6f9",
    ACCENT2: "#e2f1f6",
    ACCENT3: "#d5ecf3",
    SUCCESS: "#a0d899",
    WARNING: "#f8d49a",
    DANGER: "#ff5436",
    DISABLED: "#c8c8c8",
    HOLIDAY: "#fd9797",
  };

export default Color;
