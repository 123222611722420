import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../../store/auth-context";
import useApiRequest from "../../store/use-http";
import { baseUrl } from "../../Config";
import "./WorktimePage.css";
import WorktimeTable from "../Table/WorktimeTable";
import { SendGetRequest } from "../../actions/Actions";

const WorktimePage = () => {
  const [engagements, setEngagements] = useState([]);
  const [worktimes, setWorktimes] = useState([]);

  const user = JSON.parse(sessionStorage.getItem("user"))
  const authContext = useContext(AuthContext);

  /*Fetch engagements*/
  const engagementsUrl = baseUrl + "/api/engagement/all/" + user.id;

  const engagementsCallback = (data) => {
    setEngagements(data);
  };

  useEffect(() => {
    SendGetRequest(engagementsUrl, authContext.token, engagementsCallback);
  }, []);

  /*Fetch calendar*/
  const worktimeUrl = baseUrl + "/api/worktime/all/" + user.id;

  const worktimesCallback = (data) => {
    setWorktimes((prev) => {
      return data;
    });
  };
  useEffect(() => {
    SendGetRequest(worktimeUrl, authContext.token, worktimesCallback);
  }, []);

  /***********/
  const refreshHandler = () => {
    SendGetRequest(worktimeUrl, authContext.token, worktimesCallback);
  }

  return (
    <div className="EngagementsPage">
      <WorktimeTable engagements={engagements} worktimes={worktimes} employee={user} refreshHandler={refreshHandler} />
    </div>
  );
};

export default WorktimePage;
